import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Loading from "../../components/Loading/Loading";
import AlertAction from "../../components/AlertAction";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PaymentDefaultApi from "../../services/PaymentDefaultApi";
import Checkbox from '@mui/material/Checkbox';
import "./PaymentDefault.css";
import RequiredFieldApi from "../../services/RequiredFieldApi";

//--------------------------------------------------

function PaymentDefault(props)
{
    //
    const [formLoading, setFormLoading] = useState(false);

    const [dataPaymentTerm, setDataPaymentTerm] = useState([]);
    const [dataPaymentTermLoading, setDataPaymentTermLoading] = useState(true);
    const [paymentTermFormDialogOpen, setPaymentTermFormDialogOpen] = useState(false);
    const [formPaymentTermLoading, setFormPaymentTermLoading] = useState(false);
    const [formPaymentTermName, setFormPaymentTermName] = useState('');
    const [formPaymentTermId, setFormPaymentTermId] = useState('');

    const [dataPaymentMethod, setDataPaymentMethod] = useState([]);
    const [dataPaymentMethodLoading, setDataPaymentMethodLoading] = useState(true);
    const [paymentMethodFormDialogOpen, setPaymentMethodFormDialogOpen] = useState(false);
    const [formPaymentMethodLoading, setFormPaymentMethodLoading] = useState(false);
    const [formPaymentMethodName, setFormPaymentMethodName] = useState('');
    const [formPaymentMethodId, setFormPaymentMethodId] = useState('');

    const alertAction = useRef(); // init alert

    // paymentTerm
    const loadDataPaymentTerm = () =>
    {
        setDataPaymentTerm(null); // set data to null
        setDataPaymentTermLoading(true); // show loading

        // get data
        PaymentDefaultApi.findPaymentTerm().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataPaymentTerm(response.data.list);
                }

                setDataPaymentTermLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataPaymentTermLoading(false); // hide loading
            }
        );
    };

    // paymentMethod
    const loadDataPaymentMethod = () =>
    {
        setDataPaymentMethod(null); // set data to null
        setDataPaymentMethodLoading(true); // show loading

        // get data
        PaymentDefaultApi.findPaymentMethod().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataPaymentMethod(response.data.list);
                }

                setDataPaymentMethodLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataPaymentMethodLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadDataPaymentMethod(); loadDataPaymentTerm(); }, []);

    // show form PaymentTerm
    const handlePaymentTermFormDialog = (e) =>
    {
        setFormPaymentTermId('');
        setFormPaymentTermName('');

        setPaymentTermFormDialogOpen(!paymentTermFormDialogOpen);
    };

    // show form PaymentMethod
    const handlePaymentMethodFormDialog = (e) =>
    {
        setFormPaymentMethodId('');
        setFormPaymentMethodName('');

        setPaymentMethodFormDialogOpen(!paymentMethodFormDialogOpen);
    };

    // paymentTerm save
    const savePaymentTerm = () =>
    {
        if(formPaymentTermName !== "") // check data is correct
        {
            setFormPaymentTermLoading(true); // hide btn & show loading

            // send data
            const params = {'termId': formPaymentTermId, 'name': formPaymentTermName};
            PaymentDefaultApi.savePaymentTerm(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("PaymentTerm Saved!", 'success'); // show success

                        if(formPaymentTermId !== '') setPaymentTermFormDialogOpen(false);
                        setFormPaymentTermId('');
                        setFormPaymentTermName('');

                        loadDataPaymentTerm();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormPaymentTermLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormPaymentTermLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // paymentMethod save
    const savePaymentMethod = () =>
    {
        if(formPaymentMethodName !== "") // check data is correct
        {
            setFormPaymentMethodLoading(true); // hide btn & show loading

            // send data
            const params = {'methodId': formPaymentMethodId, 'name': formPaymentMethodName};
            PaymentDefaultApi.savePaymentMethod(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("PaymentMethod Saved!", 'success'); // show success

                        if(formPaymentMethodId !== '') setPaymentMethodFormDialogOpen(false);
                        setFormPaymentMethodId('');
                        setFormPaymentMethodName('');

                        loadDataPaymentMethod();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormPaymentMethodLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormPaymentMethodLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // paymentTerm edit
    const handlePaymentTermEdit = (index) =>
    {
        setFormPaymentTermId(dataPaymentTerm[index].termId);
        setFormPaymentTermName(dataPaymentTerm[index].termName);

        setPaymentTermFormDialogOpen(true);
    };

    // paymentMethod edit
    const handlePaymentMethodEdit = (index) =>
    {
        setFormPaymentMethodId(dataPaymentMethod[index].methodId);
        setFormPaymentMethodName(dataPaymentMethod[index].methodName);

        setPaymentMethodFormDialogOpen(true);
    };

    // paymentTerm delete
    const handlePaymentTermDelete = (index) =>
    {
        setDataPaymentTermLoading(true); // show loading

        // send data
        const params = {'paymentTermId': dataPaymentTerm[index].termId};
        PaymentDefaultApi.deletePaymentTerm(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Payment Term Deleted!", 'success'); // show success

                    loadDataPaymentTerm();
                }
                else
                {
                    setDataPaymentTermLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataPaymentTermLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // paymentMethod delete
    const handlePaymentMethodDelete = (index) =>
    {
        setDataPaymentMethodLoading(true); // show loading

        // send data
        const params = {'paymentMethodId': dataPaymentMethod[index].methodId};
        PaymentDefaultApi.deletePaymentMethod(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Payment Method Deleted!", 'success'); // show success

                    loadDataPaymentMethod();
                }
                else
                {
                    setDataPaymentMethodLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataPaymentMethodLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const handlePaymentTermChange = (e, index) =>
    {
        let tmpData = dataPaymentTerm;
        tmpData[index].isRequired = e.target.checked;
        setDataPaymentTerm([...tmpData, ...[]]);
    };

    //
    const handlePaymentMethodChange = (e, index) =>
    {
        let tmpData = dataPaymentMethod;
        tmpData[index].isRequired = e.target.checked;
        setDataPaymentMethod([...tmpData, ...[]]);
    };

    // PaymentDefault save
    const savePaymentDefault = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'term': dataPaymentTerm, 'method': dataPaymentMethod};
        PaymentDefaultApi.save(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Payment Defaults Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <>
            {/* PaymentDefault list */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Manage Payment Defaults</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content dialog-content-payment-default">
                    <span className="section-title">Payment Terms</span>
                    <Button onClick={handlePaymentTermFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Term</Button>
                    {
                        dataPaymentTermLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                dataPaymentTerm !== null ?
                                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar scrollbar-payment-term">
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th className="w-100">Required</th>
                                                <th>Payment Terms</th>
                                                <th className="w-40"></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataPaymentTerm.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td><Checkbox checked={item.isRequired} onChange={(e) => handlePaymentTermChange(e, index)} /></td>
                                                                <td>{item.termName}</td>
                                                                <td>
                                                                    {/*<IconButton className="icon-edit" onClick={() => handlePaymentTermEdit(index)}><BorderColorIcon /></IconButton>*/}
                                                                    <IconButton className="icon-delete" onClick={() => handlePaymentTermDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    :
                                    <AlertDataEmpty />
                            )
                    }

                    {/* *** */}

                    <span className="section-title">Payment Methods</span>
                    <Button onClick={handlePaymentMethodFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Method</Button>
                    {
                        dataPaymentMethodLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                dataPaymentMethod !== null ?
                                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar scrollbar-payment-method">
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th className="w-100">Required</th>
                                                <th>Payment Methods</th>
                                                <th className="w-40"></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataPaymentMethod.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td><Checkbox checked={item.isRequired} onChange={(e) => handlePaymentMethodChange(e, index)} /></td>
                                                                <td>{item.methodName}</td>
                                                                <td>
                                                                    {/*<IconButton className="icon-edit" onClick={() => handlePaymentMethodEdit(index)}><BorderColorIcon /></IconButton>*/}
                                                                    <IconButton className="icon-delete" onClick={() => handlePaymentMethodDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    :
                                    <AlertDataEmpty />
                            )
                    }
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                {dataPaymentTerm !== null || dataPaymentMethod !== null ? <Button onClick={savePaymentDefault} className="dialog-btn-save">Save Changes</Button> : null}
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* PaymentTerm form */}
            <Dialog open={paymentTermFormDialogOpen} onClose={handlePaymentTermFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formPaymentTermId === '' ? 'Add' : 'Edit'} Term</span>
                        <IconButton className="dialog-close" onClick={handlePaymentTermFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-payment-term-name">Term Name</InputLabel>
                        <TextField className="form-input" id="form-payment-term-name" value={formPaymentTermName} onChange={(e) => setFormPaymentTermName(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formPaymentTermLoading ?
                            <>
                                <Button onClick={savePaymentTerm} className="dialog-btn-save">Save Term</Button>
                                <Button onClick={handlePaymentTermFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* PaymentMethod form */}
            <Dialog open={paymentMethodFormDialogOpen} onClose={handlePaymentMethodFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formPaymentMethodId === '' ? 'Add' : 'Edit'} Method</span>
                        <IconButton className="dialog-close" onClick={handlePaymentMethodFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-payment-method-name">Method Name</InputLabel>
                        <TextField className="form-input" id="form-payment-method-name" value={formPaymentMethodName} onChange={(e) => setFormPaymentMethodName(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formPaymentMethodLoading ?
                            <>
                                <Button onClick={savePaymentMethod} className="dialog-btn-save">Save Method</Button>
                                <Button onClick={handlePaymentMethodFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default PaymentDefault;
