import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Loading from "../../components/Loading/Loading";
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import AlertAction from "../../components/AlertAction";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ProductApi from "../../services/ProductApi";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import StationApi from "../../services/StationApi";
import VendorApi from "../../services/VendorApi";
import CheckIcon from '@mui/icons-material/Check';
import { FileUploader } from "react-drag-drop-files";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import JobApi from "../../services/JobApi";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import "./Job.css";
import {useNavigate} from "react-router-dom";

//--------------------------------------------------

function Job(props)
{
    const quoteId = props.quoteId;
    const jobId = props.jobId;
    const dialogStep = props.dialogStep;

    //
    const [dataProduct, setDataProduct] = useState([]);
    const [dataProductModel, setDataProductModel] = useState([]);
    const [dataStation, setDataStation] = useState([]);
    const [dataVendor, setDataVendor] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataFileDelete, setDataFileDelete] = useState([]);
    const [dataFileOld, setDataFileOld] = useState([]);
    const [dataFile, setDataFile] = useState([]);
    const fileTypes = ["JPEG", "PNG", "GIF"];

    const [formJobSectionShowHide, setFormJobSectionShowHide] = useState([-1, 1, 0, 0, 0]);
    const [dataStationSelected, setDataStationSelected] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [formJobId, setFormJobId] = useState('');
    const [formJobCategoryId, setFormJobCategoryId] = useState('');
    const [formJobCategoryName, setFormJobCategoryName] = useState('');
    const [formJobProductModelId, setFormJobProductModelId] = useState('');
    const [formJobProductModelName, setFormJobProductModelName] = useState('');
    const [formJobPricing, setFormJobPricing] = useState('');
    const [formJobQuantity, setFormJobQuantity] = useState('');
    const [formJobProductDetails, setFormJobProductDetails] = useState('');
    const [formJobProductionNotes, setFormJobProductionNotes] = useState('');
    const [dataCustomInputs, setDataCustomInputs] = useState([]);
    const [dataCustomInputValues, setDataCustomInputValues] = useState({});
    const [formJobSpecialOrder, setFormJobSpecialOrder] = useState([]);

    const [dragId, setDragId] = useState();

    const alertAction = useRef(); // init alert
    const navigate = useNavigate();

    // load data
    const loadDataProduct = () =>
    {
        setDataProduct(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        ProductApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataProduct(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataStation = () =>
    {
        setDataStation(null); // set data to null

        // get data
        StationApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataStation(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataVendor = () =>
    {
        setDataVendor(null); // set data to null

        // get data
        VendorApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataVendor(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadDataProduct(); loadDataStation(); loadDataVendor(); }, []);
    useEffect(() => { if(props.jobData != null && !dataLoading) handleJobEdit(props.jobData); }, [dataLoading]);

    // job save
    const saveJob = () =>
    {
        let workflow = [];

        //
        if(quoteId > 0 && formJobProductModelId !== "" && dataStationSelected != null && dataStationSelected.length > 0) // check data is correct
        {
            for (let i = 0; i < dataStationSelected.length; i++) workflow[i] = dataStationSelected[i].stationId;

            //
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('quoteId', quoteId);
            params.append('jobId', formJobId);
            params.append('modelName', formJobProductModelId);
            params.append('pricing', formJobPricing);
            params.append('quantity', formJobQuantity);
            params.append('productionNotes', formJobProductionNotes);
            params.append('productDetails', formJobProductDetails);
            params.append('customs', JSON.stringify(dataCustomInputValues));
            params.append('specialOrder', JSON.stringify(formJobSpecialOrder));
            params.append('workflow', JSON.stringify(workflow));
            params.append('skipFiles', JSON.stringify(dataFileDelete));
            for (let i = 0; i < dataFile.length; i++)
            {
                params.append('upload[]', dataFile[i]);
            }
            JobApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Job Saved!", 'success'); // show success

                        if(formJobId === '') resetForm();

                        props.loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormJobSectionShowHide([...[-1, 1, 0, 0, 0], ...[]]);
        setFormJobId('');
        setDataProductModel([]);
        setDataFileDelete([]);
        setDataFileOld([]);
        setDataFile([]);
        setDataStationSelected([]);
        setFormJobCategoryId('');
        setFormJobCategoryName('');
        setFormJobProductModelId('');
        setFormJobProductModelName('');
        setFormJobPricing('');
        setFormJobQuantity('');
        setFormJobProductDetails('');
        setFormJobProductionNotes('');
        setDataCustomInputs([]);
        setDataCustomInputValues({});
        setFormJobSpecialOrder([]);
    };

    // job edit
    const handleJobEdit = (jobData) =>
    {
        if(dialogStep === 2) setFormJobSectionShowHide([...[-1, 2, 1, 0, 0], ...[]]);
        else if(dialogStep === 3) setFormJobSectionShowHide([...[-1, 2, 2, 1, 0], ...[]]);

        //
        let dwf = [];
        let categoryId = jobData.jobOrder.categoryId;
        let productId = jobData.jobOrder.productModelId;
        let productModel = [];
        let customInputs = [];

        if(jobData.workFlows != null)
        {
            for (let i = 0; i < jobData.workFlows.length; i++)
            {
                for (let j = 0; j < dataStation.length; j++)
                {
                    if(dataStation[j].stationId == jobData.workFlows[i].stationId) dwf[dwf.length] = dataStation[j];
                }
            }
        }

        if(dataProduct !== null && dataProduct.length > 0)
        {
            for(let i = 0; i < dataProduct.length; i++)
            {
                if(dataProduct[i].categoryId === categoryId)
                {
                    productModel = dataProduct[i].products;
                    break;
                }
            }
        }

        if(productModel !== null && productModel.length > 0)
        {
            for(let i = 0; i < productModel.length; i++)
            {
                if(productModel[i].productId === productId)
                {
                    customInputs = productModel[i].customInputs;
                    break;
                }
            }
        }

        setFormJobId(jobId);
        setDataProductModel(productModel);
        setDataFileOld(jobData.files);
        setDataFile([]);
        setDataStationSelected(dwf);
        setFormJobCategoryId(jobData.jobOrder.categoryId);
        setFormJobCategoryName(jobData.jobOrder.categoryName);
        setFormJobProductModelId(jobData.jobOrder.productModelId);
        setFormJobProductModelName(jobData.jobOrder.productModel);
        setFormJobPricing(jobData.jobOrder.pricing);
        setFormJobQuantity(jobData.jobOrder.quantity);
        setFormJobProductDetails(jobData.jobOrder.notes);
        setFormJobProductionNotes(jobData.jobOrder.productionNotes);
        setDataCustomInputs(customInputs);
        setDataCustomInputValues(jobData.jobOrder.customs2);
        setFormJobSpecialOrder((jobData.specialOrders !== null && jobData.specialOrders.length > 0) ? jobData.specialOrders : []);
    };

    // handle Form Product Catalog Section show hide
    const handleFormJobSectionShowHide = (index, type) =>
    {
        let tmpData = formJobSectionShowHide;

        for (let i = 1; i < tmpData.length; i++) if(tmpData[i] === 1) tmpData[i] = 0;

        if(type === 2)
        {
            tmpData[index - 1] = 2;
            tmpData[index] = 1;
        }
        else
        {
            tmpData[index] = 1;
        }

        setFormJobSectionShowHide([...tmpData, ...[]]);
    };

    // handle add to Station Selected
    const handleAddTodStationSelected = (index) =>
    {
        setDataStationSelected([...dataStationSelected, ...[dataStation[index]]]);
    };

    // handle remove from Station Selected
    const handleRemovedStationSelected = (index) =>
    {
        let tmpData = dataStationSelected;
        tmpData.splice(index, 1);
        setDataStationSelected([...tmpData, ...[]]);
    };

    // form category Change
    const handleFormCategoryChange = (e) =>
    {
        let id = e.target.value;
        let name = '';
        let productModel = '';

        for(let i = 0; i < dataProduct.length; i++)
        {
            if(dataProduct[i].categoryId === id)
            {
                name = dataProduct[i].categoryName;
                productModel = dataProduct[i].products;
                break;
            }
        }

        setDataCustomInputs([]);
        setDataCustomInputValues({});
        setFormJobCategoryId(id);
        setFormJobCategoryName(name);
        setDataProductModel(productModel);
    };

    // form Product Model Change
    const handleFormProductModelChange = (e) =>
    {
        let id = e.target.value;
        let name = '';
        let customInputs = [];
        let dwf = [];
        let workFlows = [];

        for(let i = 0; i < dataProductModel.length; i++)
        {
            if(dataProductModel[i].productId === id)
            {
                name = dataProductModel[i].productName;
                customInputs = dataProductModel[i].customInputs;
                workFlows = dataProductModel[i].workflow;
                break;
            }
        }

        if(workFlows != null && workFlows.length > 0)
        {
            for (let i = 0; i < workFlows.length; i++)
            {
                for (let j = 0; j < dataStation.length; j++)
                {
                    if(dataStation[j].stationId == workFlows[i]) dwf[dwf.length] = dataStation[j];
                }
            }
        }

        setDataCustomInputs(customInputs);
        setDataStationSelected(dwf);
        setDataCustomInputValues({});
        setFormJobProductModelId(id);
        setFormJobProductModelName(name);
    };

    // files
    const handleFileUploaderChange = (newFile) =>
    {
        let tmpFiles = [];

        for(let i = 0; i < newFile.length; i++)
        {
            tmpFiles[i] = newFile[i];
        }

        setDataFile([...dataFile, ...tmpFiles]);
    };

    const handleFileUploaderDelete = (index) =>
    {
        let tmpData = dataFile;
        tmpData.splice(index, 1);
        setDataFile([...tmpData, ...[]]);
    };

    const handleFileOldDelete = (index) =>
    {
        let tmpData = dataFileOld;
        let fileId = tmpData[index].id;
        tmpData.splice(index, 1);
        setDataFileOld([...tmpData, ...[]]);

        let tmpData2 = dataFileDelete;
        tmpData2.push(fileId);
        setDataFileDelete([...tmpData2, ...[]]);
    };

    // customCharacteristics
    const handleJobSpecialOrderAdd = () =>
    {
        let tmpSpecialOrder = formJobSpecialOrder;
        tmpSpecialOrder[formJobSpecialOrder.length] = {'specialOrderId': '', 'vendorId': '', 'vendorName': '', 'stationId': '', 'stationName': '', 'quantity': '', 'size': '', 'productDetail': '', 'isDeleted': 0};

        setFormJobSpecialOrder([...tmpSpecialOrder, ...[]]);
    };

    const handleJobSpecialOrderDelete = (index) =>
    {
        let tmpData = formJobSpecialOrder;
        if(tmpData[index].specialOrderId > 0) tmpData[index].isDeleted = 1; else tmpData.splice(index, 1);
        setFormJobSpecialOrder([...tmpData, ...[]]);
    };

    const handleJobSpecialOrder = (e, index, type) =>
    {
        let tmpSpecialOrder = formJobSpecialOrder;

        switch(type)
        {
            case 'vendor':
            {
                let id = e.target.value;
                let name = '';

                for(let i = 0; i < dataProductModel.length; i++)
                {
                    if(dataProductModel[i].productId === id)
                    {
                        name = dataProductModel[i].productName;
                        break;
                    }
                }

                tmpSpecialOrder[index].vendorId = id;
                tmpSpecialOrder[index].vendorName = name;
            }
                break;

            case 'station':
            {
                let id = e.target.value;
                let name = '';

                for(let i = 0; i < dataProductModel.length; i++)
                {
                    if(dataProductModel[i].productId === id)
                    {
                        name = dataProductModel[i].productName;
                        break;
                    }
                }

                tmpSpecialOrder[index].stationId = id;
                tmpSpecialOrder[index].stationName = name;
            }
                break;

            case 'quantity':
                tmpSpecialOrder[index].quantity = e.target.value;
                break;

            case 'size':
                tmpSpecialOrder[index].size = e.target.value;
                break;

            case 'productDetail':
                tmpSpecialOrder[index].productDetail = e.target.value;
                break;
        }

        setFormJobSpecialOrder([...tmpSpecialOrder, ...[]]);
    };

    const handleJobCustomInput = (e, elementId, type = '') =>
    {
        let tmpData = dataCustomInputValues;
        let v = '';

        if(type === 'checkbox') v = e.target.checked ? 'on' : 'off'; else v = e.target.value;

        setDataCustomInputValues({...dataCustomInputValues, [elementId]: v});
    };

    // drag & drop
    const handleDrag = (e) =>
    {
        setDragId(e.currentTarget.id);
    };

    const handleDrop = (e) =>
    {
        const dragBoxIndex = parseInt(dragId.replace('station-item-', ''));
        const dropBoxIndex = parseInt(e.currentTarget.id.replace('station-item-', ''));
/*
        let tmpData = dataStationSelected;
        let dropBoxData = tmpData[dropBoxIndex];
        tmpData[dropBoxIndex] = tmpData[dragBoxIndex];
        tmpData[dragBoxIndex] = dropBoxData;

        setDataStationSelected([...tmpData, ...[]]);
*/

        let tmpData = dataStationSelected;
        let dragBoxData = tmpData[dragBoxIndex];

        if(dragBoxIndex !== dropBoxIndex)
        {
            if(dropBoxIndex < dragBoxIndex)
            {
                for(let i = dragBoxIndex; i > dropBoxIndex; i--) tmpData[i] = tmpData[i - 1];
            }
            else
            {
                for(let i = dragBoxIndex; i < dropBoxIndex; i++) tmpData[i] = tmpData[i + 1];
            }

            tmpData[dropBoxIndex] = dragBoxData;
        }

        setDataStationSelected([...tmpData, ...[]]);
    };

    // delete job
    const handleDeleteJob = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'jobId': jobId,
            };
        JobApi.deleteJob(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Job Deleted!", 'success'); // show success
                    navigate("/jobs", { replace: false });
                    // props.handleDialog();
                }
                else
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <>
            {/* job form */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formJobId === '' ? 'Add' : 'Edit'} Job</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2 form-job-box">
                    {
                        !dataLoading ?
                            <>
                                {/* section Select a Product */}
                                <div className="section-box">
                                    <div className={formJobSectionShowHide[1] === 2 ? "section-title-bar done" : "section-title-bar"}>
                                        <div>
                                            <span className="title">1. Select a Product</span>
                                            <Button className="btn-edit" onClick={() => handleFormJobSectionShowHide(1, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                        </div>
                                        <IconButton className="btn-close-open" onClick={() => handleFormJobSectionShowHide(1, 0)}>{formJobSectionShowHide[1] === 1 ? <ArrowDropUpOutlinedIcon /> : (formJobSectionShowHide[1] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                                    </div>
                                    {
                                        formJobSectionShowHide[1] === 1 ?
                                            <div className="section-content">
                                                <Container className="form-container">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-category">Product Category</InputLabel>
                                                            <Select className="form-select" id="form-job-category" value={formJobCategoryId} onChange={handleFormCategoryChange}>
                                                                <MenuItem value="">&nbsp;</MenuItem>
                                                                {
                                                                    dataProduct !== null ?
                                                                        dataProduct.map
                                                                        (
                                                                            (item) =>
                                                                            {
                                                                                return (
                                                                                    <MenuItem value={item.categoryId}>{item.categoryName}</MenuItem>
                                                                                );
                                                                            }
                                                                        )
                                                                        :
                                                                        null
                                                                }
                                                            </Select>
                                                        </Grid>

                                                        <div className='form-divider'></div>

                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-model-name">Model Name</InputLabel>

                                                            <Select className="form-select" id="form-job-model-name" value={formJobProductModelId} onChange={handleFormProductModelChange}>
                                                                <MenuItem value="">&nbsp;</MenuItem>
                                                                {
                                                                    dataProductModel !== null ?
                                                                        dataProductModel.map
                                                                        (
                                                                            (item) =>
                                                                            {
                                                                                return (
                                                                                    <MenuItem value={item.productId}>{item.productName}</MenuItem>
                                                                                );
                                                                            }
                                                                        )
                                                                        :
                                                                        null
                                                                }
                                                            </Select>
                                                        </Grid>

                                                        <div className='form-divider'></div>

                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-pricing">Pricing</InputLabel>
                                                            <TextField className="form-input" id="form-job-pricing" placeholder="" value={formJobPricing} onChange={(e) => setFormJobPricing(e.target.value)} />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Button className="btn-blue btn-blue-outline" onClick={() => handleFormJobSectionShowHide(2, 2)} fullWidth={false} variant="contained">Continue</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                {/* section Customize Product */}
                                <div className="section-box">
                                    <div className={formJobSectionShowHide[2] === 2 ? "section-title-bar done" : "section-title-bar"}>
                                        <div>
                                            <span className="title">2. Customize Product</span>
                                            <Button className="btn-edit" onClick={() => handleFormJobSectionShowHide(2, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                        </div>
                                        <IconButton className="btn-close-open" onClick={() => handleFormJobSectionShowHide(2, 0)}>{formJobSectionShowHide[2] === 1 ? <ArrowDropUpOutlinedIcon /> : (formJobSectionShowHide[2] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                                    </div>
                                    {
                                        formJobSectionShowHide[2] === 1 ?
                                            <div className="section-content">
                                                <Container className="form-container">
                                                    <Grid container spacing={2}>

                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-quantity">Quantity</InputLabel>
                                                            <TextField className="form-input" id="form-job-quantity" placeholder="" value={formJobQuantity} onChange={(e) => setFormJobQuantity(e.target.value)} />
                                                        </Grid>

                                                        <div className='form-divider'></div>

                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-product-details">Product Details</InputLabel>
                                                            <TextField className="form-input" id="form-job-product-details" multiline={true} placeholder="" value={formJobProductDetails} onChange={(e) => setFormJobProductDetails(e.target.value)} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-production-notes">Production Notes</InputLabel>
                                                            <TextField className="form-input" id="form-job-production-notes" multiline={true} placeholder="" value={formJobProductionNotes} onChange={(e) => setFormJobProductionNotes(e.target.value)} />
                                                        </Grid>

                                                        <div className='form-divider'></div>




                                                        {
                                                            dataCustomInputs !== null && dataCustomInputs.length > 0 ?
                                                                <Grid item xs={12} sm={6}>
                                                                    <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-file">Custom Characterisitcs</InputLabel>
                                                                    {
                                                                        dataCustomInputs.map
                                                                        (
                                                                            (item, index) =>
                                                                            {
                                                                                return (
                                                                                    <>
                                                                                        {(() =>
                                                                                        {
                                                                                            switch(item.customInputTypeId)
                                                                                            {
                                                                                                case 1:
                                                                                                    return (
                                                                                                        <Grid item xs={12} sm={12}>
                                                                                                            <InputLabel shrink className="form-label" htmlFor={"form-job-" + item.elementId}>{item.name}</InputLabel>
                                                                                                            <Select className="form-select" id={"form-job-" + item.elementId} value={dataCustomInputValues !== null && dataCustomInputValues[item.elementId] !== undefined ? dataCustomInputValues[item.elementId] : ''} onChange={(e) => handleJobCustomInput(e, item.elementId)}>
                                                                                                                <MenuItem value="">&nbsp;</MenuItem>
                                                                                                                {
                                                                                                                    item.listItems !== null ?
                                                                                                                        item.listItems.map
                                                                                                                        (
                                                                                                                            (item2) =>
                                                                                                                            {
                                                                                                                                return (
                                                                                                                                    <MenuItem value={item2.id}>{item2.name}</MenuItem>
                                                                                                                                );
                                                                                                                            }
                                                                                                                        )
                                                                                                                        :
                                                                                                                        null
                                                                                                                }
                                                                                                            </Select>
                                                                                                        </Grid>
                                                                                                    );

                                                                                                case 2:
                                                                                                    return (
                                                                                                        <Grid item xs={12} sm={12}>
                                                                                                            <InputLabel shrink className="form-label" htmlFor={"form-job-" + item.elementId}>{item.name}</InputLabel>
                                                                                                            <TextField type="number" inputProps={{maxLength: 13, step: "0.01"}} className="form-input" id={"form-job-" + item.elementId} placeholder="" value={dataCustomInputValues !== null && dataCustomInputValues[item.elementId] !== undefined ? dataCustomInputValues[item.elementId] : ''} onChange={(e) => handleJobCustomInput(e, item.elementId)} />
                                                                                                        </Grid>
                                                                                                    );

                                                                                                case 3:
                                                                                                    return (
                                                                                                        <Grid item xs={12} sm={12}>
                                                                                                            <InputLabel shrink className="form-label" htmlFor={"form-job-" + item.elementId}>{item.name}</InputLabel>
                                                                                                            <TextField className="form-input" id={"form-job-" + item.elementId} placeholder="" value={dataCustomInputValues !== null && [item.elementId] !== undefined ? dataCustomInputValues[item.elementId] : ''} onChange={(e) => handleJobCustomInput(e, item.elementId)} />
                                                                                                        </Grid>
                                                                                                    );

                                                                                                case 4:
                                                                                                    return (
                                                                                                        <FormControlLabel control={<Checkbox checked={dataCustomInputValues !== null && dataCustomInputValues[item.elementId] !== undefined && dataCustomInputValues[item.elementId] === 'on'} onChange={(e) => handleJobCustomInput(e, item.elementId, 'checkbox')} />} className="form-control-label-check-box" label={item.name} />
                                                                                                    );

                                                                                                default:
                                                                                                    return null;
                                                                                            }
                                                                                        })()}
                                                                                    </>
                                                                                );
                                                                            }
                                                                        )
                                                                    }
                                                                </Grid>
                                                                :
                                                                null
                                                        }









                                                        <div className='form-divider'></div>

                                                        <Grid item xs={12} sm={6}>
                                                            <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-file">Drawings or diagrams</InputLabel>

                                                            <FileUploader multiple={true} handleChange={handleFileUploaderChange} name="file" types={fileTypes}>
                                                                <div className="file-uploader">
                                                                    <CloudUploadOutlinedIcon className="icon" />
                                                                    <div className="title">Upload new file or folder</div>
                                                                    <div className="des">Drag an drop a file or <span className="highlight">browse file</span></div>
                                                                </div>
                                                            </FileUploader>

                                                            {
                                                                dataFile != null ?
                                                                    <div className="file-uploader-list">
                                                                        {
                                                                            dataFile.map
                                                                            (
                                                                                (item, index) =>
                                                                                {
                                                                                    return (
                                                                                        <div className="file-uploader-item">
                                                                                            <span className="name">{item.name}</span>
                                                                                            <IconButton className="icon" onClick={() => handleFileUploaderDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                dataFileOld != null && dataFileOld.length > 0 ?
                                                                    <div className="file-uploader-list file-uploader-list-old">
                                                                        <div className="title">old files</div>
                                                                        {
                                                                            dataFileOld.map
                                                                            (
                                                                                (item, index) =>
                                                                                {
                                                                                    return (
                                                                                        <div className="file-uploader-item">
                                                                                            <span className="name">{item.name}</span>
                                                                                            <IconButton className="icon" onClick={() => handleFileOldDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )
                                                                        }
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </Grid>

                                                        <div className='form-divider'></div>




                                                        <Grid item xs={12} sm={12}>
                                                            <InputLabel shrink className="form-label" htmlFor="form-job-file">Special order of material Required?<Button className="btn-blue btn-blue-outline m-l-16" onClick={handleJobSpecialOrderAdd} fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add</Button></InputLabel>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={6}>
                                                                    {
                                                                        formJobSpecialOrder !== null ?
                                                                            formJobSpecialOrder.map
                                                                            (
                                                                                (item, index) =>
                                                                                {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                item.isDeleted === 0 ?
                                                                                                    <div className="form-job-special-order-box">
                                                                                                        <Grid container spacing={2}>
                                                                                                            <Grid item xs={12} sm={12}>
                                                                                                                <InputLabel shrink className="form-label" htmlFor="form-job-special-order-vendor">Vendors</InputLabel>
                                                                                                                <Select className="form-select" id="form-job-special-order-vendor" value={formJobSpecialOrder[index].vendorId} onChange={(e) => handleJobSpecialOrder(e, index, 'vendor')}>
                                                                                                                    <MenuItem value="">&nbsp;</MenuItem>
                                                                                                                    {
                                                                                                                        dataVendor !== null ?
                                                                                                                            dataVendor.map
                                                                                                                            (
                                                                                                                                (item) =>
                                                                                                                                {
                                                                                                                                    return (
                                                                                                                                        <MenuItem value={item.vendorId}>{item.vendorName}</MenuItem>
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            )
                                                                                                                            :
                                                                                                                            null
                                                                                                                    }
                                                                                                                </Select>
                                                                                                            </Grid>

                                                                                                            <Grid item xs={12} sm={12}>
                                                                                                                <InputLabel shrink className="form-label" htmlFor="form-job-special-order-station">Station Needed</InputLabel>
                                                                                                                <Select className="form-select" id="form-job-special-order-station" value={formJobSpecialOrder[index].stationId} onChange={(e) => handleJobSpecialOrder(e, index, 'station')}>
                                                                                                                    <MenuItem value="">&nbsp;</MenuItem>
                                                                                                                    {
                                                                                                                        dataStation !== null ?
                                                                                                                            dataStation.map
                                                                                                                            (
                                                                                                                                (item) =>
                                                                                                                                {
                                                                                                                                    return (
                                                                                                                                        <MenuItem value={item.stationId}>{item.stationName}</MenuItem>
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            )
                                                                                                                            :
                                                                                                                            null
                                                                                                                    }
                                                                                                                </Select>
                                                                                                            </Grid>

                                                                                                            <Grid item xs={12} sm={12}>
                                                                                                                <InputLabel shrink className="form-label" htmlFor="form-job-special-order-quantity">Quantity</InputLabel>
                                                                                                                <TextField className="form-input" id="form-job-special-order-quantity" placeholder="" value={formJobSpecialOrder[index].quantity} onChange={(e) => handleJobSpecialOrder(e, index, 'quantity')} />
                                                                                                            </Grid>

                                                                                                            <Grid item xs={12} sm={12}>
                                                                                                                <InputLabel shrink className="form-label" htmlFor="form-job-special-order-size">Size</InputLabel>
                                                                                                                <TextField className="form-input" id="form-job-special-order-size" placeholder="" value={formJobSpecialOrder[index].size} onChange={(e) => handleJobSpecialOrder(e, index, 'size')} />
                                                                                                            </Grid>

                                                                                                            <Grid item xs={12} sm={12}>
                                                                                                                <InputLabel shrink className="form-label" htmlFor="form-job-special-order-product-detail">Product Details</InputLabel>
                                                                                                                <TextField className="form-input" id="form-job-special-order-product-detail" placeholder="" value={formJobSpecialOrder[index].productDetail} onChange={(e) => handleJobSpecialOrder(e, index, 'productDetail')} />
                                                                                                            </Grid>

                                                                                                            <Grid item xs={12} sm={12}>
                                                                                                                <Button className="btn-red btn-red-outline" onClick={() => handleJobSpecialOrderDelete(index)} fullWidth={false} variant="contained">Delete</Button>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>




                                                        <div className='form-divider'></div>

                                                        <Grid item xs={12}>
                                                            <Button className="btn-blue btn-blue-outline" onClick={() => handleFormJobSectionShowHide(3, 2)} fullWidth={false} variant="contained">Continue</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                {/* section Define Workflow */}
                                <div className="section-box">
                                    <div className={formJobSectionShowHide[3] === 2 ? "section-title-bar done" : "section-title-bar"}>
                                        <div>
                                            <span className="title">3. Define Workflow</span>
                                            <Button className="btn-edit" onClick={() => handleFormJobSectionShowHide(3, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                        </div>
                                        <IconButton className="btn-close-open" onClick={() => handleFormJobSectionShowHide(3, 0)}>{formJobSectionShowHide[3] === 1 ? <ArrowDropUpOutlinedIcon /> : (formJobSectionShowHide[3] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                                    </div>
                                    {
                                        formJobSectionShowHide[3] === 1 ?
                                            <div className="section-content">
                                                <Container className="form-container">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                            <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-work-flow-items">Add Workflow Items</InputLabel>
                                                            <div className="stations-box">
                                                                {
                                                                    dataStation !== null ?
                                                                        dataStation.map
                                                                        (
                                                                            (item, index) =>
                                                                            {
                                                                                return (
                                                                                    <div className="station-item" onClick={() => handleAddTodStationSelected(index)}>{item.stationName}</div>
                                                                                );
                                                                            }
                                                                        )
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-work-flow-selected">Selected Workflow</InputLabel>
                                                            <div className="stations-selected-box">
                                                                {
                                                                    dataStationSelected !== null ?
                                                                        dataStationSelected.map
                                                                        (
                                                                            (item, index) =>
                                                                            {
                                                                                return (
                                                                                    (item !== undefined) ?
                                                                                    <div className="station-item" id={'station-item-' + index} draggable={true} onDragOver={(e) => e.preventDefault()} onDragStart={handleDrag} onDrop={handleDrop}>
                                                                                        <div className="title">{item.stationName}</div>
                                                                                        <span className="abbreviation">{item.abbreviation}</span>
                                                                                        <IconButton className="btn-remove" onClick={() => handleRemovedStationSelected(index)}><CloseIcon /></IconButton>
                                                                                    </div>
                                                                                        : null
                                                                                );
                                                                            }
                                                                        )
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Button className="btn-blue btn-blue-outline" onClick={() => handleFormJobSectionShowHide(4, 2)} fullWidth={false} variant="contained">Continue</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                {/* section Review */}
                                <div className="section-box">
                                    <div className={formJobSectionShowHide[4] === 2 ? "section-title-bar done" : "section-title-bar"}>
                                        <div>
                                            <span className="title">4. Review</span>
                                            <Button className="btn-edit" onClick={() => handleFormJobSectionShowHide(4, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                        </div>
                                        <IconButton className="btn-close-open" onClick={() => handleFormJobSectionShowHide(4, 0)}>{formJobSectionShowHide[4] === 1 ? <ArrowDropUpOutlinedIcon /> : (formJobSectionShowHide[4] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                                    </div>
                                    {
                                        formJobSectionShowHide[4] === 1 ?
                                            <div className="section-content">
                                                <Container className="form-container">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4}>
                                                            <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-work-flow-items">Product TYpe</InputLabel>
                                                            <div className="review-item">{formJobCategoryName}</div>
                                                            <div className="review-item">{formJobProductModelName}</div>
                                                            <div className="review-item">{formJobPricing}</div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={4}>
                                                            <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-work-flow-items">Custmoization</InputLabel>
                                                            <div className="review-item">{formJobQuantity}</div>
                                                            <div className="review-item">{formJobProductDetails}</div>
                                                            <div className="review-item">{formJobProductionNotes}</div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={4}>
                                                            <InputLabel shrink className="form-label form-label-t2" htmlFor="form-job-work-flow-items">Workflow</InputLabel>
                                                            {
                                                                dataStationSelected !== null ?
                                                                    dataStationSelected.map
                                                                    (
                                                                        (item) =>
                                                                        {
                                                                            return (
                                                                                <div className="review-item">{item.stationName}</div>
                                                                            );
                                                                        }
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </>
                            :
                            <Loading />
                    }
                </DialogContent>
                {
                    !dataLoading ?
                        <DialogActions className="dialog-actions dialog-actions-2">
                            {
                                !formLoading ?
                                    <>
                                        <div className="">
                                            <Button onClick={saveJob} className="dialog-btn-save">Save Job</Button>
                                            <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                                        </div>

                                        {
                                            formJobId === '' ?
                                                null
                                                :
                                                <Button onClick={handleDeleteJob} className="dialog-btn-delete">Delete From Order</Button>
                                        }
                                    </>
                                    :
                                    <Loading />
                            }
                        </DialogActions>
                        :
                        null
                }
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default Job;
