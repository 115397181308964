import React from "react";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './SortTable.css';

//--------------------------------------------------

function SortTable(props)
{
    const sortData = props.sortData;
    const colName = props.colName;

    //
    return (
        <>
            {(sortData[colName] !== undefined && sortData[colName] !== '' && sortData['lastSortType'] !== 'desc') ? (sortData[colName] === 'asc' ? <ArrowRightAltIcon className="col-sort asc" /> : <ArrowRightAltIcon className="col-sort desc" />) : <SyncAltIcon className="col-sort" />}
        </>
    );
}

export default SortTable;
