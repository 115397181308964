import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination";
import {Button, Grid} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import ReportApi from "../../services/ReportApi";
import VendorApi from "../../services/VendorApi";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import "./Reports.css";
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import Search from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tabs from "@mui/material/Tabs";
import Tab from '@mui/material/Tab';
import StockApi from "../../services/StockApi";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import AlertAction from "../../components/AlertAction";
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SortTable from "../../components/SortTable/SortTable";
import General from "../../utils/General";
import BorderColorIcon from '@mui/icons-material/BorderColor';

//--------------------------------------------------

function Reports(props)
{
    // set page title & page path
    const pageTitle = 'Stock Orders';
    usePage().pageData({title: Menus.jobs.title, activeMenu: 'reports'});

    //
    const [data, setData] = useState([]);
    const [dataVendor, setDataVendor] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [searchWord, setSearchWord] = useState("");
    const [filterVendor, setFilterVendor] = useState('');
    const [reportType, setReportType] = useState('special'); // history or special

    const [orderStockFormDialogOpen, setOrderStockFormDialogOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formOrderStockId, setFormOrderStockId] = useState('');
    const [formOrderStockVendor, setFormOrderStockVendor] = useState('');
    const [formOrderStockQuantity, setFormOrderStockQuantity] = useState('');
    const [formOrderStockSize, setFormOrderStockSize] = useState('');
    const [formOrderStockCallCustomer, setFormOrderStockCallCustomer] = useState(false);
    const [formOrderStockProductDetail, setFormOrderStockProductDetail] = useState('');
    const [formOrderStockDateOrdered, setFormOrderStockDateOrdered] = useState('');
    const [formOrderStockDateReceived, setFormOrderStockDateReceived] = useState('');

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading
        let objApi;

        // get data
        if(reportType === 'history')
        {
            objApi = ReportApi.findOrderHistory({ pageNumber: pageNumber, searchWord: searchWord, vendor: filterVendor, sortColName: sortColName, sortType: sortType })
        }
        else
        {
            objApi = ReportApi.findOrder({ pageNumber: pageNumber, vendor: filterVendor, sortColName: sortColName, sortType: sortType })
        }
        objApi.then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataVendor = () =>
    {
        setDataVendor(null); // set data to null

        // get data
        VendorApi.find({}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataVendor(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { if(dataVendor === null || dataVendor.length === 0) loadDataVendor(); }, []);
    useEffect(() => { loadData(); }, [filterVendor, sortColName, sortType, reportType]);

    // search
    const handleSearch = (e) =>
    {
        loadData(1);
    };

    // download
    const handleDownload = (e) =>
    {
        let objApi;

        // get data
        if(reportType === 'history')
        {
            objApi = ReportApi.downloadOrderHistory({ vendor: filterVendor })
        }
        else
        {
            objApi = ReportApi.downloadOrder({ vendor: filterVendor })
        }
        objApi.then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    let csvContent = "data:text/csv;charset=utf-8," + response.data.map(e => e.join(",")).join("\n");
                    let encodedUri = encodeURI(csvContent);
                    let link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "qviewReport.csv");
                    document.body.appendChild(link);
                    link.click();
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    // tab click
    const handleTabChange = (event, newValue) =>
    {
        setReportType(newValue);
    };

    // show form
    const handleOrderStockFormDialog = (e) =>
    {
        resetForm();

        setOrderStockFormDialogOpen(!orderStockFormDialogOpen);
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    // orderStock save
    const saveOrderStock = () =>
    {
        if(formOrderStockVendor !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'itemId': formOrderStockId, 'vendor': formOrderStockVendor, 'quantity': formOrderStockQuantity, 'size': formOrderStockSize, 'details': formOrderStockProductDetail, 'callCustomer': formOrderStockCallCustomer, 'dateOrdered': General.parseDateSafe(formOrderStockDateOrdered), 'dateReceived': General.parseDateSafe(formOrderStockDateReceived)};
            StockApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Order Stock Saved!", 'success'); // show success

                        if(formOrderStockId !== '') setOrderStockFormDialogOpen(false);
                        resetForm();

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormOrderStockId('');
        setFormOrderStockVendor('');
        setFormOrderStockQuantity('');
        setFormOrderStockSize('');
        setFormOrderStockCallCustomer(false);
        setFormOrderStockProductDetail('');
        setFormOrderStockDateOrdered('');
        setFormOrderStockDateReceived('');
    };

    // orderStock edit
    const handleOrderStockEdit = (index) =>
    {
        setFormOrderStockId(data[index].stockItemId);
        setFormOrderStockVendor(data[index].vendorId);
        setFormOrderStockQuantity(data[index].quantity);
        setFormOrderStockSize(data[index].size);
        setFormOrderStockCallCustomer(false);
        setFormOrderStockProductDetail(data[index].details);
        setFormOrderStockDateOrdered(data[index].ordered);
        setFormOrderStockDateReceived(data[index].received);

        setOrderStockFormDialogOpen(true);
    };

    // items save
    const saveItems = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // check date
        let tmpData = data;
        for (let i = 0; i < data.length; i++)
        {
            if(tmpData[i].orderedTemp !== undefined) tmpData[i].ordered = tmpData[i].orderedTemp;
            if(tmpData[i].receivedTemp !== undefined) tmpData[i].received = tmpData[i].receivedTemp;
        }
        setData([...tmpData, ...[]]);

        // send data
        const params = {'data': data};
        ReportApi.saveItems(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Order Stock Items Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setDataLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // Date Change
    const handleDateChange = (date, index, type) =>
    {
        let tmpData = data;

        if(type === 'ordered')
        {
            tmpData[index].ordered = date;
            tmpData[index].orderedTemp = General.parseDateSafe(date);
        }
        else
        {
            tmpData[index].received = date;
            tmpData[index].receivedTemp = General.parseDateSafe(date);
        }

        setData([...tmpData, ...[]]);

        // save
        for (let i = 0; i < tmpData.length; i++)
        {
            if(tmpData[i].orderedTemp !== undefined) tmpData[i].ordered = tmpData[i].orderedTemp;
            if(tmpData[i].receivedTemp !== undefined) tmpData[i].received = tmpData[i].receivedTemp;
        }

        const params = {'data': tmpData};
        ReportApi.saveItems(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    if(type === 'received') loadData();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-reports">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>

                <Tabs value={reportType} onChange={handleTabChange}>
                    <Tab className="tab" label="Order History" value="history" />
                    <Tab className="tab" label="Active Or Special Order" value="special" />
                </Tabs>
            </div>

            <div className="filter-bar">
                {
                    reportType === 'history' ?
                        <Box className="search-box form-box">
                            <Search className="icon" onClick={handleSearch} />
                            <TextField placeholder="Search" className="form-input" onChange={(e) => setSearchWord(e.target.value)} />
                        </Box>
                        :
                        <Button onClick={handleOrderStockFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Stock item</Button>
                }

                <div>
                    <FormControl className="filter-select-box" size="small">
                        <InputLabel id="filter-status-label">Filter Vendor</InputLabel>
                        <Select labelId="filter-status-label" value={filterVendor} label="People" onChange={(e) => setFilterVendor(e.target.value)}>
                            <MenuItem value="">&nbsp;</MenuItem>
                            {
                                dataVendor !== null ?
                                    dataVendor.map
                                    (
                                        (item) =>
                                        {
                                            return (
                                                <MenuItem value={item.vendorId}>{item.vendorName}</MenuItem>
                                            );
                                        }
                                    )
                                    :
                                    null
                            }
                        </Select>
                    </FormControl>

                    <Button onClick={handleDownload} className="btn-black btn-black-outline btn-download" fullWidth={false} variant="contained" startIcon={<FileDownloadIcon />}>Download</Button>
                    {/*<Button onClick={saveItems} className="btn-blue btn-blue-outline m-l-16" fullWidth={false} variant="contained">Save</Button>*/}
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('vendor')}><span className="col-value"><SortTable sortData={sortData} colName="vendor" />Vendor</span></th>
                                        <th onClick={() => changeSortTable('quantity')}><span className="col-value"><SortTable sortData={sortData} colName="quantity" />Quantity</span></th>
                                        <th onClick={() => changeSortTable('size')}><span className="col-value"><SortTable sortData={sortData} colName="size" />Size</span></th>
                                        <th onClick={() => changeSortTable('details')}><span className="col-value"><SortTable sortData={sortData} colName="details" />Details</span></th>
                                        <th onClick={() => changeSortTable('ordered')} className="w-120"><span className="col-value"><SortTable sortData={sortData} colName="ordered" />Ordered</span></th>
                                        <th onClick={() => changeSortTable('received')} className="w-120"><span className="col-value"><SortTable sortData={sortData} colName="received" />Received</span></th>
                                        <th onClick={() => changeSortTable('stockSO')}><span className="col-value"><SortTable sortData={sortData} colName="stockSO" />Stock/so</span></th>
                                        <th className="w-40"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr>
                                                        <td>{item.vendor}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.size}</td>
                                                        <td>{item.details}</td>
                                                        <td>
                                                            {
                                                                reportType === 'history' ?
                                                                    item.ordered
                                                                    :
                                                                    <div className="input-date-box">
                                                                        <DatePicker dateFormat="yyyy/MM/dd" selected={item.ordered !== '' ? new Date(item.ordered) : ''} value={item.ordered !== '' ? new Date(item.ordered) : ''} onChange={(e) => handleDateChange(e, index, 'ordered')} />
                                                                    </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                reportType === 'history' ?
                                                                    item.received
                                                                    :
                                                                    <div className="input-date-box">
                                                                        <DatePicker dateFormat="yyyy/MM/dd" selected={item.received !== '' ? new Date(item.received) : ''} value={item.received !== '' ? new Date(item.received) : ''} onChange={(e) => handleDateChange(e, index, 'received')} />
                                                                    </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                reportType === 'history' ?
                                                                    item.stockSO
                                                                    :
                                                                    item.jobId === '' ?
                                                                        item.stockSO
                                                                        :
                                                                        <Link to={"/job/" + item.jobId} className="link">{item.stockSO}</Link>
                                                            }
                                                        </td>
                                                        <td>{item.jobId === '' ? <IconButton className="icon-edit" onClick={() => handleOrderStockEdit(index)}><BorderColorIcon /></IconButton> : null}</td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="10"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* orderStock form */}
            <Dialog open={orderStockFormDialogOpen} onClose={handleOrderStockFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formOrderStockId === '' ? 'Add' : 'Edit'} Order Stock Item</span>
                        <IconButton className="dialog-close" onClick={handleOrderStockFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-order-stock-vendor">Vendor</InputLabel>
                        <Select className="form-select" id="form-order-stock-vendor" value={formOrderStockVendor} onChange={(e) => setFormOrderStockVendor(e.target.value)}>
                            <MenuItem value="">&nbsp;</MenuItem>
                            {
                                dataVendor !== null ?
                                    dataVendor.map
                                    (
                                        (item) =>
                                        {
                                            return (
                                                <MenuItem value={item.vendorId}>{item.vendorName}</MenuItem>
                                            );
                                        }
                                    )
                                    :
                                    null
                            }
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-order-stock-quantity">Quantity</InputLabel>
                        <TextField className="form-input" id="form-order-stock-quantity" placeholder="" value={formOrderStockQuantity} onChange={(e) => setFormOrderStockQuantity(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-order-stock-size">Size</InputLabel>
                        <TextField className="form-input" id="form-order-stock-size" placeholder="" value={formOrderStockSize} onChange={(e) => setFormOrderStockSize(e.target.value)} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {/*<InputLabel shrink className="form-label" htmlFor="form-quote-date-ordered">Date Ordered</InputLabel>*/}
                        <InputLabel shrink className="form-label" htmlFor="form-quote-date-ordered">Date Requested</InputLabel>
                        <div className="form-input">
                            <DatePicker id="form-quote-date-ordered" dateFormat="yyyy/MM/dd" selected={formOrderStockDateOrdered !== '' ? new Date(formOrderStockDateOrdered) : ''} value={formOrderStockDateOrdered !== '' ? new Date(formOrderStockDateOrdered) : ''} onChange={(e) => setFormOrderStockDateOrdered(e)} />
                        </div>
                    </Grid>

                    {
                        formOrderStockId === '' ?
                            null
                            :
                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-date-received">Date Received</InputLabel>
                                <div className="form-input">
                                    <DatePicker id="form-quote-date-ordered" dateFormat="yyyy/MM/dd" selected={formOrderStockDateReceived !== '' ? new Date(formOrderStockDateReceived) : ''} value={formOrderStockDateReceived !== '' ? new Date(formOrderStockDateReceived) : ''} onChange={(e) => setFormOrderStockDateReceived(e)} />
                                </div>
                            </Grid>
                    }

                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <FormControlLabel control={<Checkbox checked={formOrderStockCallCustomer} onChange={(e) => setFormOrderStockCallCustomer(e.target.checked)} />} className="form-control-label-check-box form-order-stock-call-customer-check-box" label="Call Customer" />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-order-stock-product-detail">Product Details</InputLabel>
                        <TextField className="form-input" id="form-order-stock-product-detail" multiline={true} placeholder="" value={formOrderStockProductDetail} onChange={(e) => setFormOrderStockProductDetail(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveOrderStock} className="dialog-btn-save">Save Order Stock</Button>
                                <Button onClick={handleOrderStockFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Reports;
