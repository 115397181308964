import {useState} from "react";

class General
{
    siteTitle = "QView";

    siteUrl = "https://qview.mohan21.ir/api/webservice";
    // siteUrl = "https://qviewapi.jpgtowebp.org/webservice";
    // siteUrl = "http://127.0.0.1/mohan21/qview/backend/webservice";
    // siteUrl = process.env.NODE_ENV === "development" ? "http://dcapi.k24.ir/api/v1" : "http://dcapi.k24.ir/api/v1";

    // token
    authorizationToken = "";
    authorizationNameFamily = "";

    // init status class
    initStatusClass = (status) =>
    {
        return status.replace(/ /g, '-').toLowerCase();
    };

    // date
    parseDateSafe = (fullDate) =>
    {
        if(fullDate !== '')
        {
            let date = new Date(fullDate), mnth = ("0" + (date.getMonth() + 1)).slice(-2), day = ("0" + date.getDate()).slice(-2);

            return [date.getFullYear(), mnth, day].join("/");
        }

        return '';
    };
}

export default new General();
