import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import QuoteApi from "../../services/QuoteApi";
import PaymentDefaultApi from "../../services/PaymentDefaultApi";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import General from "../../utils/General";
import './Quote.css';
import Customer from "../../components/Customer/Customer";
import CustomerApi from "../../services/CustomerApi";
import Autocomplete from '@mui/material/Autocomplete';

//--------------------------------------------------

let formQuoteCustomerIdTemp = '';
let formQuoteCustomerNameTemp = '';

//--------------------------------------------------

function Quote(props)
{
    //
    const [dataPaymentTerm, setDataPaymentTerm] = useState([]);
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [searchCustomerData, setSearchCustomerData] = useState([]);

    const [flagEdit, setFlagEdit] = useState(false);
    const [newQuoteLoading, setNewQuoteLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formQuoteId, setFormQuoteId] = useState('');
    const [formQuoteQuoteNumber, setFormQuoteQuoteNumber] = useState('');
    const [formQuoteQuoteName, setFormQuoteQuoteName] = useState('');
    const [formQuoteDateOrdered, setFormQuoteDateOrdered] = useState('');
    const [formQuoteEstimatedDueDate, setFormQuoteEstimatedDueDate] = useState('');
    const [formQuoteSalesperson, setFormQuoteSalesperson] = useState('');
    const [formQuoteDateRequired, setFormQuoteDateRequired] = useState('');
    const [formQuoteCustomerId, setFormQuoteCustomerId] = useState('');
    const [formQuoteCustomerName, setFormQuoteCustomerName] = useState('');
    const [formQuotePaymentTerm, setFormQuotePaymentTerm] = useState('');
    const [formEstimateSignOffRequired, setFormEstimateSignOffRequired] = useState('');

    const [isActiveSearchCustomer, setIsActiveSearchCustomer] = useState(true);
    const [isSaved, setIsSaved] = useState(false);

    const alertAction = useRef(); // init alert

    // load data
    const loadDataPaymentTerm = () =>
    {
        setDataPaymentTerm(null); // set data to null

        // get data
        PaymentDefaultApi.findPaymentTerm().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataPaymentTerm(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadDataPaymentTerm(); newQuote(); }, []);

    // load new quote
    const newQuote = () =>
    {
        setFormQuoteId('');
        setFormQuoteQuoteNumber('');
        setNewQuoteLoading(true); // show loading

        // get data
        QuoteApi.newQuote().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setFormQuoteId(response.data.quoteId);
                    setFormQuoteQuoteNumber(response.data.quoteNumber);
                }

                setNewQuoteLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                props.handleDialog();
                setNewQuoteLoading(false); // hide loading
            }
        );
    };

    // search & add customer
    const newCustomer = (id, name, dc) =>
    {
        formQuoteCustomerIdTemp = id;
        formQuoteCustomerNameTemp = name;

        setIsActiveSearchCustomer(false);
    };

    const searchCustomer = (searchWord) =>
    {
        formQuoteCustomerIdTemp = '';
        formQuoteCustomerNameTemp = '';
        setFormQuoteCustomerId('');
        setFormQuoteCustomerName('');
        setSearchCustomerData([]);

        if(searchWord.length >= 2)
        {
            CustomerApi.find({ searchTerm: searchWord }).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        const dataTemp = [];

                        for (let i = 0; i < response.data.list.length; i++)
                        {
                            dataTemp[i] = {'id': response.data.list[i].customerId, 'label': response.data.list[i].name}
                        }

                        setSearchCustomerData(dataTemp);
                    }
                }
            ).catch
            (
                function(error)
                {
                }
            );
        }
    };

    const searchCustomerSelect = (e, item) =>
    {
        formQuoteCustomerIdTemp = item.id;
        formQuoteCustomerNameTemp = item.id;
        setFormQuoteCustomerId(item.id);
        setFormQuoteCustomerName(item.label);
    };

    // quote save
    const saveQuote = () =>
    {
        if (formQuoteQuoteNumber !== "" && formQuoteQuoteName !== "" && formQuoteEstimatedDueDate !== "" && formQuoteSalesperson !== "" && formQuoteCustomerIdTemp !== "" && formQuotePaymentTerm !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'quoteId': formQuoteId,
                    'quoteNumber': formQuoteQuoteNumber,
                    'quoteName': formQuoteQuoteName,
                    'dateRequired': formQuoteDateRequired,
                    'dateDue': General.parseDateSafe(formQuoteEstimatedDueDate),
                    'salesPerson': formQuoteSalesperson,
                    'customerId': formQuoteCustomerIdTemp,
                    'paymentTerms': formQuotePaymentTerm,
                    'signoffRequired': formEstimateSignOffRequired,
                };
            QuoteApi.save(params).then
            (
                function (response)
                {
                    if (response.status === 200)
                    {
                        alertAction.current.showAlert("Quote Saved!", 'success'); // show success

                        resetForm();
                        //props.loadData();
                        setFormQuoteId(response.data.quoteId);
                        setIsSaved(true);
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function (error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormQuoteId('');
        setFormQuoteQuoteNumber('');
        setFormQuoteQuoteName('');
        setFormQuoteDateRequired('');
        setFormQuoteDateOrdered('');
        setFormQuoteEstimatedDueDate('');
        setFormQuoteSalesperson('');
        setFormQuoteCustomerId('');
        setFormQuotePaymentTerm('');
        setFormEstimateSignOffRequired('');

        setNewQuoteLoading(false);

        formQuoteCustomerIdTemp = '';
        formQuoteCustomerNameTemp = '';
    };

    // redirect to new quote
    if(isSaved)
    {
        return <Navigate to={"/quote/" + formQuoteId} />;
    }

    //
    return (
        <>
            {/* quote form */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='md'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{!flagEdit ? 'Add New' : 'Edit'} Quote</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box form-quote-box form-box-t2">
                    {
                        !newQuoteLoading ?
                            <Container className="form-container">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-quote-number">Quote Number</InputLabel>
                                        <TextField className="form-input" id="form-quote-quote-number" placeholder="" value={formQuoteQuoteNumber} disabled="disabled" />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-quote-name">Quote Name</InputLabel>
                                        <TextField className="form-input" id="form-quote-quote-name" placeholder="" value={formQuoteQuoteName} onChange={(e) => setFormQuoteQuoteName(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-date-ordered">Date Ordered</InputLabel>
                                        <div className="form-input">
                                            <DatePicker id="form-quote-date-ordered" dateFormat="yyyy/MM/dd" selected={formQuoteDateOrdered} value={formQuoteDateOrdered} onChange={(e) => setFormQuoteDateOrdered(e)} />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-estimated-due-date">Estimated Due Date</InputLabel>
                                        <div className="form-input">
                                            <DatePicker id="form-quote-estimated-due-date" dateFormat="yyyy/MM/dd" selected={formQuoteEstimatedDueDate} value={formQuoteEstimatedDueDate} onChange={(e) => setFormQuoteEstimatedDueDate(e)} />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-salesperson">Salesperson</InputLabel>
                                        <TextField className="form-input" id="form-quote-salesperson" placeholder="" value={formQuoteSalesperson} onChange={(e) => setFormQuoteSalesperson(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel control={<Checkbox checked={formQuoteDateRequired} onChange={(e) => setFormQuoteDateRequired(e.target.checked)} />} className="form-control-label-check-box" label="Date Required" />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <div className="form-title-bar">Add Customer Details</div>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-customer">Find Customer</InputLabel>
                                        {
                                            isActiveSearchCustomer ?
                                                <Autocomplete
                                                    className="form-input form-input-search"
                                                    value={formQuoteCustomerName}
                                                    freeSolo
                                                    disableClearable
                                                    disablePortal
                                                    options={searchCustomerData}
                                                    onInputChange={(event, newValue) => searchCustomer(newValue)}
                                                    onChange={searchCustomerSelect}
                                                    renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                                />
                                                :
                                                <TextField className="form-input" id="form-quote-customer" placeholder="" value={formQuoteCustomerNameTemp} onFocus={() => setIsActiveSearchCustomer(true)} />
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-customer-new">&nbsp;</InputLabel>
                                        <div className="form-quote-customer-new">
                                            <span>-or-</span>
                                            <Button onClick={() => setCustomerDialogOpen(true)} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Customer</Button>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <div className="form-title-bar">Add Payment Terms</div>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className="form-label" htmlFor="form-quote-payment-term">Payment Terms</InputLabel>
                                        <Select className="form-select" id="form-quote-payment-term" value={formQuotePaymentTerm} onChange={(e) => setFormQuotePaymentTerm(e.target.value)}>
                                            <MenuItem value="">&nbsp;</MenuItem>
                                            {
                                                dataPaymentTerm !== null ?
                                                    dataPaymentTerm.map
                                                    (
                                                        (item) =>
                                                        {
                                                            return (
                                                                <MenuItem value={item.termId}>{item.termName}</MenuItem>
                                                            );
                                                        }
                                                    )
                                                    :
                                                    null
                                            }
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <FormControlLabel control={<Checkbox checked={formEstimateSignOffRequired} onChange={(e) => setFormEstimateSignOffRequired(e.target.checked)} />} className="form-control-label-check-box" label="Estimate SignOff Requireds" />
                                    </Grid>
                                </Grid>
                            </Container>
                            :
                            <Loading />
                    }
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveQuote} className="dialog-btn-save">{!flagEdit ? 'Create New' : 'Save'} Quote</Button>
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>

            {/* customer */}
            {customerDialogOpen ? <Customer handleDialog={() => setCustomerDialogOpen(false)} closeAfterSave={true} newCustomer={newCustomer} customerData={null} loadData={() => {}} /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default Quote;
