import { forwardRef, useImperativeHandle } from 'react';
import { useSnackbar } from 'notistack';

//--------------------------------------------------
const AlertAction = forwardRef((props, ref) =>
    {
        const { enqueueSnackbar } = useSnackbar();
        // alertType = 'default' | 'error' | 'success' | 'warning' | 'info'

        useImperativeHandle
        (
            ref, () =>
                (
                    {
                        showAlert: (message, alertType = 'error') =>
                        {
                            enqueueSnackbar(message, {variant: alertType, anchorOrigin: {vertical: 'top', horizontal: 'left'}});
                        },
                    }
                )
        );

        return;
    }
);

export default AlertAction;
