import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid} from "@mui/material";
import Loading from "../Loading/Loading";
import InputLabel from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField/TextField";
import QuoteApi from "../../services/QuoteApi";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import AlertAction from "../AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import General from "../../utils/General";
import "./QuoteEdit.css";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";

//--------------------------------------------------

function QuoteEdit(props)
{
    //
    const [formQuoteLoading, setFormQuoteLoading] = useState(false);
    const [formQuoteQuoteNumber, setFormQuoteQuoteNumber] = useState('');
    const [formQuoteQuoteName, setFormQuoteQuoteName] = useState('');
    const [formQuoteDateOrdered, setFormQuoteDateOrdered] = useState('');
    const [formQuoteEstimatedDueDate, setFormQuoteEstimatedDueDate] = useState('');
    const [formQuoteSalesperson, setFormQuoteSalesperson] = useState('');
    const [formQuoteDateRequired, setFormQuoteDateRequired] = useState(false);

    const alertAction = useRef(); // init alert

    useEffect(() => { if(props.quoteData != null) handleQuoteEdit(props.quoteData); }, []);

    // quote save
    const saveQuote = () =>
    {

        // alertAction.current.showAlert("Quote Saved!", 'success'); // show success
        // props.handleDialog();
        // props.loadData();



        if (formQuoteQuoteName !== "" && setFormQuoteDateOrdered !== "" && formQuoteEstimatedDueDate !== "" && formQuoteSalesperson !== "") // check data is correct
        {
            //setFormQuoteLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'quoteId': props.quoteData.id,
                    'quoteNumber': props.quoteData.quoteNumber,
                    'quoteName': formQuoteQuoteName,
                    'dateRequired': formQuoteDateRequired,
                    'dateDue': General.parseDateSafe(formQuoteEstimatedDueDate),
                    'salesPerson': formQuoteSalesperson,
                    'customerId': props.quoteData.customer.customerId,
                    'paymentTerms': props.quoteData.payment.id,
                    'signoffRequired': props.quoteData.payment.estimateSignOff,
                };
            QuoteApi.save(params).then
            (
                function (response)
                {
                    if (response.status === 200)
                    {
                        alertAction.current.showAlert("Quote Saved!", 'success'); // show success

                        props.handleDialog();
                        props.loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    // setFormQuoteLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function (error)
                {
                    // setFormQuoteLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // quote edit
    const handleQuoteEdit = (data) =>
    {
        setFormQuoteQuoteNumber(data.quoteNumber);
        setFormQuoteQuoteName(data.order.name);
        setFormQuoteDateOrdered(data.order.createdDate2);
        setFormQuoteEstimatedDueDate(data.order.dueDate2);
        setFormQuoteSalesperson(data.order.salesPerson);
        setFormQuoteDateRequired(data.order.isDueDateRequired);
    };

    //
    return (
        <>
            {/* quote form */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='md'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Edit Quote Details</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-quote-box form-box-t2">
                    <Container className="form-container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-quote-number">Quote Number</InputLabel>
                                <TextField className="form-input" id="form-quote-quote-number" disabled={true} placeholder="" value={formQuoteQuoteNumber} onChange={(e) => setFormQuoteQuoteNumber(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-quote-name">Quote Name</InputLabel>
                                <TextField className="form-input" id="form-quote-quote-name" placeholder="" value={formQuoteQuoteName} onChange={(e) => setFormQuoteQuoteName(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-date-ordered">Date Ordered</InputLabel>
                                <div className="form-input">
                                    <DatePicker id="form-quote-date-ordered" dateFormat="yyyy/MM/dd" selected={formQuoteDateOrdered !== '' ? new Date(formQuoteDateOrdered) : ''} value={formQuoteDateOrdered !== '' ? new Date(formQuoteDateOrdered) : ''} onChange={(e) => setFormQuoteDateOrdered(e)} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-estimated-due-date">Estimated Due Date</InputLabel>
                                <div className="form-input">
                                    <DatePicker id="form-quote-estimated-due-date" dateFormat="yyyy/MM/dd" selected={formQuoteEstimatedDueDate !== '' ? new Date(formQuoteEstimatedDueDate) : ''} value={formQuoteEstimatedDueDate !== '' ? new Date(formQuoteEstimatedDueDate) : ''} onChange={(e) => setFormQuoteEstimatedDueDate(e)} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-salesperson">Salesperson</InputLabel>
                                <TextField className="form-input" id="form-quote-salesperson" placeholder="" value={formQuoteSalesperson} onChange={(e) => setFormQuoteSalesperson(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormControlLabel control={<Checkbox checked={formQuoteDateRequired} onChange={(e) => setFormQuoteDateRequired(e.target.checked)} />} className="form-control-label-check-box" label="Required Due Date" />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formQuoteLoading ?
                            <>
                                <Button onClick={saveQuote} className="dialog-btn-save">Save Quote</Button>
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default QuoteEdit;
