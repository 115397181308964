import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Loading from "../../components/Loading/Loading";
import AlertAction from "../../components/AlertAction";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import WorkflowPreferenceApi from "../../services/WorkflowPreferenceApi";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import "./WorkflowPreference.css";

//--------------------------------------------------

function WorkflowPreference(props)
{
    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const [formLoading, setFormLoading] = useState(false);
    const [formWorkflowPreferenceAutoArchiveCompleted, setFormWorkflowPreferenceAutoArchiveCompleted] = useState('');
    const [formWorkflowPreferenceAutoArchiveCancelled, setFormWorkflowPreferenceAutoArchiveCancelled] = useState('');
    const [formWorkflowPreferenceApprove, setFormWorkflowPreferenceApprove] = useState('');
    const [formWorkflowPreferencePickup, setFormWorkflowPreferencePickup] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        WorkflowPreferenceApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data);

                    setFormWorkflowPreferenceAutoArchiveCompleted(response.data['completedArchive'].toString());
                    setFormWorkflowPreferenceAutoArchiveCancelled(response.data['cancelledArchive'].toString());
                    setFormWorkflowPreferenceApprove(response.data['approve']);
                    setFormWorkflowPreferencePickup(response.data['pickup']);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // workflowPreference save
    const saveWorkflowPreference = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'completedArchive': formWorkflowPreferenceAutoArchiveCompleted, 'cancelledArchive': formWorkflowPreferenceAutoArchiveCancelled, 'approve': formWorkflowPreferenceApprove, 'pickup': formWorkflowPreferencePickup};
        WorkflowPreferenceApi.save(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Workflow Preference Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <>
            {/* workflowPreference form */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Manage Workflow Preferences</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    {
                        dataLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                <>
                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label form-label-t2" htmlFor="form-workflowPreference-name">Auto-archive <span className="dark">completed</span> orders</InputLabel>
                                        <ToggleButtonGroup className="form-button-group" value={formWorkflowPreferenceAutoArchiveCompleted} exclusive onChange={(e, v) => setFormWorkflowPreferenceAutoArchiveCompleted(v)}>
                                            <ToggleButton value="0">Immediately</ToggleButton>
                                            <ToggleButton value="1">1 Day</ToggleButton>
                                            <ToggleButton value="2">3 Days</ToggleButton>
                                            <ToggleButton value="3">5 Days</ToggleButton>
                                            <ToggleButton value="4">1 Week</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label form-label-t2" htmlFor="form-workflowPreference-name">Auto-archive <span className="dark">cancelled</span> orders</InputLabel>
                                        <ToggleButtonGroup className="form-button-group" value={formWorkflowPreferenceAutoArchiveCancelled} exclusive onChange={(e, v) => setFormWorkflowPreferenceAutoArchiveCancelled(v)}>
                                            <ToggleButton value="0">Immediately</ToggleButton>
                                            <ToggleButton value="1">1 Day</ToggleButton>
                                            <ToggleButton value="2">3 Days</ToggleButton>
                                            <ToggleButton value="3">5 Days</ToggleButton>
                                            <ToggleButton value="4">1 Week</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel className="form-control-label-check-box" control={<Checkbox checked={formWorkflowPreferenceApprove} className="form-check-box" onChange={(e) => setFormWorkflowPreferenceApprove(e.target.checked)} />} label="Customer Sign off on quote before production?" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel className="form-control-label-check-box" control={<Checkbox checked={formWorkflowPreferencePickup} className="form-check-box" onChange={(e) => setFormWorkflowPreferencePickup(e.target.checked)} />} label="Customer sign for delivery or pickup?" />
                                    </Grid>
                                </>
                            )
                    }
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveWorkflowPreference} className="dialog-btn-save dialog-btn-save-outline">Save Change</Button>
                                {/*<Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>*/}
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>

            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default WorkflowPreference;
