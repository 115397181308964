import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Loading from "../../components/Loading/Loading";
import AlertAction from "../../components/AlertAction";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import StationApi from "../../services/StationApi";
import "./Station.css";

//--------------------------------------------------

function Station(props)
{
    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const [stationFormDialogOpen, setStationFormDialogOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formStationName, setFormStationName] = useState('');
    const [formStationAbbreviation, setFormStationAbbreviation] = useState('');
    const [formStationId, setFormStationId] = useState('');

    const alertAction = useRef(); // init alert

    // station
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        StationApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // show form
    const handleStationFormDialog = (e) =>
    {
        setFormStationId('');
        setFormStationName('');
        setFormStationAbbreviation('');

        setStationFormDialogOpen(!stationFormDialogOpen);
    };

    // station save
    const saveStation = () =>
    {
        if(formStationName !== "" && formStationAbbreviation !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'stationId': formStationId, 'stationName': formStationName, 'stationAbbreviation': formStationAbbreviation};
            StationApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Station Saved!", 'success'); // show success

                        if(formStationId !== '') setStationFormDialogOpen(false);
                        setFormStationId('');
                        setFormStationName('');
                        setFormStationAbbreviation('');

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // station edit
    const handleStationEdit = (index) =>
    {
        setFormStationId(data[index].stationId);
        setFormStationName(data[index].stationName);
        setFormStationAbbreviation(data[index].abbreviation);

        setStationFormDialogOpen(true);
    };

    // station delete
    const handleStationDelete = (index) =>
    {
        setDataLoading(true); // show loading

        // send data
        const params = {'stationId': data[index].stationId};
        StationApi.delete(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Station Deleted!", 'success'); // show success

                    loadData();
                }
                else
                {
                    setDataLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <>
            {/* station list */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Manage Workflow Stations</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content dialog-content-station">
                    <Button onClick={handleStationFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Station</Button>

                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                        {
                            dataLoading ?
                                (
                                    <Loading />
                                )
                                :
                                (
                                    data !== null ?
                                        <table className="table-data">
                                            <tbody>
                                            {
                                                data.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td>{item.stationName}</td>
                                                                <td>{item.abbreviation}</td>
                                                                <td className="w-80">
                                                                    <IconButton className="icon-edit" onClick={() => handleStationEdit(index)}><BorderColorIcon /></IconButton>
                                                                    <IconButton className="icon-delete" onClick={() => handleStationDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <AlertDataEmpty />
                                )
                        }
                    </Scrollbars>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* station form */}
            <Dialog open={stationFormDialogOpen} onClose={handleStationFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formStationId === '' ? 'Add' : 'Edit'} Station</span>
                        <IconButton className="dialog-close" onClick={handleStationFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-station-name">Station Name</InputLabel>
                        <TextField className="form-input" id="form-station-name" placeholder="" value={formStationName} onChange={(e) => setFormStationName(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-station-abbreviation">Abbreviation</InputLabel>
                        <TextField className="form-input" id="form-station-abbreviation" placeholder="" value={formStationAbbreviation} onChange={(e) => setFormStationAbbreviation(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveStation} className="dialog-btn-save">Save Station</Button>
                                <Button onClick={handleStationFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>

            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default Station;
