import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading/Loading";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {Button, Container, Grid, TextField} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import QuoteApi from "../../services/QuoteApi";
import OrderApi from "../../services/OrderApi";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./Order.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from "@mui/material/Typography";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import General from "../../utils/General";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Customer from "../../components/Customer/Customer";
import Task from "../../components/Task/Task";
import Job from "../../components/Job/Job";
import QuoteEdit from "../../components/QuoteEdit/QuoteEdit";
import PaymentDefaultApi from "../../services/PaymentDefaultApi";
import "react-datepicker/dist/react-datepicker.css";
import TaskApi from "../../services/TaskApi";
import CourierApi from "../../services/CourierApi";
import DatePicker from "react-datepicker";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CustomerApi from "../../services/CustomerApi";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import imageStationArrowIcon from "../../images/station-arrow-icon.png";

//--------------------------------------------------

let formCustomerCustomerIdTemp = '';
let formCustomerCustomerNameTemp = '';

//--------------------------------------------------

function Order(props)
{
    // get id
    let { id } = useParams();

    // set page title & page path
    const pageTitle = 'Order';
    usePage().pageData({title: 'Orders', activeMenu: 'orders'});

    //
    const [data, setData] = useState(null);
    const [dataCourier, setDataCourier] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [quoteNumber, setQuoteNumber] = useState('');
    const [quoteStatus, setQuoteStatus] = useState('');
    const [quoteStatusId, setQuoteStatusId] = useState('');
    const [orderJobs, setOrderJobs] = useState(null);
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [customerNewDialogOpen, setCustomerNewDialogOpen] = useState(false);
    const [taskDialogOpen, setTaskDialogOpen] = useState(false);
    const [dataTask, setDataTask] = useState(null);
    const [dataTasks, setDataTasks] = useState(null);
    const [dataCustomer, setDataCustomer] = useState(null);
    const [productionNotes, setProductionNotes] = useState('');
    const [dataPaymentTerm, setDataPaymentTerm] = useState([]);
    const [paymentTermFormDialogOpen, setPaymentTermFormDialogOpen] = useState(false);
    const [formPaymentTermLoading, setFormPaymentTermLoading] = useState(false);
    const [formPaymentTermPaymentTerm, setFormPaymentTermPaymentTerm] = useState('');
    const [formPaymentTermEstimateSignOffRequired, setFormPaymentTermEstimateSignOffRequired] = useState('');
    const [quoteFormDialogOpen, setQuoteFormDialogOpen] = useState(false);
    const [jobFormDialogOpen, setJobFormDialogOpen] = useState(false);
    const [sectionTaskPreFlag, setSectionTaskPreFlag] = useState(false);
    const [sectionTaskPostFlag, setSectionTaskPostFlag] = useState(false);
    const [flagOrderChangeStatus, setFlagOrderChangeStatus] = useState(false);

    const [formPickupDeliveryPerson, setFormPickupDeliveryPerson] = useState('');
    const [formPickupDeliveryCourier, setFormPickupDeliveryCourier] = useState('');
    const [formPickupDeliveryDate, setFormPickupDeliveryDate] = useState('');
    const [formPickupDeliveryLoading, setFormPickupDeliveryLoading] = useState('');
    const [pickupDeliveryType, setPickupDeliveryType] = useState('pickup');

    const [dataPaymentMethod, setDataPaymentMethod] = useState([]);
    const [formPaymentAmount, setFormPaymentAmount] = useState('');
    const [formPaymentPaymentMethod, setFormPaymentPaymentMethod] = useState('');
    const [paymentFormDialogOpen, setPaymentFormDialogOpen] = useState(false);
    const [formPaymentLoading, setFormPaymentLoading] = useState(false);

    const [searchCustomerData, setSearchCustomerData] = useState([]);
    const [customerFormDialogOpen, setCustomerFormDialogOpen] = useState(false);
    const [formCustomerLoading, setFormCustomerLoading] = useState(false);
    const [formCustomerCustomerId, setFormCustomerCustomerId] = useState('');
    const [formCustomerCustomerName, setFormCustomerCustomerName] = useState('');
    const [isActiveSearchCustomer, setIsActiveSearchCustomer] = useState(true);

    const [productionNoteEdit, setProductionNoteEdit] = useState(false);

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        QuoteApi.findQuote({quoteId: id}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data);
                    setQuoteNumber(response.data.quoteNumber);
                    setQuoteStatus(response.data.status);
                    setQuoteStatusId(response.data.statusId);
                    setOrderJobs(response.data.orderJobs);
                    setDataCustomer(response.data.customer);
                    setDataTasks(response.data.task);

                    setFlagOrderChangeStatus(response.data.requireToPickupDelivery);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataPaymentTerm = () =>
    {
        setDataPaymentTerm(null); // set data to null

        // get data
        PaymentDefaultApi.findPaymentTerm().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataPaymentTerm(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataCourier = () =>
    {
        setDataCourier(null); // set data to null

        // get data
        CourierApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCourier(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataPaymentMethod = () =>
    {
        setDataPaymentMethod(null); // set data to null

        // get data
        PaymentDefaultApi.findPaymentMethod().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataPaymentMethod(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); loadDataPaymentTerm(); loadDataCourier(); loadDataPaymentMethod(); }, []);

    // handle Order Job Detail show hide
    const handleOrderJobDetail = (index) =>
    {
        let tmpData = orderJobs;
        for (let i = 0; i < tmpData.length; i++)
        {
            if(tmpData[i].trDetail !== undefined)
            {
                tmpData[i].trDetail = (index === i) ? !tmpData[i].trDetail : false;
            }
            else
                tmpData[i].trDetail = (index === i);
        }

        setOrderJobs([...tmpData, ...[]]);
    };

    // handle Special Order show hide
    const handleSpecialOrderShowHide = (index, indexSO) =>
    {
        let tmpData = orderJobs;

        for (let i = 0; i < tmpData[index].detail.specialOrders.length; i++)
        {
            if(tmpData[index].detail.specialOrders[i].secShow !== undefined)
            {
                tmpData[index].detail.specialOrders[i].secShow = (indexSO === i) ? !tmpData[index].detail.specialOrders[i].secShow : false;
            }
            else
            {
                tmpData[index].detail.specialOrders[i].secShow = (indexSO === i);
            }
        }

        setOrderJobs([...tmpData, ...[]]);
    };

    // show customer edit form
    const handleCustomerFormDialog = (type = '') =>
    {
        resetFormCustomer();

        setCustomerFormDialogOpen(!customerFormDialogOpen);

        if(type === 'edit')
        {
            formCustomerCustomerIdTemp = data.customer.id;
            formCustomerCustomerNameTemp = data.customer.name;
            setIsActiveSearchCustomer(false);
        }
    };

    const newCustomer = (id, name, dc) =>
    {
        formCustomerCustomerIdTemp = id;
        formCustomerCustomerNameTemp = name;

        setIsActiveSearchCustomer(false);
    };

    const searchCustomer = (searchWord) =>
    {
        formCustomerCustomerIdTemp = '';
        formCustomerCustomerNameTemp = '';
        setFormCustomerCustomerId('');
        setFormCustomerCustomerName('');
        setSearchCustomerData([]);

        if(searchWord.length >= 2)
        {
            CustomerApi.find({ searchTerm: searchWord }).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        const dataTemp = [];

                        for (let i = 0; i < response.data.list.length; i++)
                        {
                            dataTemp[i] = {'id': response.data.list[i].customerId, 'label': response.data.list[i].name}
                        }

                        setSearchCustomerData(dataTemp);
                    }
                }
            ).catch
            (
                function(error)
                {
                }
            );
        }
    };

    const searchCustomerSelect = (e, item) =>
    {
        formCustomerCustomerIdTemp = item.id;
        formCustomerCustomerNameTemp = item.id;
        setFormCustomerCustomerId(item.id);
        setFormCustomerCustomerName(item.label);
    };

    const saveCustomer = () =>
    {
        if (formCustomerCustomerIdTemp !== "") // check data is correct
        {
            setFormCustomerLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'quoteId': id,
                    'customerId': formCustomerCustomerIdTemp,
                };
            QuoteApi.saveCustomer(params).then
            (
                function (response)
                {
                    if (response.status === 200)
                    {
                        alertAction.current.showAlert("Customer Changed!", 'success'); // show success

                        handleCustomerFormDialog();
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormCustomerLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function (error)
                {
                    setFormCustomerLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetFormCustomer = () =>
    {
        setFormCustomerCustomerId('');
        setFormCustomerCustomerName('');

        formCustomerCustomerIdTemp = '';
        formCustomerCustomerNameTemp = '';
    };

    // show task edit form
    const handleTaskFormDialog = (item) =>
    {
        setDataTask(item);
        setTaskDialogOpen(true);
    };

    // show task edit form
    const handleTaskChange = (index, isChecked) =>
    {
        // send data
        const params =
            {
                'quoteId': id,
                'taskId': dataTasks[index].taskId,
                'value': isChecked ? 1 : 0,
            };
        TaskApi.complete(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Task Changed!", 'success'); // show success

                    let tmpData = dataTasks;
                    tmpData[index].isComplete = isChecked;
                    setDataTasks([...tmpData, ...[]]);

                    loadData();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // save Production Note
    const saveProductionNote = (item, index) =>
    {
        // send data
        const params =
            {
                'jobId': item.jobId,
                'note': productionNotes,
            };
        QuoteApi.saveProductionNote(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Production Note Saved!", 'success'); // show success
                    handleProductionNoteCancel();

                    let tmpOrderJobs = orderJobs;
                    tmpOrderJobs[index].detail.productionNotes = productionNotes;
                    setOrderJobs([...tmpOrderJobs, ...[]]);
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                alertAction.current.showAlert("There is a Problem 2"); // show error
            }
        );
    };

    const handleProductionNoteEdit = (item) =>
    {
        setProductionNotes(item.detail.productionNotes);
        setProductionNoteEdit(true);
    };

    const handleProductionNoteCancel = () =>
    {
        setProductionNoteEdit(false);
        setProductionNotes("");
    };

    // show Payment Term edit form
    const handlePaymentTermFormDialog = (e) =>
    {
        setFormPaymentTermPaymentTerm(data.payment.id);
        setFormPaymentTermEstimateSignOffRequired(data.payment.estimateSignOff);

        setPaymentTermFormDialogOpen(!paymentTermFormDialogOpen);
    };

    // save Payment Term
    const savePaymentTerm = () =>
    {
        if(formPaymentTermPaymentTerm !== "") // check data is correct
        {
            setFormPaymentTermLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'quoteId': id,
                    'paymentTerms': formPaymentTermPaymentTerm,
                    'signoffRequired': formPaymentTermEstimateSignOffRequired,
                };
            QuoteApi.savePayment(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Payment Term Saved!", 'success'); // show success

                        setPaymentTermFormDialogOpen(false);
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormPaymentTermLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormPaymentTermLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // show Quote edit form
    const handleQuoteFormDialog = () =>
    {
        setQuoteFormDialogOpen(true);
    };

    // show job form
    const handleJobFormDialog = () =>
    {
        setJobFormDialogOpen(true);
    };

    // cancel order
    const handleCancelOrder = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'quoteId': id,
            };
        QuoteApi.cancelQuote(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Order Canceled!", 'success'); // show success
                    loadData();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setDataLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // resume order
    const handleResumeOrder = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'quoteId': id,
            };
        QuoteApi.pauseQuote(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Order Resume!", 'success'); // show success
                    loadData();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setDataLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // pause order
    const handlePauseOrder = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'quoteId': id,
            };
        QuoteApi.pauseQuote(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Order Paused!", 'success'); // show success
                    loadData();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setDataLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // save Pickup Delivery
    const savePickupDelivery = () =>
    {
        setFormPickupDeliveryLoading(true); // hide btn & show loading

        // send data
        const params = {'quoteId': id, 'signatureName': formPickupDeliveryPerson, 'deliveryType': pickupDeliveryType === 'delivery' ? 1 : 2, 'pickupDate': General.parseDateSafe(formPickupDeliveryDate), 'courier': formPickupDeliveryCourier};
        OrderApi.pickupDelivery(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Pickup / Delivery Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormPickupDeliveryLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormPickupDeliveryLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const goToJob = (id) =>
    {
        navigate("/job/" + id, { replace: false });
    };

    // add Payment form
    const handlePaymentFormDialog = (e) =>
    {
        setFormPaymentAmount('');
        setFormPaymentPaymentMethod('');

        setPaymentFormDialogOpen(!paymentFormDialogOpen);
    };

    const savePayment = () =>
    {
        if(formPaymentAmount > 0 && formPaymentPaymentMethod > 0) // check data is correct
        {
            setFormPaymentLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'quoteId': id,
                    'type': 'payment',
                    'amountCollected': formPaymentAmount,
                    'paymentMethod': formPaymentPaymentMethod,
                };
            QuoteApi.savePayment(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Payment Saved!", 'success'); // show success

                        setPaymentFormDialogOpen(false);
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormPaymentLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormPaymentLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // download order
    const downloadOrder = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'quoteId': id,
            };
        OrderApi.download(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    let link = document.createElement("a");
                    link.setAttribute("href", response.data.link);
                    link.setAttribute("target", "_blank");
                    link.setAttribute("download", response.data.name);
                    document.body.appendChild(link);
                    link.click();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setDataLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // change status order
    const closeOrderChangeStatus = () =>
    {
        setFlagOrderChangeStatus(false);
    };

    const saveOrderChangeStatus = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'quoteId': id,
            };
        OrderApi.changeStatus(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Order status changed!", 'success'); // show success

                    loadData();
                }
                else
                {
                    alertAction.current.showAlert("There is a Problem"); // show error
                }

                setFlagOrderChangeStatus(false);
                setDataLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-order">
            <div className="title-bar-breadcrumbs-status">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle} #{quoteNumber}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        <Typography className="current-page" key="3" color="text.primary">Order #{quoteNumber}</Typography>,
                    </Breadcrumbs>
                </div>
                {
                    quoteStatus !== '' ?
                        <span className={"label-status " + General.initStatusClass(quoteStatus)}>{quoteStatus}</span>
                        :
                        null
                }
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <>
                                    {/* actions */}
                                    <div className="section-divider"><span className="section-title">Actions:</span></div>
                                    <div className="quote-section-action-box">
                                        <div className="">
                                            {
                                                quoteStatusId === 4 ?
                                                    <Button onClick={handleResumeOrder} className="btn-green btn-green-outline" fullWidth={false} variant="contained" startIcon={<PlayArrowOutlinedIcon />}>Resume Order</Button>
                                                    :
                                                    <Button onClick={handlePauseOrder} className="btn-yellow btn-yellow-outline" fullWidth={false} variant="contained" startIcon={<PauseCircleOutlineIcon />}>Pause Order</Button>
                                            }
                                            <Button onClick={handleCancelOrder} className="btn-red" fullWidth={false} variant="contained" startIcon={<DeleteOutlineOutlinedIcon />}>Cancel Order</Button>
                                        </div>

                                        <div className="">
                                            <Button onClick={downloadOrder} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<FileDownloadIcon />}>Download</Button>
                                        </div>
                                    </div>


                                    {/* pickup-delivery */}
                                    {
                                        quoteStatusId === 9 ?
                                            <div className={pickupDeliveryType === 'pickup' ? "quote-section-pickup-delivery" : "quote-section-pickup-delivery quote-section-pickup-delivery-2"}>
                                                <div className="quote-section-box form-box">
                                                    <div className="title">Delivery Details:</div>
                                                    <div className="des">Pick an option for fullfillment</div>

                                                    <Tabs value={pickupDeliveryType} onChange={(event, newValue) => { setPickupDeliveryType(newValue); }}>
                                                        <Tab className="tab" label="Delivery" value="delivery" />
                                                        <Tab className="tab" label="Pickup" value="pickup" />
                                                    </Tabs>

                                                    <Container className="quote-section-container">
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={12} sm={12} className={pickupDeliveryType === 'pickup' ? '' : 'display-none'}>
                                                                <InputLabel shrink className="form-label" htmlFor="form-pickup-delivery-person">Name of person</InputLabel>
                                                                <TextField className="form-input" id="form-pickup-delivery-person" placeholder="" value={formPickupDeliveryPerson} onChange={(e) => setFormPickupDeliveryPerson(e.target.value)} />
                                                            </Grid>

                                                            <Grid item xs={12} sm={6} className={pickupDeliveryType === 'pickup' ? 'display-none' : ''}>
                                                                <InputLabel shrink className="form-label" htmlFor="form-pickup-delivery-courier">Courier</InputLabel>
                                                                <Select className="form-select" id="form-pickup-delivery-courier" value={formPickupDeliveryCourier} onChange={(e) => setFormPickupDeliveryCourier(e.target.value)}>
                                                                    <MenuItem value="">&nbsp;</MenuItem>
                                                                    {
                                                                        dataCourier !== null ?
                                                                            dataCourier.map
                                                                            (
                                                                                (item) =>
                                                                                {
                                                                                    return (
                                                                                        <MenuItem value={item.courierId}>{item.vendorName}</MenuItem>
                                                                                    );
                                                                                }
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                </Select>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6} className={pickupDeliveryType === 'pickup' ? 'display-none' : ''}>
                                                                <InputLabel shrink className="form-label" htmlFor="form-pickup-delivery-date">Pick Up Date</InputLabel>
                                                                <div className="form-input">
                                                                    <DatePicker id="form-pickup-delivery-date" dateFormat="yyyy/MM/dd" selected={formPickupDeliveryDate !== '' ? new Date(formPickupDeliveryDate) : ''} value={formPickupDeliveryDate !== '' ? new Date(formPickupDeliveryDate) : ''} onChange={(e) => setFormPickupDeliveryDate(e)} />
                                                                </div>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {
                                                                    !formPickupDeliveryLoading ?
                                                                        <Button onClick={savePickupDelivery} className="dialog-btn-save">Save</Button>
                                                                        :
                                                                        <Loading />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }


                                    {/* task */}
                                    {
                                        dataTasks !== null ?
                                            <Container className="quote-section-container quote-section-task">
                                                <Grid container spacing={4}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <div className="task-title" onClick={() => setSectionTaskPreFlag(!sectionTaskPreFlag)}>Pre tasks{sectionTaskPreFlag ? <ArrowDropUpOutlinedIcon className="icon" /> : <ArrowDropDownOutlinedIcon className="icon" />}</div>
                                                        <table className={sectionTaskPreFlag ? "table-data" : "table-data display-none"}>
                                                            <thead>
                                                            <tr>
                                                                <th className="w-40"></th>
                                                                <th>Task</th>
                                                                <th>Due Date</th>
                                                                <th>Assigned to</th>
                                                                {(quoteStatusId === 6 || quoteStatusId === 9 || quoteStatusId === 10) ? null : <th className="w-40"></th>}
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                            {
                                                                dataTasks.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            item.type === 0 ?
                                                                                <tr>
                                                                                    <td><FormControlLabel control={<Checkbox disabled={(quoteStatusId === 6 || quoteStatusId === 9 || quoteStatusId === 10)} checked={item.isComplete} onChange={(e) => handleTaskChange(index, e.target.checked)} />} className="form-control-label-check-box form-order-stock-call-customer-check-box" /></td>
                                                                                    <td>{item.taskName}</td>
                                                                                    <td>{item.dueDate}</td>
                                                                                    <td>{item.assigned}</td>
                                                                                    {(quoteStatusId === 6 || quoteStatusId === 9 || quoteStatusId === 10) ? null : <td><IconButton className="icon-edit" onClick={() => handleTaskFormDialog(item)}><BorderColorIcon /></IconButton></td>}
                                                                                </tr>
                                                                                :
                                                                                null
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </Grid>

                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <div className="task-title task-post-title" onClick={() => setSectionTaskPostFlag(!sectionTaskPostFlag)}>Post tasks{sectionTaskPostFlag ? <ArrowDropUpOutlinedIcon className="icon" /> : <ArrowDropDownOutlinedIcon className="icon" />}</div>
                                                        <table className={sectionTaskPostFlag ? "table-data" : "table-data display-none"}>
                                                            <thead>
                                                            <tr>
                                                                <th className="w-40"></th>
                                                                <th>Task</th>
                                                                <th>Due Date</th>
                                                                <th>Assigned to</th>
                                                                {(quoteStatusId === 6 || quoteStatusId === 9 || quoteStatusId === 10) ? null : <th className="w-40"></th>}
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                            {
                                                                dataTasks.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            item.type === 1 ?
                                                                                <tr>
                                                                                    <td><FormControlLabel control={<Checkbox disabled={(quoteStatusId === 6 || quoteStatusId === 9 || quoteStatusId === 10)} checked={item.isComplete} onChange={(e) => handleTaskChange(index, e.target.checked)} />} className="form-control-label-check-box form-order-stock-call-customer-check-box" /></td>
                                                                                    <td>{item.taskName}</td>
                                                                                    <td>{item.dueDate}</td>
                                                                                    <td>{item.assigned}</td>
                                                                                    {(quoteStatusId === 6 || quoteStatusId === 9 || quoteStatusId === 10) ? null : <td><IconButton className="icon-edit" onClick={() => handleTaskFormDialog(item)}><BorderColorIcon /></IconButton></td>}
                                                                                </tr>
                                                                                :
                                                                                null
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Button onClick={() => handleTaskFormDialog(null)} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Tasks</Button>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                            :
                                            null
                                    }


                                    {/* order details */}
                                    <div className="section-divider"><span className="section-title">Order Details:</span></div>
                                    <Container className="quote-section-container quote-section-order-detail">
                                        <Grid container spacing={4}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="quote-section-box">
                                                    <div>
                                                        <div className="data-box">
                                                            <div className="title">Name:</div>
                                                            <div className="des">{data.order.name}</div>
                                                        </div>

                                                        <Grid container spacing={4}>
                                                            <Grid item sm={6} xs={12}>
                                                                <div className="data-box">
                                                                    <div className="title title2">Date Ordered:</div>
                                                                    <div className="des"><span className="date-blue">{data.order.createdDate}</span></div>
                                                                </div>
                                                            </Grid>

                                                            <Grid item sm={6} xs={12}>
                                                                <div className="data-box">
                                                                    <div className="title title2">Date Due:</div>
                                                                    <div className="des"><span className={data.order.isDueDateRequired ? "date-orange" : "date-green"}>{data.order.dueDate}</span></div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                        <div className="data-box">
                                                            <div className="title title-by-icon">{data.order.isDueDateRequired ? <CheckBoxIcon className="icon" /> : <CheckBoxOutlineBlankIcon className="icon" />} Required Due Date</div>
                                                        </div>

                                                        <div className="data-box">
                                                            <div className="title">Salesperson:</div>
                                                            <div className="des">{data.order.salesPerson}</div>
                                                        </div>
                                                    </div>

                                                    <div className="btn-link-bar">
                                                        <Button onClick={handleQuoteFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="quote-section-box">
                                                    <div>
                                                        <div className="data-box">
                                                            <div className="title">Customer Details:</div>
                                                            <div className="des">{data.customer.name}</div>
                                                            <div className="des">{data.customer.phoneByFormat}</div>
                                                        </div>

                                                        <Grid container spacing={4}>
                                                            <Grid item sm={6} xs={12}>
                                                                <div className="data-box">
                                                                    <div className="title title2">Billing Address:</div>
                                                                    <div className="des des2">{data.customer.billingAddressFull}</div>
                                                                </div>
                                                            </Grid>

                                                            <Grid item sm={6} xs={12}>
                                                                <div className="data-box">
                                                                    <div className="title title2">Shipping Address:</div>
                                                                    <div className="des des2">{data.customer.billingSameAsShipping == 1 ? 'Same as billing' : data.customer.shippingAddressFull}</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>

                                                    <div className="btn-link-bar">
                                                        <Button onClick={() => handleCustomerFormDialog('edit')} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="quote-section-box">
                                                    <div className="data-box data-box2">
                                                        <div className="title">Payment Details:</div>
                                                        <div className="des">{data.payment.name}</div>
                                                    </div>

                                                    <div className="data-box data-box2">
                                                        <FormControlLabel control={<Checkbox checked={data.payment.estimateSignOff} onChange={(e) => {}} />} className="form-control-label-check-box form-order-stock-call-customer-check-box" label="Estimate Signoff Required" />
                                                    </div>

                                                    <div className="data-box data-box2">
                                                        <div className="title title2">Past payments:</div>
                                                        {
                                                            data.payment.pastPayments !== null ?
                                                                <table className="table-data">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Amount</th>
                                                                        <th>Type</th>
                                                                        <th>Date</th>
                                                                    </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                    {
                                                                        data.payment.pastPayments.map
                                                                        (
                                                                            (item, index) =>
                                                                            {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item.amount}</td>
                                                                                        <td>{item.type}</td>
                                                                                        <td>{item.date}</td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <AlertDataEmpty />
                                                        }
                                                    </div>

                                                    <Grid container spacing={4}>
                                                        <Grid item sm={6} xs={12}>
                                                            <div className="data-box data-box2">
                                                                <div className="title title2">Balance Due:</div>
                                                                <div className="des des3">{data.payment.balanceDue}</div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item sm={6} xs={12}>
                                                            <div className="data-box data-box2">
                                                                <div className="title title2">Total:</div>
                                                                <div className="des des3">{data.payment.paymentTotal}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <div className="btn-link-bar btn-link-bar2">
                                                        <Button onClick={handlePaymentFormDialog} className="btn-green" fullWidth={false} variant="contained" startIcon={<CreditCardIcon />}>Add payment</Button>
                                                        <Button onClick={handlePaymentTermFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Container>


                                    {/* order jobs */}
                                    <div className="section-divider">
                                        <span className="section-title">Jobs in order:</span>
                                        <Button onClick={handleJobFormDialog} className="btn-blue" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Job</Button>
                                    </div>

                                    <div className="quote-section-box quote-section-order-jobs-box">
                                        {
                                            orderJobs !== null ?
                                                <table className="table-data">
                                                    <thead>
                                                    <tr>
                                                        <th>Job Number</th>
                                                        <th>Product Name</th>
                                                        <th>Status</th>
                                                        <th>Station</th>
                                                        <th>Due Date</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        orderJobs.map
                                                        (
                                                            (item, index) =>
                                                            {
                                                                return (
                                                                    <>
                                                                        <tr onClick={() => goToJob(item.jobId)}>
                                                                            <td>{item.jobNumber}</td>
                                                                            <td>{item.productName}</td>
                                                                            <td><label className={'label-status ' + General.initStatusClass(item.status)}>{item.status}</label></td>
                                                                            <td>{item.station}</td>
                                                                            <td className={item.isDueDateRequired ? 'td-highlight' : ''}>{item.dueDate}</td>
                                                                            <td>{item.quantity}</td>
                                                                            <td>${item.price}</td>
                                                                            <td>${item.total}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={10} className="td-expand">
                                                                                {
                                                                                    item.trDetail ?
                                                                                        <Button onClick={(e) => handleOrderJobDetail(index)} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<ArrowDropUpOutlinedIcon />}>Close</Button>
                                                                                        :
                                                                                        <Button onClick={(e) => handleOrderJobDetail(index)} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<ArrowDropDownOutlinedIcon />}>Expand</Button>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            item.trDetail ?
                                                                                <tr>
                                                                                    <td colSpan={10} className="td-order-job">
                                                                                        <div className="order-job-box">
                                                                                            <div className="data-box">
                                                                                                <div className="title">Current Flow:</div>

                                                                                                <div className="content workflow-content">
                                                                                                    {
                                                                                                        item.detail.workFlows != null ?
                                                                                                            item.detail.workFlows.map
                                                                                                            (
                                                                                                                (item2, index) =>
                                                                                                                {
                                                                                                                    return (
                                                                                                                        <div className={item.stationId === item2.stationId ? "workflow-station-box active" : "workflow-station-box"}>
                                                                                                                            <div className="station-title-number-box">
                                                                                                                                <div className="title-station-number">
                                                                                                                                    <div className="title">{item2.name}</div>
                                                                                                                                    <div className="station-number">{item2.abbreviation}</div>
                                                                                                                                </div>
                                                                                                                                {item.stationId === item2.stationId ? <div className="job-current">Current Job</div> : null}
                                                                                                                            </div>
                                                                                                                            <img src={imageStationArrowIcon} className="icon" />
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                }
                                                                                                            )
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="data-box">
                                                                                                <div className="title">Name:</div>
                                                                                                <div className="des">{item.detail.name}</div>
                                                                                            </div>

                                                                                            <Grid container spacing={4} className="container-date">
                                                                                                <Grid item sm={6} xs={12}>
                                                                                                    <div className="data-box">
                                                                                                        <div className="title">Date Ordered:</div>
                                                                                                        <div className="des"><span className="date-blue">{item.detail.orderedDate}</span></div>
                                                                                                    </div>
                                                                                                </Grid>

                                                                                                <Grid item sm={6} xs={12}>
                                                                                                    <div className="data-box">
                                                                                                        <div className="title">Date Due:</div>
                                                                                                        <div className="des"><span className={item.detail.isDueDateRequired ? "date-orange" : "date-green"}>{item.detail.dueDate}</span></div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid container spacing={2}>
                                                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                                    <div className="data-box data-box-border">
                                                                                                        <div className="title">Product / Model</div>
                                                                                                        <div className="des">{item.detail.productModel}</div>

                                                                                                        <div className="title">Dollars</div>
                                                                                                        <div className="des">{item.detail.Dollars}</div>

                                                                                                        <div className="title">Quantity</div>
                                                                                                        <div className="des">{item.detail.quantity}</div>

                                                                                                        {/*<div className="title">Mattress Size</div>*/}
                                                                                                        {/*<div className="des">{item.detail.dueDate}</div>*/}

                                                                                                        {
                                                                                                            item.detail.customs != null ?
                                                                                                                item.detail.customs.map
                                                                                                                (
                                                                                                                    (item2) =>
                                                                                                                    {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className="title">{item2.title}</div>
                                                                                                                                <div className="des">{item2.value}</div>
                                                                                                                            </>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                        {
                                                                                                            item.detail.specialOrders != null ?
                                                                                                                item.detail.specialOrders.map
                                                                                                                (
                                                                                                                    (itemSO, indexSO) =>
                                                                                                                    {
                                                                                                                        return (
                                                                                                                            <div className="special-order-box">
                                                                                                                                <div className="bar-icon-show-hide">
                                                                                                                                    <div className="title">Vendors</div>
                                                                                                                                    <div className="des">{itemSO.vendorName}</div>

                                                                                                                                    <IconButton className="btn-show-hide" onClick={() => handleSpecialOrderShowHide(index, indexSO)}>{itemSO.secShow ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}</IconButton>
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    itemSO.secShow ?
                                                                                                                                        <>
                                                                                                                                            {/*<div className="title">Vendors</div>*/}
                                                                                                                                            {/*<div className="des">{itemSO.vendorName}</div>*/}

                                                                                                                                            <div className="title">Station Needed</div>
                                                                                                                                            <div className="des">{itemSO.stationName}</div>

                                                                                                                                            <div className="title">Quantity</div>
                                                                                                                                            <div className="des">{itemSO.quantity}</div>

                                                                                                                                            <div className="title">Size</div>
                                                                                                                                            <div className="des">{itemSO.size}</div>

                                                                                                                                            <div className="title">Product Details</div>
                                                                                                                                            <div className="des">{itemSO.productDetail}</div>
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        null
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                        {
                                                                                                            item.detail.files != null ?
                                                                                                                <>
                                                                                                                    <div className="title">files</div>
                                                                                                                    {
                                                                                                                        item.detail.files.map
                                                                                                                        (
                                                                                                                            (itemFile) =>
                                                                                                                            {
                                                                                                                                return (
                                                                                                                                    <div className="des-link">
                                                                                                                                        <a href={itemFile.link} target="_blank">{itemFile.name} <span className="file-size">({itemFile.size})</span></a>
                                                                                                                                    </div>
                                                                                                                                );
                                                                                                                            }
                                                                                                                        )
                                                                                                                    }
                                                                                                                </>
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                    </div>
                                                                                                </Grid>

                                                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                                    <div className="data-box data-box-border note-data-box">
                                                                                                        <div className="title">Notes:</div>
                                                                                                        <div className="note-box">{item.detail.notes}</div>
                                                                                                    </div>
                                                                                                </Grid>

                                                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                                                    <div className="data-box data-box-border production-note-data-box">
                                                                                                        <div className="title">Production Notes:</div>
                                                                                                        {
                                                                                                            productionNoteEdit ?
                                                                                                                <TextField className="form-input" multiline={true} placeholder="" value={productionNotes} onChange={(e) => setProductionNotes(e.target.value)} />
                                                                                                                :
                                                                                                                <TextField className="form-input" multiline={true} placeholder="" value={item.detail.productionNotes} disabled="disabled" />
                                                                                                        }
                                                                                                        <div>
                                                                                                            {
                                                                                                                productionNoteEdit ?
                                                                                                                    <>
                                                                                                                        <Button onClick={() => saveProductionNote(item, index)} className="btn-green-2" fullWidth={false} variant="contained">Save Changes</Button>
                                                                                                                        <Button onClick={() => handleProductionNoteCancel()} className="btn-blue btn-blue-outline m-l-16" fullWidth={false} variant="contained">Cancel</Button>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <Button onClick={() => handleProductionNoteEdit(item) } className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                );
                                                            }
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                                :
                                                <AlertDataEmpty />
                                        }
                                    </div>


                                    {/* job times */}
                                    <Container className="quote-section-container">
                                        <Grid container spacing={4}>
                                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                                <div className="quote-section-box quote-section-job-times-box">
                                                    {
                                                        data.jobTimes !== null ?
                                                            <table className="table-data">
                                                                <thead>
                                                                <tr>
                                                                    <th>Job Number</th>
                                                                    <th>Active Time</th>
                                                                    <th>Idle Time</th>
                                                                </tr>
                                                                </thead>

                                                                <tbody>
                                                                {
                                                                    data.jobTimes.map
                                                                    (
                                                                        (item, index) =>
                                                                        {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item.getJobNumber}</td>
                                                                                    <td>{item.activeTime}</td>
                                                                                    <td>{item.idleTime}</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <AlertDataEmpty />
                                                    }
                                                </div>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="quote-section-box quote-section-price-box">
                                                    <div className="data-box">
                                                        <span className="title">Subtotal:</span>
                                                        <span className="des">${data.price.subTotal}</span>
                                                    </div>

                                                    <div className="data-box">
                                                        <span className="title">GST:</span>
                                                        <span className="des">${data.price.gst}</span>
                                                    </div>

                                                    <div className="line-bar"></div>

                                                    <div className="data-box">
                                                        <span className="title">Total:</span>
                                                        <span className="des">${data.price.total}</span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* edit payment term form */}
            <Dialog open={paymentTermFormDialogOpen} onClose={handlePaymentTermFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Edit Payment Terms</span>
                        <IconButton className="dialog-close" onClick={handlePaymentTermFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box form-quote-box form-box-t2">
                    <Container className="form-container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <InputLabel shrink className="form-label" htmlFor="form-quote-payment-term">Payment Terms</InputLabel>
                                <Select className="form-select" id="form-quote-payment-term" value={formPaymentTermPaymentTerm} onChange={(e) => setFormPaymentTermPaymentTerm(e.target.value)}>
                                    <MenuItem value="">&nbsp;</MenuItem>
                                    {
                                        dataPaymentTerm !== null ?
                                            dataPaymentTerm.map
                                            (
                                                (item) =>
                                                {
                                                    return (
                                                        <MenuItem value={item.termId}>{item.termName}</MenuItem>
                                                    );
                                                }
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormControlLabel control={<Checkbox checked={formPaymentTermEstimateSignOffRequired} onChange={(e) => setFormPaymentTermEstimateSignOffRequired(e.target.checked)} />} className="form-control-label-check-box" label="Estimate SignOff Requireds" />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formPaymentTermLoading ?
                            <>
                                <Button onClick={savePaymentTerm} className="dialog-btn-save">Save Changes</Button>
                                <Button onClick={handlePaymentTermFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* add payment form */}
            <Dialog open={paymentFormDialogOpen} onClose={handlePaymentFormDialog} fullWidth={true} maxWidth='md'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Add Payment</span>
                        <IconButton className="dialog-close" onClick={handlePaymentFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box form-payment-box form-box-t2">
                    <Container className="form-container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-payment-amount">Amount</InputLabel>
                                <TextField className="form-input" id="form-payment-amount" placeholder="$" value={formPaymentAmount} onChange={(e) => setFormPaymentAmount(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-payment-payment-method">Payment Method</InputLabel>
                                <Select className="form-select" id="form-payment-payment-method" value={formPaymentPaymentMethod} onChange={(e) => setFormPaymentPaymentMethod(e.target.value)}>
                                    <MenuItem value="">&nbsp;</MenuItem>
                                    {
                                        dataPaymentMethod !== null ?
                                            dataPaymentMethod.map
                                            (
                                                (item) =>
                                                {
                                                    return (
                                                        <MenuItem value={item.methodId}>{item.methodName}</MenuItem>
                                                    );
                                                }
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formPaymentLoading ?
                            <>
                                <Button onClick={savePayment} className="dialog-btn-save">Save Payment</Button>
                                <Button onClick={handlePaymentFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* customer form */}
            <Dialog open={customerFormDialogOpen} onClose={handleCustomerFormDialog} fullWidth={true} maxWidth='md'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Edit Customer Details</span>
                        <IconButton className="dialog-close" onClick={handleCustomerFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box form-customer-box form-box-t2">
                    <Container className="form-container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-customer-customer">Find Customer</InputLabel>
                                {
                                    isActiveSearchCustomer ?
                                        <Autocomplete
                                            className="form-input form-input-search"
                                            value={formCustomerCustomerName}
                                            freeSolo
                                            disableClearable
                                            disablePortal
                                            options={searchCustomerData}
                                            onInputChange={(event, newValue) => searchCustomer(newValue)}
                                            onChange={searchCustomerSelect}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} placeholder="Search" />}
                                        />
                                        :
                                        <TextField className="form-input" id="form-customer-customer" placeholder="" value={formCustomerCustomerNameTemp} onFocus={() => setIsActiveSearchCustomer(true)} />
                                }
                                <Button onClick={() => setCustomerDialogOpen(true)} className="btn-blue btn-blue-link m-b-16 m-t--16" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit Selected</Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel shrink className="form-label" htmlFor="form-customer-customer-new">&nbsp;</InputLabel>
                                <div className="form-customer-customer-new">
                                    <span>-or-</span>
                                    <Button onClick={() => setCustomerNewDialogOpen(true)} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Customer</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formCustomerLoading ?
                            <>
                                <Button onClick={saveCustomer} className="dialog-btn-save">Save Changes</Button>
                                <Button onClick={handleCustomerFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* add job form */}
            {jobFormDialogOpen ? <Job handleDialog={() => setJobFormDialogOpen(false)} closeAfterSave={false} quoteId={id} jobData={null} loadData={() => { setTimeout(loadData, 100); }} /> : null}


            {/* edit quote form */}
            {quoteFormDialogOpen ? <QuoteEdit handleDialog={() => setQuoteFormDialogOpen(false)} closeAfterSave={true} quoteData={data} loadData={() => { setTimeout(loadData, 100); }} /> : null}
            {customerNewDialogOpen ? <Customer handleDialog={() => setCustomerNewDialogOpen(false)} closeAfterSave={true} newCustomer={newCustomer} customerData={null} loadData={() => {}} /> : null}


            {/* customer */}
            {customerDialogOpen ? <Customer handleDialog={() => setCustomerDialogOpen(false)} closeAfterSave={true} customerData={dataCustomer} loadData={() => { setTimeout(loadData, 100); }} /> : null}


            {/* task */}
            {taskDialogOpen ? <Task handleDialog={() => setTaskDialogOpen(false)} closeAfterSave={true} taskData={dataTask} orderId={id} orderNumber={quoteNumber} loadData={() => { setTimeout(loadData, 100); }} /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />


            {/* change status */}
            <Dialog open={flagOrderChangeStatus} onClose={closeOrderChangeStatus} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Change Status</span>
                        <IconButton className="dialog-close" onClick={closeOrderChangeStatus}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content">
                    <p className="p-b-20">Would you like to change this Order from Completed to Delivery/ Pickup Status?</p>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button onClick={saveOrderChangeStatus} className="dialog-btn-save">Yes</Button>
                    <Button onClick={closeOrderChangeStatus} className="dialog-btn-cancel">No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Order;
