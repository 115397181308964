import imageDashboard from '../images/menu/dashboard.png';
import imageQuote from '../images/menu/quotes.png';
import imageOrder from '../images/menu/orders.png';
import imageJob from '../images/menu/jobs.png';
import imageReport from '../images/menu/reports.png';
import imageTask from '../images/menu/tasks.png';
import imageCustomer from '../images/menu/customers.png';
import imageSetting from '../images/menu/settings.png';
import imageCustomize from '../images/menu/customize.png';

export default
{
    dashboardSection: {title: "DASHBOARD", isMenu: false},
    dashboard: {className: "dashboard", link: "/dashboard", title: "Dashboard", image: imageDashboard, isMenu: true},
    quotes: {className: "quotes", link: "/quotes", title: "Quotes", image: imageQuote, isMenu: true},
    orders: {className: "orders", link: "/orders", title: "Orders", image: imageOrder, isMenu: true},
    jobs: {className: "jobs", link: "/jobs", title: "Jobs", image: imageJob, isMenu: true},

    pagesSection: {title: "PAGES", isMenu: false},
    reports: {className: "reports", link: "/reports", title: "Stock Orders", image: imageReport, isMenu: true},
    tasks: {className: "tasks", link: "/tasks", title: "Tasks", image: imageTask, isMenu: true},
    customers: {className: "customers", link: "/customers", title: "Customers", image: imageCustomer, isMenu: true},

    toolsSection: {title: "TOOLS", isMenu: false},
    settings: {className: "settings", link: "/settings", title: "Settings", image: imageSetting, isMenu: true},
    // customize: {className: "customize", link: "/customize", title: "Customize", image: imageCustomize, isMenu: true},
};
