import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
// routers
import RouterPublic from "./RouterPublic";
import RouterPrivate from './RouterPrivate';
// pages
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import PageNotFound from '../pages/PageNotFound';
import Dashboard from "../pages/Dashboard/Dashboard";
import Quotes from "../pages/Quotes/Quotes";
import Quote from "../pages/Quotes/Quote";
import Orders from "../pages/Orders/Orders";
import Order from "../pages/Orders/Order";
import Tasks from "../pages/Tasks/Tasks";
import Customers from "../pages/Customers/Customers";
import Settings from "../pages/Settings/Settings";
import Jobs from "../pages/Jobs/Jobs";
import Job from "../pages/Jobs/Job";
import Search from "../pages/Search/Search";
import Couriers from "../pages/Couriers/Couriers";
import OrderVendors from "../pages/OrderVendors/OrderVendors";
import Profile from "../pages/Profile/Profile";
import InvoiceTemplate from "../pages/InvoiceTemplate/InvoiceTemplate";
import Reports from "../pages/Reports/Reports";
import ProductCatalog from "../pages/ProductCatalog/ProductCatalog";

//--------------------------------------------------

function Router()
{
    return (
        <Routes>
            <Route element={<RouterPublic />}>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
            </Route>

            <Route exact path='/' element={<Navigate to="/dashboard" />} />

            <Route element={<RouterPrivate />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/quote/:id" element={<Quote />} />
                <Route path="/quotes/:openNew" element={<Quotes />} />
                <Route path="/quotes/status-:statusId" element={<Quotes />} />
                <Route path="/quotes" element={<Quotes />} />
                <Route path="/order/:id" element={<Order />} />
                <Route path="/orders/status-:statusId" element={<Orders />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/job/:id" element={<Job />} />
                <Route path="/jobs/status-:statusId" element={<Jobs />} />
                <Route path="/jobs/station-:stationId" element={<Jobs />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/search/:sw" element={<Search />} />
                <Route path="/couriers" element={<Couriers />} />
                <Route path="/order-vendors" element={<OrderVendors />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/invoice-template" element={<InvoiceTemplate />} />
                <Route path="/product-catalog" element={<ProductCatalog />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default Router;
