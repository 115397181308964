import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Container, Grid} from "@mui/material";
import Category from "../../components/Category/Category";
import Station from "../../components/Station/Station";
import WorkflowPreference from "../../components/WorkflowPreference/WorkflowPreference";
import RequiredField from "../../components/RequiredField/RequiredField";
import PaymentDefault from "../../components/PaymentDefault/PaymentDefault";
import PresetTask from "../../components/PresetTask/PresetTask";
import CustomCharacteristic from "../../components/CustomCharacteristic/CustomCharacteristic";

import "./Settings.css";

//--------------------------------------------------

function Settings(props)
{
    // set page title & page path
    const pageTitle = 'Settings';
    usePage().pageData({title: Menus.settings.title, activeMenu: 'settings'});

    //
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [stationDialogOpen, setStationDialogOpen] = useState(false);
    const [workflowPreferenceDialogOpen, setWorkflowPreferenceDialogOpen] = useState(false);
    const [requiredFieldDialogOpen, setRequiredFieldDialogOpen] = useState(false);
    const [paymentDefaultDialogOpen, setPaymentDefaultDialogOpen] = useState(false);
    const [presetTaskDialogOpen, setPresetTaskDialogOpen] = useState(false);
    const [customCharacteristicDialogOpen, setCustomCharacteristicDialogOpen] = useState(false);

    //
    return (
        <div className="page-content page-settings">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Container className="setting-section-container">
                    <Grid container spacing={4}>
                        <Grid item lg={4} md={5} sm={6} xs={12}>
                            <div className="setting-section-box">
                                <div className="title">Products</div>

                                <Link to="" className="link" onClick={() => setCategoryDialogOpen(true)}>Manage Category Names</Link>
                                <Link to="" className="link" onClick={() => setCustomCharacteristicDialogOpen(true)}>Manage Custom Characteristics</Link>
                                <Link to="/product-catalog" className="link">Product Catalog</Link>
                            </div>

                            <div className="setting-section-box">
                                <div className="title">Customer Setting</div>

                                <Link to="" className="link" onClick={() => setRequiredFieldDialogOpen(true)}>Manage Required Fields</Link>
                                <Link to="" className="link" onClick={() => setPaymentDefaultDialogOpen(true)}>Manage Payment Defaults</Link>
                            </div>

                            <div className="setting-section-box">
                                <div className="title">Workflow</div>

                                <Link to="" className="link" onClick={() => setWorkflowPreferenceDialogOpen(true)}>Manage Workflow Preferences</Link>
                                <Link to="" className="link" onClick={() => setStationDialogOpen(true)}>Manage Station Names</Link>
                                <Link to="" className="link" onClick={() => setPresetTaskDialogOpen(true)}>Manage Preset Task Lists</Link>
                            </div>

                            <div className="setting-section-box">
                                <div className="title">Integrations - Coming Soon</div>

                                <Link to="" className="link disable" aria-disabled>Link Quickbooks</Link>
                                <Link to="" className="link disable">Link Quickbooks Online</Link>
                                <Link to="" className="link disable">Link Zapier</Link>
                            </div>
                        </Grid>

                        <Grid item lg={4} md={5} sm={6} xs={12}>
                            <div className="setting-section-box">
                                <div className="title">Billing</div>

                                <Link to="" className="link">Payments</Link>
                            </div>

                            <div className="setting-section-box">
                                <div className="title">Templates</div>

                                <Link to="/invoice-template" className="link">Invoice Template</Link>
                            </div>

                            <div className="setting-section-box">
                                <div className="title">Vendors</div>

                                <Link to="/order-vendors" className="link">Manage Special Order Vendors</Link>
                                <Link to="/couriers" className="link">Manage Couriers (Deliveries)</Link>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Scrollbars>

            {categoryDialogOpen ? <Category handleDialog={() => setCategoryDialogOpen(false)} /> : null}
            {stationDialogOpen ? <Station handleDialog={() => setStationDialogOpen(false)} /> : null}
            {workflowPreferenceDialogOpen ? <WorkflowPreference handleDialog={() => setWorkflowPreferenceDialogOpen(false)} /> : null}
            {requiredFieldDialogOpen ? <RequiredField handleDialog={() => setRequiredFieldDialogOpen(false)} /> : null}
            {paymentDefaultDialogOpen ? <PaymentDefault handleDialog={() => setPaymentDefaultDialogOpen(false)} /> : null}
            {presetTaskDialogOpen ? <PresetTask handleDialog={() => setPresetTaskDialogOpen(false)} /> : null}
            {customCharacteristicDialogOpen ? <CustomCharacteristic handleDialog={() => setCustomCharacteristicDialogOpen(false)} /> : null}
        </div>
    );
}

export default Settings;
