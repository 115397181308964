import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination.js";
import {Button, Container, Grid} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import CustomersApi from "../../services/CustomerApi";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import Search from '@mui/icons-material/Search';
import CustomerApi from "../../services/CustomerApi";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Switch from "@mui/material/Switch/Switch";
import Stack from "@mui/material/Stack";
import FormControlLabel from '@mui/material/FormControlLabel';
import ProvinceApi from "../../services/ProvinceApi";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import "./Customer.css";
import RequiredFieldApi from "../../services/RequiredFieldApi";

//--------------------------------------------------

function Customer(props)
{
    //
    const [data, setData] = useState([]);
    const [dataRequiredField, setDataRequiredField] = useState([]);
    const [dataProvince, setDataProvince] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [searchWord, setSearchWord] = useState("");

    const [formLoading, setFormLoading] = useState(false);
    const [formCustomerId, setFormCustomerId] = useState('');
    const [formCustomerType, setFormCustomerType] = useState(1);
    const [formCustomerCompanyName, setFormCustomerCompanyName] = useState('');
    const [formCustomerCustomerPhoneNumber, setFormCustomerCustomerPhoneNumber] = useState('');
    const [formCustomerCustomerPhoneExt, setFormCustomerCustomerPhoneExt] = useState('');
    const [formCustomerCustomerFax, setFormCustomerCustomerFax] = useState('');
    const [formCustomerFirstName, setFormCustomerFirstName] = useState('');
    const [formCustomerLastName, setFormCustomerLastName] = useState('');
    const [formCustomerEmail, setFormCustomerEmail] = useState('');
    const [formCustomerPhoneNumber, setFormCustomerPhoneNumber] = useState('');
    const [formCustomerPhoneExt, setFormCustomerPhoneExt] = useState('');
    const [formCustomerBillingAddress, setFormCustomerBillingAddress] = useState('');
    const [formCustomerCity, setFormCustomerCity] = useState('');
    const [formCustomerProvince, setFormCustomerProvince] = useState('');
    const [formCustomerPostalCode, setFormCustomerPostalCode] = useState('');
    const [formCustomerShippingAddress, setFormCustomerShippingAddress] = useState('');
    const [formCustomerShippingCity, setFormCustomerShippingCity] = useState('');
    const [formCustomerShippingProvince, setFormCustomerShippingProvince] = useState('');
    const [formCustomerShippingPostalCode, setFormCustomerShippingPostalCode] = useState('');
    const [formCustomerNote, setFormCustomerNote] = useState('');
    const [formCustomerBillingSameShipping, setFormCustomerBillingSameShipping] = useState(false);

    const alertAction = useRef(); // init alert

    // load data
    const loadDataProvince = () =>
    {
        setDataProvince(null); // set data to null

        // get data
        ProvinceApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataProvince(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataRequiredField = () =>
    {
        setDataRequiredField(null); // set data to null
        setDataLoading(true);

        // get data
        RequiredFieldApi.find({haveKey: true}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataRequiredField(response.data.list);
                }

                setDataLoading(false);
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false);
            }
        );
    };

    useEffect(() => { loadDataProvince(); loadDataRequiredField(); if(props.customerData != null) handleCustomerEdit(props.customerData); }, []);

    // customer save
    const saveCustomer = () =>
    {
        if(
            (
                formCustomerType === 1 &&
                ((dataRequiredField.RetailBillingAddress && formCustomerBillingAddress !== "") || !dataRequiredField.RetailBillingAddress) &&
                ((dataRequiredField.RetailBillingCity && formCustomerCity !== "") || !dataRequiredField.RetailBillingCity) &&
                ((dataRequiredField.RetailEmail && formCustomerEmail !== "") || !dataRequiredField.RetailEmail) &&
                ((dataRequiredField.RetailFirstName && formCustomerFirstName !== "") || !dataRequiredField.RetailFirstName) &&
                ((dataRequiredField.RetailLastName && formCustomerLastName !== "") || !dataRequiredField.RetailLastName) &&
                ((dataRequiredField.RetailNotes && formCustomerNote !== "") || !dataRequiredField.RetailNotes) &&
                ((dataRequiredField.RetailPhone && formCustomerPhoneNumber !== "") || !dataRequiredField.RetailPhone) &&
                ((dataRequiredField.RetailPhoneExtension && formCustomerPhoneExt !== "") || !dataRequiredField.RetailPhoneExtension) &&
                ((dataRequiredField.RetailPostalCode && formCustomerPostalCode !== "") || !dataRequiredField.RetailPostalCode) &&
                ((dataRequiredField.RetailProvince && formCustomerProvince !== "") || !dataRequiredField.RetailProvince)
            )
            ||
            (
                formCustomerType === 0 &&
                ((dataRequiredField.CorporateBillingAddress && formCustomerBillingAddress !== "") || !dataRequiredField.CorporateBillingAddress) &&
                ((dataRequiredField.CorporateBillingCity && formCustomerCity !== "") || !dataRequiredField.CorporateBillingCity) &&
                ((dataRequiredField.CorporateBillingPostalCode && formCustomerPostalCode !== "") || !dataRequiredField.CorporateBillingPostalCode) &&
                ((dataRequiredField.CorporateBillingProvince && formCustomerProvince !== "") || !dataRequiredField.CorporateBillingProvince) &&
                ((dataRequiredField.CorporateCompanyName && formCustomerCompanyName !== "") || !dataRequiredField.CorporateCompanyName) &&
                ((dataRequiredField.CorporateCompanyPhone && formCustomerCustomerPhoneNumber !== "") || !dataRequiredField.CorporateCompanyPhone) &&
                ((dataRequiredField.CorporateCompanyPhoneExtension && formCustomerCustomerPhoneExt !== "") || !dataRequiredField.CorporateCompanyPhoneExtension) &&
                ((dataRequiredField.CorporateEmail && formCustomerEmail !== "") || !dataRequiredField.CorporateEmail) &&
                ((dataRequiredField.CorporateFax && formCustomerCustomerFax !== "") || !dataRequiredField.CorporateFax) &&
                ((dataRequiredField.CorporateFirstName && formCustomerFirstName !== "") || !dataRequiredField.CorporateFirstName) &&
                ((dataRequiredField.CorporateLastName && formCustomerLastName !== "") || !dataRequiredField.CorporateLastName) &&
                ((dataRequiredField.CorporateNotes && formCustomerNote !== "") || !dataRequiredField.CorporateNotes) &&
                ((dataRequiredField.CorporatePhone && formCustomerPhoneNumber !== "") || !dataRequiredField.CorporatePhone) &&
                ((dataRequiredField.CorporatePhoneExtension && formCustomerPhoneExt !== "") || !dataRequiredField.CorporatePhoneExtension)
            )
        ) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading
            const preField = formCustomerType === 1 ? 'retail' : 'corporate';

            // send data
            const params = {};
            params['customerId'] = formCustomerId;
            params['customerType'] = formCustomerType;
            params[preField + 'CompanyName'] = formCustomerCompanyName;
            params[preField + 'FirstName'] = formCustomerFirstName;
            params[preField + 'LastName'] = formCustomerLastName;
            params[preField + 'CompanyPhone'] = formCustomerCustomerPhoneNumber;
            params[preField + 'CompanyPhoneExtension'] = formCustomerCustomerPhoneExt;
            params[preField + 'Phone'] = formCustomerPhoneNumber;
            params[preField + 'PhoneExtension'] = formCustomerPhoneExt;
            params[preField + 'Mobile'] = '';
            params[preField + 'Fax'] = formCustomerCustomerFax;
            params[preField + 'Email'] = formCustomerEmail;
            params[preField + 'CustomerSource'] = '';
            params[preField + 'BillingApartment'] = '';
            params[preField + 'BillingAddress'] = formCustomerBillingAddress;
            params[preField + 'BillingCity'] = formCustomerCity;
            params[preField + 'BillingProvince'] = formCustomerProvince;
            params[preField + 'BillingPostalCode'] = formCustomerPostalCode;
            params[preField + 'BillingSameAsShipping'] = formCustomerBillingSameShipping ? 'on' : '';
            params[preField + 'ShippingApartment'] = '';
            params[preField + 'ShippingAddress'] = formCustomerShippingAddress;
            params[preField + 'ShippingCity'] = formCustomerShippingCity;
            params[preField + 'ShippingProvince'] = formCustomerShippingProvince;
            params[preField + 'ShippingPostalCode'] = formCustomerShippingPostalCode;
            params[preField + 'Notes'] = formCustomerNote;
            CustomerApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Customer Saved!", 'success'); // show success

                        if(formCustomerId === '') resetForm();
                        if(props.newCustomer !== undefined) props.newCustomer(response.data.customerId, (preField === 'retail') ? formCustomerFirstName + ' ' + formCustomerLastName : formCustomerCompanyName, response.data);
                        props.loadData();
                        if(props.closeAfterSave !== undefined && props.closeAfterSave) props.handleDialog();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormCustomerId('');
        setFormCustomerType(1);
        setFormCustomerCompanyName('');
        setFormCustomerCustomerPhoneNumber('');
        setFormCustomerCustomerPhoneExt('');
        setFormCustomerCustomerFax('');
        setFormCustomerFirstName('');
        setFormCustomerLastName('');
        setFormCustomerEmail('');
        setFormCustomerPhoneNumber('');
        setFormCustomerPhoneExt('');
        setFormCustomerBillingAddress('');
        setFormCustomerCity('');
        setFormCustomerProvince('');
        setFormCustomerPostalCode('');
        setFormCustomerShippingAddress('');
        setFormCustomerShippingCity('');
        setFormCustomerShippingProvince('');
        setFormCustomerShippingPostalCode('');
        setFormCustomerNote('');
        setFormCustomerBillingSameShipping('');
    };

    // customer edit
    const handleCustomerEdit = (data) =>
    {
        setFormCustomerId(data.customerId);
        setFormCustomerType(data.customerType);
        setFormCustomerCompanyName(data.companyName);
        setFormCustomerCustomerPhoneNumber(data.companyPhone);
        setFormCustomerCustomerPhoneExt(data.companyPhoneExtension);
        setFormCustomerCustomerFax(data.fax);
        setFormCustomerFirstName(data.firstName);
        setFormCustomerLastName(data.lastName);
        setFormCustomerEmail(data.email);
        setFormCustomerPhoneNumber(data.phone);
        setFormCustomerPhoneExt(data.phoneExtension);
        setFormCustomerBillingAddress(data.billingAddress);
        setFormCustomerCity(data.billingCity);
        setFormCustomerProvince(data.billingProvince);
        setFormCustomerPostalCode(data.billingPostalCode);
        setFormCustomerShippingAddress(data.shippingAddress);
        setFormCustomerShippingCity(data.shippingCity);
        setFormCustomerShippingProvince(data.shippingProvince);
        setFormCustomerShippingPostalCode(data.shippingPostalCode);
        setFormCustomerNote(data.notes);
        setFormCustomerBillingSameShipping(data.billingSameAsShipping === 1);
    };

    // customer delete
    // const handleCustomerDelete = (index) =>
    // {
    //     setDataLoading(true); // show loading
    //
    //     // send data
    //     const params = {'customerId': data.customerId};
    //     CustomerApi.delete(params).then
    //     (
    //         function(response)
    //         {
    //             if(response.status === 200)
    //             {
    //                 alertAction.current.showAlert("Customer Deleted!", 'success'); // show success
    //
    //                 loadData();
    //             }
    //             else
    //             {
    //                 setDataLoading(false); // hide loading
    //                 alertAction.current.showAlert("Incorrect information"); // show error
    //             }
    //         }
    //     ).catch
    //     (
    //         function(error)
    //         {
    //             setDataLoading(false); // hide loading
    //             alertAction.current.showAlert("There is a Problem"); // show error
    //         }
    //     );
    // };

    //
    return (
        <>
            {/* customer form */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='md'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formCustomerId === '' ? 'Add' : 'Edit'} Customer</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-customer-box form-box-t2">
                    {
                        !dataLoading ?
                            <Container className="form-container">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack className="form-switch" direction="row" spacing={1} alignItems="center">
                                            <span className="form-label form-label-m0">Corporate</span>
                                            <Switch defaultChecked checked={formCustomerType === 1} onChange={(e) => setFormCustomerType(e.target.checked ? 1 : 0)} />
                                            <span className="form-label form-label-m0">Retail</span>
                                        </Stack>
                                    </Grid>

                                    <div className="form-divider"></div>

                                    <Grid item xs={12} sm={6} className={formCustomerType === 1 ? 'display-none' : ''}>
                                        <InputLabel shrink className={(formCustomerType === 0 && dataRequiredField.CorporateCompanyName) ? "form-label form-star" : "form-label"} htmlFor="form-customer-company-name">Company Name</InputLabel>
                                        <TextField className="form-input" id="form-customer-company-name" placeholder="" value={formCustomerCompanyName} onChange={(e) => setFormCustomerCompanyName(e.target.value)} />
                                    </Grid>

                                    <div className={formCustomerType === 1 ? 'display-none' : 'form-divider'}></div>

                                    <Grid item xs={12} sm={6} className={formCustomerType === 1 ? 'display-none' : ''}>
                                        <InputLabel shrink className={(formCustomerType === 0 && dataRequiredField.CorporateCompanyPhone) ? "form-label form-star" : "form-label"} htmlFor="form-customer-customer-phone-number">Customer Phone Number</InputLabel>
                                        <TextField className="form-input" id="form-customer-customer-phone-number" placeholder="" value={formCustomerCustomerPhoneNumber} onChange={(e) => setFormCustomerCustomerPhoneNumber(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} className={formCustomerType === 1 ? 'display-none' : ''}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6}>
                                                <InputLabel shrink className={(formCustomerType === 0 && dataRequiredField.CorporateCompanyPhoneExtension) ? "form-label form-star" : "form-label"} htmlFor="form-customer-customer-phone-ext">Ext.</InputLabel>
                                                <TextField className="form-input" id="form-customer-customer-phone-ext" placeholder="" value={formCustomerCustomerPhoneExt} onChange={(e) => setFormCustomerCustomerPhoneExt(e.target.value)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6} className={formCustomerType === 1 ? 'display-none' : ''}>
                                        <InputLabel shrink className={(formCustomerType === 0 && dataRequiredField.CorporateFax) ? "form-label form-star" : "form-label"} htmlFor="form-customer-customer-fax ">Customer Fax</InputLabel>
                                        <TextField className="form-input" id="form-customer-customer-fax" placeholder="" value={formCustomerCustomerFax} onChange={(e) => setFormCustomerCustomerFax(e.target.value)} />
                                    </Grid>

                                    <div className={formCustomerType === 1 ? 'display-none' : 'form-divider'}></div>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailFirstName) || (formCustomerType === 0 && dataRequiredField.CorporateFirstName)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-first-name">First Name</InputLabel>
                                        <TextField className="form-input" id="form-customer-first-name" placeholder="" value={formCustomerFirstName} onChange={(e) => setFormCustomerFirstName(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailLastName) || (formCustomerType === 0 && dataRequiredField.CorporateLastName)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-last-name">Last Name</InputLabel>
                                        <TextField className="form-input" id="form-customer-last-name" placeholder="" value={formCustomerLastName} onChange={(e) => setFormCustomerLastName(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailEmail) || (formCustomerType === 0 && dataRequiredField.CorporateEmail)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-email">Email Address</InputLabel>
                                        <TextField className="form-input" id="form-customer-email" placeholder="" value={formCustomerEmail} onChange={(e) => setFormCustomerEmail(e.target.value)} />
                                    </Grid>

                                    <div className="form-divider"></div>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailPhone) || (formCustomerType === 0 && dataRequiredField.CorporatePhone)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-phone-number">Phone Number</InputLabel>
                                        <TextField className="form-input" id="form-customer-phone-number" placeholder="" value={formCustomerPhoneNumber} onChange={(e) => setFormCustomerPhoneNumber(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6}>
                                                <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailPhoneExtension) || (formCustomerType === 0 && dataRequiredField.CorporatePhoneExtension)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-phone-ext">Ext.</InputLabel>
                                                <TextField className="form-input" id="form-customer-phone-ext" placeholder="" value={formCustomerPhoneExt} onChange={(e) => setFormCustomerPhoneExt(e.target.value)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailBillingAddress) || (formCustomerType === 0 && dataRequiredField.CorporateBillingAddress)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-billing-address">Billing Address</InputLabel>
                                                <TextField className="form-input" id="form-customer-billing-address" placeholder="" value={formCustomerBillingAddress} onChange={(e) => setFormCustomerBillingAddress(e.target.value)} />
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailBillingCity) || (formCustomerType === 0 && dataRequiredField.CorporateBillingCity)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-city">City</InputLabel>
                                                        <TextField className="form-input" id="form-customer-city" placeholder="" value={formCustomerCity} onChange={(e) => setFormCustomerCity(e.target.value)} />
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailProvince) || (formCustomerType === 0 && dataRequiredField.CorporateBillingProvince)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-province">State/Province</InputLabel>
                                                        <Select className="form-select" id="form-customer-province" value={formCustomerProvince} onChange={(e) => setFormCustomerProvince(e.target.value)}>
                                                            <MenuItem value="">&nbsp;</MenuItem>
                                                            {
                                                                dataProvince !== null ?
                                                                    dataProvince.map
                                                                    (
                                                                        (item) =>
                                                                        {
                                                                            return (
                                                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                            );
                                                                        }
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailPostalCode) || (formCustomerType === 0 && dataRequiredField.CorporateBillingPostalCode)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-zip-code">ZIP / Postal Code</InputLabel>
                                                        <TextField className="form-input" id="form-customer-zip-code" placeholder="" value={formCustomerPostalCode} onChange={(e) => setFormCustomerPostalCode(e.target.value)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-customer-shipping-address">Shipping Address<FormControlLabel control={<Checkbox checked={formCustomerBillingSameShipping} onChange={(e) => setFormCustomerBillingSameShipping(e.target.checked)} />} className="form-control-label-check-box" label="Same as billing address" /></InputLabel>
                                                <TextField className={formCustomerBillingSameShipping ? 'display-none' : 'form-input'} id="form-customer-shipping-address" placeholder="" value={formCustomerShippingAddress} onChange={(e) => setFormCustomerShippingAddress(e.target.value)} />
                                            </Grid>

                                            <div className={formCustomerBillingSameShipping ? 'display-none' : 'form-divider'}></div>

                                            <Grid item xs={12} sm={12} className={formCustomerBillingSameShipping ? 'display-none' : ''}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-customer-shipping-city">City</InputLabel>
                                                        <TextField className="form-input" id="form-customer-shipping-city" placeholder="" value={formCustomerShippingCity} onChange={(e) => setFormCustomerShippingCity(e.target.value)} />
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-customer-shipping-province">State/Province</InputLabel>
                                                        <Select className="form-select" id="form-customer-shipping-province" value={formCustomerShippingProvince} onChange={(e) => setFormCustomerShippingProvince(e.target.value)}>
                                                            <MenuItem value="">&nbsp;</MenuItem>
                                                            {
                                                                dataProvince !== null ?
                                                                    dataProvince.map
                                                                    (
                                                                        (item) =>
                                                                        {
                                                                            return (
                                                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                            );
                                                                        }
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <div className={formCustomerBillingSameShipping ? 'display-none' : 'form-divider'}></div>

                                            <Grid item xs={12} sm={12} className={formCustomerBillingSameShipping ? 'display-none' : ''}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-customer-shipping-zip-code">ZIP / Postal Code</InputLabel>
                                                        <TextField className="form-input" id="form-customer-shipping-zip-code" placeholder="" value={formCustomerShippingPostalCode} onChange={(e) => setFormCustomerShippingPostalCode(e.target.value)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <div className="form-divider"></div>

                                    <Grid item xs={12} sm={6}>
                                        <InputLabel shrink className={((formCustomerType === 1 && dataRequiredField.RetailNotes) || (formCustomerType === 0 && dataRequiredField.CorporateNotes)) ? "form-label form-star" : "form-label"} htmlFor="form-customer-note">Addtional Notes</InputLabel>
                                        <TextField className="form-input" id="form-customer-note" multiline={true} placeholder="" value={formCustomerNote} onChange={(e) => setFormCustomerNote(e.target.value)} />
                                    </Grid>
                                </Grid>
                            </Container>
                            :
                            <Loading />
                    }
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading && !dataLoading ?
                            <>
                                <Button onClick={saveCustomer} className="dialog-btn-save">Save Customer</Button>
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default Customer;
