import React, { useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { AuthContext, useAuth } from "./contexts/Auth";
import { PageContext, usePage } from "./contexts/Page";
import { MdDescription, MdExitToApp } from "react-icons/md";
import Router from "./routes/Router";
import Menus from "./utils/Menu";
// import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "./styles/Main.css";
import General from "./utils/General";
import {Scrollbars} from "react-custom-scrollbars-2";
import imageLogo from './images/logo.png';
import imageUser from './images/user.png';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';

//
function App(props)
{
    // user auth
    const auth = useAuth();
    const uPage = usePage();
    const [authenticated, setAuthenticated] = useState(auth.checkAuthenticate);
    const authentication = (data) =>
    {
        auth.setAuthenticate(data);
        setAuthenticated(data !== undefined && data !== null);
    };

    // page data :: title & path
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState("");
    const [pageTitle2, setPageTitle2] = useState("");
    const [pageName, setPageName] = useState("");
    const [pagePath, setPagePath] = useState("");
    const [activeMenu, setActiveMenu] = useState("");
    const [searchWord, setSearchWord] = useState("");
    const pageData = (data) =>
    {
        uPage.pageTitle = data.title;
        uPage.pageTitle2 = data.title2;
        uPage.pageName = data.pageName;
        setPageTitle(data.title);
        setPageTitle2(data.title2);
        setPageName(data.pageName);
        setPagePath(data.path);
        setActiveMenu(data.activeMenu);

        auth.token();
    };

    // active link of current page
    const checkPageLink = (currentPage, currentPageRegex = undefined) =>
    {
        if(activeMenu === currentPage)
        {
            return "active";
        }

        return null;
    };

    // log out
    function logOut()
    {
        authentication(null);
    }

    // search
    function search()
    {
        if(searchWord !== "")
        {
            navigate('/search/' + searchWord.replace(/ /g, '-'));

            if(activeMenu === 'search') window.location.reload();
        }
    }

    //
    return (
        <AuthContext.Provider value={{ authenticated: authenticated, authentication: authentication }}>
            <PageContext.Provider value={{ pageData: pageData }}>
                {
                    authenticated ?
                        (
                            <div className={pageName !== "" ? "main-body main-body-" + pageName : "main-body"}>
                                <div className="col-menu">
                                    <div className="site-logo"><img src={imageLogo} /></div>

                                    <ul className="menu menu-user">
                                        <li className={checkPageLink('profile')}><Link to="/profile"><img className="user-image" src={imageUser} /><span className="user-name">{General.authorizationNameFamily}</span></Link></li>
                                    </ul>

                                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="menu-scrollbar">
                                        <ul className="menu">
                                            {
                                                Object.keys(Menus).map
                                                (
                                                    (key, index) => (Menus[key].isMenu) ?
                                                        <li key={'mm' + index} className={checkPageLink(Menus[key].className)}><Link to={Menus[key].link}><img src={Menus[key].image} className="icon" /><span className="title">{Menus[key].title}</span></Link></li>
                                                        :
                                                        <li key={'mm' + index} className="menu-section">{Menus[key].title}</li>
                                                )
                                            }
                                            <li><Link to="" onClick={logOut}><MdExitToApp className="icon" /><span className="title">Logout</span></Link></li>
                                        </ul>
                                    </Scrollbars>
                                </div>

                                <div className="col-content">
                                    <div className="search-bar">
                                        <Box className="search-box form-box">
                                            <Search className="icon" onClick={search} />
                                            <TextField placeholder="Search" className="form-input" onChange={(e) => setSearchWord(e.target.value)} />
                                        </Box>
                                    </div>

                                    <Router />
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className="main-body">
                                <Router />
                            </div>
                        )
                }
            </PageContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
