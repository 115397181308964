import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Loading from "../../components/Loading/Loading";
import AlertAction from "../../components/AlertAction";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import RequiredFieldApi from "../../services/RequiredFieldApi";
import TasksApi from "../../services/TaskApi";
import Checkbox from '@mui/material/Checkbox';
import "./RequiredField.css";

//--------------------------------------------------

function RequiredField(props)
{
    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);

    const alertAction = useRef(); // init alert

    // requiredField
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        RequiredFieldApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // requiredField save
    const saveRequiredField = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'data': data};
        RequiredFieldApi.save(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("RequiredField Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const handleRequiredFieldChange = (e, index) =>
    {
        let tmpData = data;
        tmpData[index].isChecked = e.target.checked;
        setData([...tmpData, ...[]]);
    };

    //
    return (
        <>
            {/* requiredField list */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Manage Required Fields</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content dialog-content-required-field">
                    {/*<Button onClick={handleRequiredFieldFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Characterisitic</Button>*/}

                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                        {
                            dataLoading ?
                                (
                                    <Loading />
                                )
                                :
                                (
                                    data !== null ?
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th className="w-100">Required</th>
                                                <th>Characterisitc</th>
                                                <th>Type</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                data.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td><Checkbox checked={item.isChecked} disabled={item.isDisabled} onChange={(e) => handleRequiredFieldChange(e, index)} /></td>
                                                                <td>{item.name}</td>
                                                                <td>{item.type}</td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <AlertDataEmpty />
                                )
                        }
                    </Scrollbars>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                {data !== null ? <Button onClick={saveRequiredField} className="dialog-btn-save">Save Changes</Button> : null}
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default RequiredField;
