import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination.js";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertAction from "../../components/AlertAction";
import "./InvoiceTemplate.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InvoiceTemplateApi from "../../services/InvoiceTemplateApi";
import ProvinceApi from "../../services/ProvinceApi";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';

//--------------------------------------------------

function InvoiceTemplate(props)
{
    // set page title & page path
    const pageTitle = 'Invoice Template';
    usePage().pageData({title: pageTitle, activeMenu: 'settings'});

    //
    const [data, setData] = useState([]);
    const [dataProvince, setDataProvince] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const [formLoading, setFormLoading] = useState(false);
    const [formInvoiceTemplateCompanyName, setFormInvoiceTemplateCompanyName] = useState('');
    const [formInvoiceTemplateCustomerPhoneNumber, setFormInvoiceTemplateCustomerPhoneNumber] = useState('');
    const [formInvoiceTemplateCustomerPhoneExt, setFormInvoiceTemplateCustomerPhoneExt] = useState('');
    const [formInvoiceTemplateCustomerFax, setFormInvoiceTemplateCustomerFax] = useState('');
    const [formInvoiceTemplateFirstName, setFormInvoiceTemplateFirstName] = useState('');
    const [formInvoiceTemplateLastName, setFormInvoiceTemplateLastName] = useState('');
    const [formInvoiceTemplateEmail, setFormInvoiceTemplateEmail] = useState('');
    const [formInvoiceTemplatePhoneNumber, setFormInvoiceTemplatePhoneNumber] = useState('');
    const [formInvoiceTemplatePhoneExt, setFormInvoiceTemplatePhoneExt] = useState('');
    const [formInvoiceTemplateBillingAddress, setFormInvoiceTemplateBillingAddress] = useState('');
    const [formInvoiceTemplateCity, setFormInvoiceTemplateCity] = useState('');
    const [formInvoiceTemplateProvince, setFormInvoiceTemplateProvince] = useState('');
    const [formInvoiceTemplatePostalCode, setFormInvoiceTemplatePostalCode] = useState('');
    const [formInvoiceTemplateWebsiteAddress, setFormInvoiceTemplateWebsiteAddress] = useState('');
    const [formInvoiceTemplateTaxId, setFormInvoiceTemplateTaxId] = useState('');
    const [formInvoiceTemplateTaxInfo, setFormInvoiceTemplateTaxInfo] = useState('');
    const [formInvoiceTemplateTaxName1, setFormInvoiceTemplateTaxName1] = useState('');
    const [formInvoiceTemplateTaxPercentage1, setFormInvoiceTemplateTaxPercentage1] = useState('');
    const [formInvoiceTemplateTaxName2, setFormInvoiceTemplateTaxName2] = useState('');
    const [formInvoiceTemplateTaxPercentage2, setFormInvoiceTemplateTaxPercentage2] = useState('');
    const [formInvoiceTemplateTaxName3, setFormInvoiceTemplateTaxName3] = useState('');
    const [formInvoiceTemplateTaxPercentage3, setFormInvoiceTemplateTaxPercentage3] = useState('');
    const [formInvoiceTemplateCustomField1, setFormInvoiceTemplateCustomField1] = useState('');
    const [formInvoiceTemplateCustomField2, setFormInvoiceTemplateCustomField2] = useState('');
    const [formInvoiceTemplateFooter, setFormInvoiceTemplateFooter] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        InvoiceTemplateApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data);

                    setFormInvoiceTemplateCompanyName(response.data.companyName);
                    setFormInvoiceTemplateCustomerPhoneNumber('');
                    setFormInvoiceTemplateCustomerPhoneExt('');
                    setFormInvoiceTemplateCustomerFax('');
                    setFormInvoiceTemplateFirstName('');
                    setFormInvoiceTemplateLastName('');
                    setFormInvoiceTemplateEmail(response.data.email);
                    setFormInvoiceTemplatePhoneNumber(response.data.phone);
                    setFormInvoiceTemplatePhoneExt('');
                    setFormInvoiceTemplateBillingAddress(response.data.address);
                    setFormInvoiceTemplateCity(response.data.city);
                    setFormInvoiceTemplateProvince(response.data.province);
                    setFormInvoiceTemplatePostalCode(response.data.postalCode);
                    setFormInvoiceTemplateWebsiteAddress(response.data.webAddress);
                    setFormInvoiceTemplateTaxId(response.data.taxId);
                    setFormInvoiceTemplateTaxInfo(response.data.isInvoiceTaxesIncluded);
                    setFormInvoiceTemplateTaxName1(response.data.taxName1);
                    setFormInvoiceTemplateTaxPercentage1(response.data.taxPercentage1);
                    setFormInvoiceTemplateTaxName2(response.data.taxName2);
                    setFormInvoiceTemplateTaxPercentage2(response.data.taxPercentage2);
                    setFormInvoiceTemplateTaxName3(response.data.taxName3);
                    setFormInvoiceTemplateTaxPercentage3(response.data.taxPercentage3);
                    setFormInvoiceTemplateCustomField1(response.data.custom1);
                    setFormInvoiceTemplateCustomField2(response.data.custom2);
                    setFormInvoiceTemplateFooter(response.data.footer);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataProvince = () =>
    {
        setDataProvince(null); // set data to null

        // get data
        ProvinceApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataProvince(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); loadDataProvince(); }, []);

    // invoiceTemplate save
    const saveInvoiceTemplate = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'companyName': formInvoiceTemplateCompanyName,
                'address': formInvoiceTemplateBillingAddress,
                'city': formInvoiceTemplateCity,
                'province': formInvoiceTemplateProvince,
                'postalCode': formInvoiceTemplatePostalCode,
                'phone': formInvoiceTemplatePhoneNumber,
                'fax': formInvoiceTemplateCustomerFax,
                'email': formInvoiceTemplateEmail,
                'webAddress': formInvoiceTemplateWebsiteAddress,
                'taxId': formInvoiceTemplateTaxId,
                'custom1': formInvoiceTemplateCustomField1,
                'custom2': formInvoiceTemplateCustomField2,
                'footer': formInvoiceTemplateFooter,
                'taxesIncluded': formInvoiceTemplateTaxInfo,
                'taxName1': formInvoiceTemplateTaxName1,
                'taxPercentage1': formInvoiceTemplateTaxPercentage1,
                'taxName2': formInvoiceTemplateTaxName2,
                'taxPercentage2': formInvoiceTemplateTaxPercentage2,
                'taxName3': formInvoiceTemplateTaxName3,
                'taxPercentage3': formInvoiceTemplateTaxPercentage3,
            };
        InvoiceTemplateApi.save(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Invoice Template Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-invoice-template">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                <Link className="link" underline="hover" color="inherit" to="/settings">Settings</Link>
                <Typography className="current-page" key="3" color="text.primary">Manage Invoice Template</Typography>,
            </Breadcrumbs>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <div className="form-box form-box-t2">
                                    <Container className="form-container">
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-company-name">Company Name</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-company-name" placeholder="" value={formInvoiceTemplateCompanyName} onChange={(e) => setFormInvoiceTemplateCompanyName(e.target.value)} />
                                            </Grid>

                                            {/*<div className="form-divider"></div>*/}

                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <InputLabel shrink className="form-label" htmlFor="form-invoice-template-customer-phone-number">Customer Phone Number</InputLabel>*/}
                                            {/*    <TextField className="form-input" id="form-invoice-template-customer-phone-number" placeholder="" value={formInvoiceTemplateCustomerPhoneNumber} onChange={(e) => setFormInvoiceTemplateCustomerPhoneNumber(e.target.value)} />*/}
                                            {/*</Grid>*/}

                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <Grid container spacing={2}>*/}
                                            {/*        <Grid item xs={6} sm={6}>*/}
                                            {/*            <InputLabel shrink className="form-label" htmlFor="form-invoice-template-customer-phone-ext">Ext.</InputLabel>*/}
                                            {/*            <TextField className="form-input" id="form-invoice-template-customer-phone-ext" placeholder="" value={formInvoiceTemplateCustomerPhoneExt} onChange={(e) => setFormInvoiceTemplateCustomerPhoneExt(e.target.value)} />*/}
                                            {/*        </Grid>*/}
                                            {/*    </Grid>*/}
                                            {/*</Grid>*/}

                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <InputLabel shrink className="form-label" htmlFor="form-invoice-template-customer-fax ">Customer Fax</InputLabel>*/}
                                            {/*    <TextField className="form-input" id="form-invoice-template-customer-fax" placeholder="" value={formInvoiceTemplateCustomerFax} onChange={(e) => setFormInvoiceTemplateCustomerFax(e.target.value)} />*/}
                                            {/*</Grid>*/}

                                            <div className="form-divider"></div>

                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <InputLabel shrink className="form-label" htmlFor="form-invoice-template-first-name">First Name</InputLabel>*/}
                                            {/*    <TextField className="form-input" id="form-invoice-template-first-name" placeholder="" value={formInvoiceTemplateFirstName} onChange={(e) => setFormInvoiceTemplateFirstName(e.target.value)} />*/}
                                            {/*</Grid>*/}

                                            {/*<Grid item xs={12} sm={6}>*/}
                                            {/*    <InputLabel shrink className="form-label" htmlFor="form-invoice-template-last-name">Last Name</InputLabel>*/}
                                            {/*    <TextField className="form-input" id="form-invoice-template-last-name" placeholder="" value={formInvoiceTemplateLastName} onChange={(e) => setFormInvoiceTemplateLastName(e.target.value)} />*/}
                                            {/*</Grid>*/}

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-email">Email Address</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-email" placeholder="" value={formInvoiceTemplateEmail} onChange={(e) => setFormInvoiceTemplateEmail(e.target.value)} />
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-phone-number">Phone Number</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-phone-number" placeholder="" value={formInvoiceTemplatePhoneNumber} onChange={(e) => setFormInvoiceTemplatePhoneNumber(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-invoice-template-phone-ext">Ext.</InputLabel>
                                                        <TextField className="form-input" id="form-invoice-template-phone-ext" placeholder="" value={formInvoiceTemplatePhoneExt} onChange={(e) => setFormInvoiceTemplatePhoneExt(e.target.value)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-billing-address">Billing Address</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-billing-address" placeholder="" value={formInvoiceTemplateBillingAddress} onChange={(e) => setFormInvoiceTemplateBillingAddress(e.target.value)} />
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-invoice-template-city">City</InputLabel>
                                                        <TextField className="form-input" id="form-invoice-template-city" placeholder="" value={formInvoiceTemplateCity} onChange={(e) => setFormInvoiceTemplateCity(e.target.value)} />
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-invoice-template-province">State/Province</InputLabel>
                                                        <Select className="form-select" id="form-invoice-template-province" value={formInvoiceTemplateProvince} onChange={(e) => setFormInvoiceTemplateProvince(e.target.value)}>
                                                            <MenuItem value="">&nbsp;</MenuItem>
                                                            {
                                                                dataProvince !== null ?
                                                                    dataProvince.map
                                                                    (
                                                                        (item) =>
                                                                        {
                                                                            return (
                                                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                            );
                                                                        }
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sm={6}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-invoice-template-zip-code">ZIP / Postal Code</InputLabel>
                                                        <TextField className="form-input" id="form-invoice-template-zip-code" placeholder="" value={formInvoiceTemplatePostalCode} onChange={(e) => setFormInvoiceTemplatePostalCode(e.target.value)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-website-address">Website Address</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-website-address" placeholder="" value={formInvoiceTemplateWebsiteAddress} onChange={(e) => setFormInvoiceTemplateWebsiteAddress(e.target.value)} />
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-customer-custom-field-1">Custom Field 1</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-customer-custom-field-1" placeholder="" value={formInvoiceTemplateCustomField1} onChange={(e) => setFormInvoiceTemplateCustomField1(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-customer-custom-field-2">Custom Field 2</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-customer-custom-field-2" placeholder="" value={formInvoiceTemplateCustomField2} onChange={(e) => setFormInvoiceTemplateCustomField2(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-customer-footer">Footer</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-customer-footer" placeholder="" value={formInvoiceTemplateFooter} onChange={(e) => setFormInvoiceTemplateFooter(e.target.value)} />
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-id">Tax Id</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-id" placeholder="" value={formInvoiceTemplateTaxId} onChange={(e) => setFormInvoiceTemplateTaxId(e.target.value)} />
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-info">Tax Info are taxes included</InputLabel>
                                                <Stack className="form-switch" direction="row" spacing={1} alignItems="center">
                                                    <span className="form-label form-label-m0">Yes</span>
                                                    <Switch defaultChecked checked={formInvoiceTemplateTaxInfo === 0} onChange={(e) => setFormInvoiceTemplateTaxInfo(e.target.checked ? 0 : 1)} />
                                                    <span className="form-label form-label-m0">No</span>
                                                </Stack>
                                            </Grid>

                                            <div className="form-divider"></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-name-1">Tax 1 Name</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-name-1" placeholder="" value={formInvoiceTemplateTaxName1} onChange={(e) => setFormInvoiceTemplateTaxName1(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-percentage-1">Tax %</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-percentage-1" placeholder="" value={formInvoiceTemplateTaxPercentage1} onChange={(e) => setFormInvoiceTemplateTaxPercentage1(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-name-2">Tax 2 Name</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-name-2" placeholder="" value={formInvoiceTemplateTaxName2} onChange={(e) => setFormInvoiceTemplateTaxName2(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-percentage-2">Tax %</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-percentage-2" placeholder="" value={formInvoiceTemplateTaxPercentage2} onChange={(e) => setFormInvoiceTemplateTaxPercentage2(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-name-3">Tax 3 Name</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-name-3" placeholder="" value={formInvoiceTemplateTaxName3} onChange={(e) => setFormInvoiceTemplateTaxName3(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-invoice-template-tax-percentage-3">Tax %</InputLabel>
                                                <TextField className="form-input" id="form-invoice-template-tax-percentage-3" placeholder="" value={formInvoiceTemplateTaxPercentage3} onChange={(e) => setFormInvoiceTemplateTaxPercentage3(e.target.value)} />
                                            </Grid>

                                            {
                                                !formLoading ?
                                                    <div className="form-invoice-template-btn-bar">
                                                        <Button onClick={saveInvoiceTemplate} className="btn-blue btn-blue-outline">Save Changes</Button>
                                                    </div>
                                                    :
                                                    <Loading />
                                            }
                                        </Grid>
                                    </Container>
                                </div>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default InvoiceTemplate;
