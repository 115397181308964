import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading/Loading";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {Button, Container, Grid, TextField} from "@mui/material";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import QuoteApi from "../../services/QuoteApi";
import JobApi from "../../services/JobApi";
import Select from "@mui/material/Select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from "@mui/material/Typography";
import General from "../../utils/General";
import "react-datepicker/dist/react-datepicker.css";
import UserApi from "../../services/UserApi";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import imageStationArrowIcon from "../../images/station-arrow-icon.png";
import imageIconWorkflow from "../../images/icon-change-workflow.png";
import imageIconReturn from "../../images/icon-return.png";
import Popover from '@mui/material/Popover';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { default  as JobEdit } from "../../components/Job/Job";
import "./Job.css";
import ButtonGroup from '@mui/material/ButtonGroup';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

//--------------------------------------------------

function Job(props)
{
    // get id
    let { id } = useParams();

    // set page title & page path
    const pageTitle = 'Job';
    usePage().pageData({title: 'Jobs', activeMenu: 'jobs'});

    //
    const [data, setData] = useState(null);
    const [dataSpecialOrder, setDataSpecialOrder] = useState(null);
    const [dataUser, setDataUser] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [jobNumber, setJobNumber] = useState('');
    const [jobStatus, setJobStatus] = useState('');
    const [jobStatusId, setJobStatusId] = useState('');
    const [productionNotes, setProductionNotes] = useState('');
    const [productionNotesTemp, setProductionNotesTemp] = useState('');

    const [returnJobFormDialogOpen, setReturnJobFormDialogOpen] = useState(false);
    const [formReturnJobLoading, setFormReturnJobLoading] = useState(false);
    const [formReturnJobStation, setFormReturnJobStation] = useState('');
    const [formReturnJobNote, setFormReturnJobNote] = useState('');

    const [formJobAssignedToUser, setFormJobAssignedToUser] = useState('');

    const [jobHistoryItemToggle, setJobHistoryItemToggle] = useState('display-none');
    const [jobChatItemToggle, setJobChatItemToggle] = useState('display-none');

    const [jobOrderAnchorEl, setJobOrderAnchorEl] = React.useState(null);
    const jobOrderOpen = Boolean(jobOrderAnchorEl);

    const [nextStationAnchorEl, setNextStationAnchorEl] = React.useState(null);
    const nextStationOpen = Boolean(nextStationAnchorEl);

    const [jobFormDialogOpen, setJobFormDialogOpen] = useState(false);
    const [jobobFormDialogStep, setJobobFormDialogStep] = useState(false);

    const [productionNoteEdit, setProductionNoteEdit] = useState(false);

    const alertAction = useRef(); // init alert
    const navigate = useNavigate();

    // load data
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        JobApi.findJob({jobId: id}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data);
                    setDataSpecialOrder(response.data.specialOrders);
                    setJobNumber(response.data.jobNumber);
                    setJobStatus(response.data.status);
                    setJobStatusId(response.data.statusId);
                    setFormJobAssignedToUser(response.data.assignedUserId);
                    setProductionNotesTemp(response.data.jobOrder.productionNotes);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataUser = () =>
    {
        setDataUser(null); // set data to null

        // get data
        UserApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataUser(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); loadDataUser(); }, []);

    // save Production Note
    const saveProductionNote = () =>
    {
        // send data
        const params =
            {
                'jobId': id,
                'note': productionNotes,
            };
        QuoteApi.saveProductionNote(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Production Note Saved!", 'success'); // show success
                    handleProductionNoteCancel();

                    setProductionNotesTemp(productionNotes);
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    const handleProductionNoteEdit = (pNote) =>
    {
        setProductionNotes(pNote);
        setProductionNoteEdit(true);
    };

    const handleProductionNoteCancel = () =>
    {
        setProductionNoteEdit(false);
        setProductionNotes("");
    };

    // show Return Job form
    const handleReturnJobFormDialog = (e) =>
    {
        setFormReturnJobStation('');
        setFormReturnJobNote('');

        setReturnJobFormDialogOpen(!returnJobFormDialogOpen);
    };

    // save Return Job
    const saveReturnJob = () =>
    {
        if(formReturnJobStation !== "") // check data is correct
        {
            setFormReturnJobLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'jobId': id,
                    'station': formReturnJobStation,
                    'notes': formReturnJobNote,
                };
            JobApi.returnJob(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Return Job Done!", 'success'); // show success

                        setReturnJobFormDialogOpen(false);
                        handleNextStationMenuClose();
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormReturnJobLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormReturnJobLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // delete job
    const handleDeleteJob = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'jobId': id,
            };
        JobApi.deleteJob(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Job Deleted!", 'success'); // show success
                    handleNextStationMenuClose();
                    // loadData();
                    navigate("/jobs", { replace: false });
                }
                else
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // resume job
    const handleResumeJob = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'jobId': id,
            };
        JobApi.resumeJob(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Job Resume!", 'success'); // show success
                    loadData();
                }
                else
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // pause job
    const handlePauseJob = () =>
    {
        setDataLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'jobId': id,
            };
        JobApi.pauseJob(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Job Paused!", 'success'); // show success
                    loadData();
                }
                else
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // assigned To user
    const handleJobAssignedToUserChange = (e) =>
    {
        const userId = e.target.value;

        if(userId >= 0)
        {
            setDataLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'jobId': id,
                    'userId': userId,
                };
            JobApi.assignJob(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("User Assigned!", 'success'); // show success
                        setFormJobAssignedToUser(userId);
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setDataLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
    };

    //
    const goToJob = (newId) =>
    {
        navigate("/job/" + newId, { replace: false });
        id = newId;
        handleJobOrderClose();
        loadData();
    };

    // jobs in order btn
    const handleJobOrderClick = (event) =>
    {
        setJobOrderAnchorEl(event.currentTarget);
    };
    const handleJobOrderClose = () =>
    {
        setJobOrderAnchorEl(null);
    };

    // next Station btn
    const handleNextStationClick = (isLastStation) =>
    {
        setDataLoading(true); // hide btn & show loading

        if(isLastStation)
        {
            // send data
            const params =
                {
                    'jobId': id,
                };
            JobApi.completeJob(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Complete Job Done!", 'success'); // show success

                        setReturnJobFormDialogOpen(false);
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                        setDataLoading(false); // hide btn & show loading
                    }
                }
            ).catch
            (
                function(error)
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            // send data
            const params =
                {
                    'jobId': id,
                };
            JobApi.nextStationJob(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Next Station Done!", 'success'); // show success

                        setReturnJobFormDialogOpen(false);
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                        setDataLoading(false); // hide btn & show loading
                    }
                }
            ).catch
            (
                function(error)
                {
                    setDataLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
    };

    const handleNextStationMenuClick = (event) =>
    {
        setNextStationAnchorEl(event.currentTarget);
    };
    const handleNextStationMenuClose = () =>
    {
        setNextStationAnchorEl(null);
    };

    // show job edit form
    const handleJobFormDialog = (step) =>
    {
        handleNextStationMenuClose();

        setJobobFormDialogStep(step);
        setJobFormDialogOpen(true);
    };

    // handle Special Order show hide
    const handleSpecialOrderShowHide = (indexSO) =>
    {
        let tmpData = dataSpecialOrder;

        for (let i = 0; i < tmpData.length; i++)
        {
            if(tmpData[i].secShow !== undefined)
            {
                tmpData[i].secShow = (indexSO === i) ? !tmpData[i].secShow : false;
            }
            else
            {
                tmpData[i].secShow = (indexSO === i);
            }
        }

        setDataSpecialOrder([...tmpData, ...[]]);
    };

    //
    return (
        <div className="page-content page-job">
            <div className="title-bar-breadcrumbs-status">
                <div className="title-bar-breadcrumbs-status-left">
                    <div className="title-bar-breadcrumbs">
                        <div className="title-bar">
                            <span className="title">{pageTitle} #{jobNumber}</span>
                        </div>

                        <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className="link" underline="hover" color="inherit" to="/jobs">Jobs</Link>
                            <Typography className="current-page" key="3" color="text.primary">Job #{jobNumber}</Typography>,
                        </Breadcrumbs>
                    </div>
                    {
                        jobStatus !== '' ?
                            <span className={"label-status " + General.initStatusClass(jobStatus)}>{jobStatus}</span>
                            :
                            null
                    }
                    {
                        !dataLoading && data !== null && data.currentJobStation !== null ?
                            <div className="workflow-station-box active">
                                <div className="station-title-number-box">
                                    <div className="title-station-number">
                                        <div className="title">{data.currentJobStation.name}</div>
                                        <div className="station-number">{data.currentJobStation.abbreviation}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

                {
                    !dataLoading && data !== null && data.jobsOrder !== null ?
                        <>
                            <Button className="btn-blue btn-blue-outline btn-job-order" id="jobs-order-button" aria-describedby={jobOrderOpen ? 'jobs-order-menu' : undefined} onClick={handleJobOrderClick}><span className="job-number">{data.jobsOrder.length}</span> Jobs In Oder</Button>
                            <Popover className="jobs-order-menu" id={jobOrderOpen ? 'jobs-order-menu' : undefined} open={jobOrderOpen} anchorEl={jobOrderAnchorEl} onClose={handleJobOrderClose} anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}>

                                <div className="title">Order #{data.quoteNumber} <Link to={(data.isOrder ? '/order/' : '/quote/') + data.quoteId} className="link-order-view"><RemoveRedEyeIcon className="icon" />View Order</Link></div>

                                {
                                    data.jobsOrder.map
                                    (
                                        (item, index) =>
                                        {
                                            return (
                                                <div onClick={() => goToJob(item.id)} className={item.isCurrent ? "jobs-order-menu-item active" : "jobs-order-menu-item"}>
                                                    <span className="col">{item.jobNumber}</span>
                                                    <span className="col">{item.productCategoryId} - {item.productName}</span>
                                                    <div className="workflow-station-box active">
                                                        <div className="station-title-number-box">
                                                            <div className="title-station-number">
                                                                <div className="title">{item.stationAbbreviation}</div>
                                                                <div className="station-number">{item.stationAbbreviation}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )
                                }
                            </Popover>
                        </>
                        :
                        null
                }
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <>
                                    {/* actions */}
                                    <div className="section-divider"><span className="section-title">Actions:</span></div>
                                    <div className="job-section-action-box">
                                        <div className="next-station-box">
                                            {
                                                jobStatusId === 4 ?
                                                    null
                                                    :
                                                    <>
                                                        <ButtonGroup disabled={formJobAssignedToUser === ""} className={data.isLastStation ? "btn-group-green-2 next-station-button" : "btn-group-blue next-station-button"} variant="contained" aria-label="outlined primary button group">
                                                            <Button className="btn" startIcon={data.isLastStation ? <CheckIcon /> : <SkipNextOutlinedIcon />} onClick={() => handleNextStationClick(data.isLastStation)}>{data.isLastStation ? 'Complete Job' : 'Next Station'}</Button>
                                                            <Button className="btn" id="next-station-button" aria-controls={nextStationOpen ? 'next-station-menu' : undefined} aria-haspopup="true" aria-expanded={nextStationOpen ? 'true' : undefined} onClick={handleNextStationMenuClick}><KeyboardArrowDownIcon /></Button>
                                                        </ButtonGroup>
                                                        {/*<Button className={data.isLastStation ? "btn-green-2 next-station-button" : "btn-blue next-station-button"} id="next-station-button" aria-controls={nextStationOpen ? 'next-station-menu' : undefined} aria-haspopup="true" aria-expanded={nextStationOpen ? 'true' : undefined} onClick={handleNextStationMenuClick} startIcon={data.isLastStation ? <CheckIcon /> : <SkipNextOutlinedIcon />} endIcon={<KeyboardArrowDownIcon />}>{data.isLastStation ? 'Complete Job' : 'Next Station'}</Button>*/}
                                                        <Menu className="next-station-menu" id="next-station-menu" anchorEl={nextStationAnchorEl} open={nextStationOpen} onClose={handleNextStationMenuClose} MenuListProps={{'aria-labelledby': 'next-station-button',}}>
                                                            <MenuItem onClick={handleReturnJobFormDialog} className="next-station-menu-item"><img src={imageIconReturn} className="icon" /> Return</MenuItem>
                                                            <MenuItem onClick={() => handleJobFormDialog(3)} className="next-station-menu-item"><img src={imageIconWorkflow} className="icon" /> Change Workflow</MenuItem>
                                                            {/*<MenuItem onClick={handleDeleteJob} className="next-station-menu-item item-delete"><DeleteOutlineOutlinedIcon className="icon" /> Delete</MenuItem>*/}
                                                        </Menu>

                                                        {/*<Button onClick={handlePauseJob} className="btn-yellow btn-yellow-outline" fullWidth={false} variant="contained" startIcon={<PauseCircleOutlineIcon />}>Pause Job</Button>*/}
                                                    </>
                                            }

                                            <FormControl className="form-box">
                                                <InputLabel className="form-job-assigned-user-label" id="form-job-assigned-user">Assigned To</InputLabel>
                                                <Select className="form-select form-job-assigned-user" id="form-job-assigned-user" value={formJobAssignedToUser} onChange={handleJobAssignedToUserChange}>
                                                    <MenuItem value="">Unassigned</MenuItem>
                                                    {
                                                        dataUser !== null ?
                                                            dataUser.map
                                                            (
                                                                (item) =>
                                                                {
                                                                    return (
                                                                        <MenuItem value={item.userId}>{item.fullName}</MenuItem>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>

                                        {
                                            jobStatusId === 4 ?
                                                <Button onClick={handleResumeJob} className="btn-green btn-green-outline" fullWidth={false} variant="contained" startIcon={<PlayArrowOutlinedIcon />}>Resume Job</Button>
                                                :
                                                <Button onClick={handlePauseJob} className="btn-yellow btn-yellow-outline" fullWidth={false} variant="contained" startIcon={<PauseCircleOutlineIcon />}>Pause Job</Button>
                                        }
                                    </div>


                                    {/* workFlow */}
                                    <div className="job-section-container job-section-work-flow">
                                        <div className="job-section-box">
                                            <div className="title-bar">Current Flow</div>

                                            <div className="content workflow-content">
                                                {
                                                    data.workFlows != null ?
                                                        data.workFlows.map
                                                        (
                                                            (item, index) =>
                                                            {
                                                                return (
                                                                    <div className={data.stationId === item.stationId ? "workflow-station-box active" : "workflow-station-box"}>
                                                                        <div className="station-title-number-box">
                                                                            <div className="title-station-number">
                                                                                <div className="title">{item.name}</div>
                                                                                <div className="station-number">{item.abbreviation}</div>
                                                                            </div>
                                                                            {data.stationId === item.stationId ? <div className="job-current">Current Job</div> : null}
                                                                        </div>
                                                                        <img src={imageStationArrowIcon} className="icon" />
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    {/* order job */}
                                    <div className="job-section-box order-job-box">
                                        <div className="data-box">
                                            <div className="title">Name:</div>
                                            <div className="des">{data.jobOrder.name}</div>
                                        </div>

                                        <Grid container spacing={4} className="container-date">
                                            <Grid item sm={6} xs={12}>
                                                <div className="data-box">
                                                    <div className="title">Date Ordered:</div>
                                                    <div className="des"><span className="date-blue">{data.jobOrder.orderedDate}</span></div>
                                                </div>
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <div className="data-box">
                                                    <div className="title">Date Due:</div>
                                                    <div className="des"><span className={data.jobOrder.isDueDateRequired ? "date-orange" : "date-green"}>{data.jobOrder.dueDate}</span></div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="data-box data-box-border">
                                                    <div className="title">Product / Model</div>
                                                    <div className="des">{data.jobOrder.productModel}</div>

                                                    <div className="title">Dollars</div>
                                                    <div className="des">{data.jobOrder.Dollars}</div>

                                                    <div className="title">Quantity</div>
                                                    <div className="des">{data.jobOrder.quantity}</div>

                                                    {/*<div className="title">Mattress Size</div>*/}
                                                    {/*<div className="des">{data.jobOrder.dueDate}</div>*/}

                                                    {
                                                        data.jobOrder.customs != null ?
                                                            data.jobOrder.customs.map
                                                            (
                                                                (item2) =>
                                                                {
                                                                    return (
                                                                        <>
                                                                            <div className="title">{item2.title}</div>
                                                                            <div className="des">{item2.value}</div>
                                                                        </>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }

                                                    {
                                                        dataSpecialOrder != null ?
                                                            dataSpecialOrder.map
                                                            (
                                                                (itemSO, indexSO) =>
                                                                {
                                                                    return (
                                                                        <div className="special-order-box">
                                                                            <div className="bar-icon-show-hide">
                                                                                <div className="title">Vendors</div>
                                                                                <div className="des">{itemSO.vendorName}</div>

                                                                                <IconButton className="btn-show-hide" onClick={() => handleSpecialOrderShowHide(indexSO)}>{itemSO.secShow ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}</IconButton>
                                                                            </div>
                                                                            {
                                                                                itemSO.secShow ?
                                                                                    <>
                                                                                        {/*<div className="title">Vendors</div>*/}
                                                                                        {/*<div className="des">{itemSO.vendorName}</div>*/}

                                                                                        <div className="title">Station Needed</div>
                                                                                        <div className="des">{itemSO.stationName}</div>

                                                                                        <div className="title">Quantity</div>
                                                                                        <div className="des">{itemSO.quantity}</div>

                                                                                        <div className="title">Size</div>
                                                                                        <div className="des">{itemSO.size}</div>

                                                                                        <div className="title">Product Details</div>
                                                                                        <div className="des">{itemSO.productDetail}</div>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                    {
                                                        data.files != null ?
                                                            <>
                                                                <div className="title">files</div>
                                                                {
                                                                    data.files.map
                                                                    (
                                                                        (itemFile) =>
                                                                        {
                                                                            return (
                                                                                <div className="des-link">
                                                                                    <a href={itemFile.link} target="_blank">{itemFile.name} <span className="file-size">({itemFile.size})</span></a>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="data-box data-box-border note-data-box">
                                                    <div className="title">Notes:</div>
                                                    <div className="note-box">{data.jobOrder.notes}</div>
                                                </div>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="data-box data-box-border production-note-data-box">
                                                    <div className="title">Production Notes:</div>
                                                    {
                                                        productionNoteEdit ?
                                                            <TextField className="form-input" multiline={true} placeholder="" value={productionNotes} onChange={(e) => setProductionNotes(e.target.value)} />
                                                            :
                                                            <TextField className="form-input" multiline={true} placeholder="" value={productionNotesTemp} disabled="disabled" />
                                                    }
                                                    <div>
                                                        {
                                                            productionNoteEdit ?
                                                                <>
                                                                    <Button onClick={() => saveProductionNote()} className="btn-green-2" fullWidth={false} variant="contained">Save Changes</Button>
                                                                    <Button onClick={() => handleProductionNoteCancel()} className="btn-blue btn-blue-outline m-l-16" fullWidth={false} variant="contained">Cancel</Button>
                                                                </>
                                                                :
                                                                <Button onClick={() => handleProductionNoteEdit(productionNotesTemp) } className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                                        }
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button onClick={() => handleJobFormDialog(2)} className="btn-blue btn-job-order-edit" fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                            </Grid>
                                        </Grid>
                                    </div>


                                    {/* job comment & history */}
                                    <Container className="job-section-container job-chat-history-section-container">
                                        <Grid container spacing={4}>
                                            {/*
                                            <Grid item lg={5} md={6} sm={12} xs={12}>
                                                <div className="job-section-box job-section-job-chat-box">
                                                    <div>
                                                        <div className="title-bar">Comments</div>

                                                        <div className="job-chat-item">
                                                            <div className="col-left">
                                                                <div className="user">Mark Hannigan</div>
                                                                <div className="message">Comments about the job and how its going</div>
                                                            </div>
                                                            <div className="col-right">June 19th <IconButton className="btn-delete" onClick={() => {}}><DeleteOutlineOutlinedIcon /></IconButton></div>
                                                        </div>

                                                        <div className="job-chat-item">
                                                            <div className="col-left">
                                                                <div className="user">Mark Hannigan</div>
                                                                <div className="message">Comments about the job and how its going</div>
                                                            </div>
                                                            <div className="col-right">June 19th <IconButton className="btn-delete" onClick={() => {}}><DeleteOutlineOutlinedIcon /></IconButton></div>
                                                        </div>

                                                        <div className={"job-chat-item " + jobChatItemToggle}>
                                                            <div className="col-left">
                                                                <div className="user">Mark Hannigan</div>
                                                                <div className="message">Comments about the job and how its going</div>
                                                            </div>
                                                            <div className="col-right">June 19th <IconButton className="btn-delete" onClick={() => {}}><DeleteOutlineOutlinedIcon /></IconButton></div>
                                                        </div>

                                                        <div className={"job-chat-item " + jobChatItemToggle}>
                                                            <div className="col-left">
                                                                <div className="user">Mark Hannigan</div>
                                                                <div className="message">Comments about the job and how its going</div>
                                                            </div>
                                                            <div className="col-right">June 19th <IconButton className="btn-delete" onClick={() => {}}><DeleteOutlineOutlinedIcon /></IconButton></div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-box">
                                                        {
                                                            jobChatItemToggle === '' ?
                                                                <Button onClick={(e) => setJobChatItemToggle('display-none')} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<CloseIcon />}>Close</Button>
                                                                :
                                                                <Button onClick={(e) => setJobChatItemToggle('')} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<FullscreenIcon />}>Expand</Button>
                                                        }
                                                    </div>
                                                </div>
                                            </Grid>
                                            */}

                                            <Grid item lg={5} md={6} sm={12} xs={12}>
                                                <div className="job-section-box job-section-job-history-box">
                                                    <div>
                                                        <div className="title-bar">Job Updates</div>
                                                        {
                                                            data.jobHistory !== null ?
                                                                data.jobHistory.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            <div className={"job-history-item " + (index > 1 ? jobHistoryItemToggle : '')}>
                                                                                <div className="col-left">
                                                                                    <div className="user">{item.user}</div>
                                                                                    <div className="title">{item.title}</div>
                                                                                    <div className="message">{item.message}</div>
                                                                                </div>

                                                                                <div className="col-right">
                                                                                    <div className="date">{item.date}</div>
                                                                                    <div className="time">{item.time}</div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                                :
                                                                <AlertDataEmpty />
                                                        }
                                                    </div>
                                                    <div className="btn-box">
                                                        {
                                                            data.jobHistory !== null && data.jobHistory.length > 2 ?
                                                                jobHistoryItemToggle === '' ?
                                                                    <Button onClick={(e) => setJobHistoryItemToggle('display-none')} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<CloseIcon />}>Close</Button>
                                                                    :
                                                                    <Button onClick={(e) => setJobHistoryItemToggle('')} className="btn-black btn-black-outline" fullWidth={false} variant="contained" startIcon={<FullscreenIcon />}>Expand</Button>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* return Job */}
            <Dialog open={returnJobFormDialogOpen} onClose={handleReturnJobFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Return Job</span>
                        <IconButton className="dialog-close" onClick={handleReturnJobFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box form-box-t2">
                    <Container className="form-container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <InputLabel shrink className="form-label" htmlFor="form-return-job-station">Return</InputLabel>
                                <Select className="form-select" id="form-return-job-station" value={formReturnJobStation} onChange={(e) => setFormReturnJobStation(e.target.value)}>
                                    <MenuItem value="">&nbsp;</MenuItem>
                                    {
                                        data !== null && data.workFlows !== null ?
                                            data.workFlows.map
                                            (
                                                (item, index) =>
                                                {
                                                    return (
                                                        <MenuItem value={index}>{item.name}</MenuItem>
                                                    );
                                                }
                                            )
                                            :
                                            null
                                    }
                                </Select>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <InputLabel shrink className="form-label" htmlFor="form-return-job-note">Notes</InputLabel>
                                <TextField className="form-input" id="form-return-job-note" multiline={true} placeholder="" value={formReturnJobNote} onChange={(e) => setFormReturnJobNote(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formReturnJobLoading ?
                            <>
                                <Button onClick={saveReturnJob} className="dialog-btn-save">Return</Button>
                                <Button onClick={handleReturnJobFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* edit job form */}
            {jobFormDialogOpen ? <JobEdit handleDialog={() => setJobFormDialogOpen(false)} closeAfterSave={false} dialogStep={jobobFormDialogStep} jobId={id} quoteId={data !== null ? data.quoteId : 0} jobData={data} loadData={() => { setTimeout(loadData, 100); }} /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Job;
