import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Loading from "../../components/Loading/Loading";
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import AlertAction from "../../components/AlertAction";
import "./ProductCatalog.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ProductApi from "../../services/ProductApi";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import CategoryApi from "../../services/CategoryApi";
import StationApi from "../../services/StationApi";
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import CustomCharacteristicApi from "../../services/CustomCharacteristicApi";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import JobApi from "../../services/JobApi";

//--------------------------------------------------

function ProductCatalog(props)
{
    // set page title & page path
    const pageTitle = 'Product Catalog';
    usePage().pageData({title: pageTitle, activeMenu: 'settings'});

    //
    const [dataCategory, setDataCategory] = useState([]);
    const [dataStation, setDataStation] = useState([]);
    const [dataCustomInput, setDataCustomInput] = useState([]);
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const customCharacteristicTabs = ['Existing', 'List', 'Number', 'Text', 'Yes/No'];

    const [formProductCatalogSectionShowHide, setFormProductCatalogSectionShowHide] = useState([-1, 1, 0, 0, 0]);
    const [dataStationSelected, setDataStationSelected] = useState([]);
    const [productCatalogFormDialogOpen, setProductCatalogFormDialogOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formProductCatalogId, setFormProductCatalogId] = useState('');
    const [formProductCatalogCategoryId, setFormProductCatalogCategoryId] = useState('');
    const [formProductCatalogCategoryName, setFormProductCatalogCategoryName] = useState('');
    const [formProductCatalogModelName, setFormProductCatalogModelName] = useState('');
    const [formProductCatalogCustomCharacteristicExistingId, setFormProductCatalogCustomCharacteristicExistingId] = useState('');
    const [formProductCatalogCustomCharacteristicExistingName, setFormProductCatalogCustomCharacteristicExistingName] = useState('');
    const [formProductCatalogCustomCharacteristicName, setFormProductCatalogCustomCharacteristicName] = useState('');
    const [formProductCatalogCustomCharacteristicType, setFormProductCatalogCustomCharacteristicType] = useState(0);
    const [formProductCatalogCustomCharacteristicListName, setFormProductCatalogCustomCharacteristicListName] = useState('');
    const [dataCustomCharacteristicListItems, setDataCustomCharacteristicListItems] = useState([]);
    const [dataCustomCharacteristics, setDataCustomCharacteristics] = useState([]);

    const [categoryFormDialogOpen, setCategoryFormDialogOpen] = useState(false);
    const [formCategoryLoading, setFormCategoryLoading] = useState(false);
    const [formCategoryName, setFormCategoryName] = useState('');
    const [formCategoryId, setFormCategoryId] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        ProductApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataCategory = () =>
    {
        setDataCategory(null); // set data to null

        // get data
        CategoryApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCategory(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataStation = () =>
    {
        setDataStation(null); // set data to null

        // get data
        StationApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataStation(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataCustomInput = () =>
    {
        setDataCustomInput(null); // set data to null

        // get data
        CustomCharacteristicApi.findCustomCharacteristic().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCustomInput(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); loadDataCategory(); loadDataStation(); loadDataCustomInput(); }, []);

    // show form
    const handleProductCatalogFormDialog = (e) =>
    {
        resetForm();

        setProductCatalogFormDialogOpen(!productCatalogFormDialogOpen);
    };

    // productCatalog save
    const saveProductCatalog = () =>
    {
        let workflow = [];
        let tmpDataCustomCharacteristics = dataCustomCharacteristics;

        //
        if(formProductCatalogCategoryId > 0 && formProductCatalogModelName !== "" && dataCustomCharacteristics != null && dataCustomCharacteristics.length > 0 && dataStationSelected != null && dataStationSelected.length > 0) // check data is correct
        {
            for (let i = 0; i < dataStationSelected.length; i++) workflow[i] = dataStationSelected[i].stationId;
            for (let i = 0; i < tmpDataCustomCharacteristics.length; i++)
            {
                if(tmpDataCustomCharacteristics[i].typeId === 0)
                {
                    tmpDataCustomCharacteristics[i].typeId = 5;
                }
            }

            //
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'productId': formProductCatalogId,
                    'productCategory': formProductCatalogCategoryId,
                    'modelName': formProductCatalogModelName,
                    'workflow': workflow,
                    'customInputs': tmpDataCustomCharacteristics,
                };
            ProductApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("ProductCatalog Saved!", 'success'); // show success

                        if(formProductCatalogId !== '') setProductCatalogFormDialogOpen(false);
                        resetForm();

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormProductCatalogSectionShowHide([...[-1, 1, 0, 0, 0], ...[]]);
        setFormProductCatalogId('');
        setFormProductCatalogCategoryId('');
        setFormProductCatalogCategoryName('');
        setFormProductCatalogModelName('');
        setDataStationSelected([]);
        setFormProductCatalogCustomCharacteristicExistingId('');
        setFormProductCatalogCustomCharacteristicExistingName('');
        setFormProductCatalogCustomCharacteristicName('');
        setFormProductCatalogCustomCharacteristicType(0);
        setFormProductCatalogCustomCharacteristicListName('');
        setDataCustomCharacteristicListItems([]);
        setDataCustomCharacteristics([]);
    };

    // productCatalog edit
    const handleProductCatalogEdit = (categoryIndex, productIndex) =>
    {
        let dc = data[categoryIndex];
        let dp = dc.products[productIndex];
        let dci = [];
        let dwf = [];
        let typeId = 0;

        if(dp.customInputs != null)
        {
            for (let i = 0; i < dp.customInputs.length; i++)
            {
                typeId = dp.customInputs[i].customInputTypeId;

                dci[i] = {'typeId': typeId === 5 ? 0 : typeId, 'name': dp.customInputs[i].name, 'id': dp.customInputs[i].id, 'list': null};
            }
        }

        if(dp.workflow != null)
        {
            for (let i = 0; i < dp.workflow.length; i++)
            {
                for (let j = 0; j < dataStation.length; j++)
                {
                    if(dataStation[j].stationId == dp.workflow[i]) dwf[i] = dataStation[j];
                }
            }
        }

        setFormProductCatalogSectionShowHide([...[-1, 1, 0, 0, 0], ...[]]);
        setFormProductCatalogId(dp.productId);
        setFormProductCatalogCategoryId(dc.categoryId);
        setFormProductCatalogCategoryName(dc.categoryName);
        setFormProductCatalogModelName(dp.productName);
        setDataCustomCharacteristics(dci);
        setDataStationSelected(dwf);

        setProductCatalogFormDialogOpen(true);
    };

    // handle products show hide
    const handleProductsShowHide = (index) =>
    {
        let tmpData = data;
        tmpData[index].secShow = !tmpData[index].secShow;

        setData([...tmpData, ...[]]);
    };

    // handle Form Product Catalog Section show hide
    const handleFormProductCatalogSectionShowHide = (index, type) =>
    {
        let tmpData = formProductCatalogSectionShowHide;

        for (let i = 1; i < tmpData.length; i++) if(tmpData[i] === 1) tmpData[i] = 0;

        if(type === 2)
        {
            tmpData[index - 1] = 2;
            tmpData[index] = 1;
        }
        else
        {
            tmpData[index] = 1;
        }

        setFormProductCatalogSectionShowHide([...tmpData, ...[]]);
    };

    // handle add to Station Selected
    const handleAddTodStationSelected = (index) =>
    {
        setDataStationSelected([...dataStationSelected, ...[dataStation[index]]]);
    };

    // handle remove from Station Selected
    const handleRemovedStationSelected = (index) =>
    {
        let tmpData = dataStationSelected;
        tmpData.splice(index, 1);
        setDataStationSelected([...tmpData, ...[]]);
    };

    // handle Custom Characteristic Type Change
    const handleCustomCharacteristicTypeChange = (e, v) =>
    {
        if(v !== null)
        {
            setFormProductCatalogCustomCharacteristicType(v);
            setFormProductCatalogCustomCharacteristicExistingId('');
            setFormProductCatalogCustomCharacteristicExistingName('');
            setFormProductCatalogCustomCharacteristicName('');
            setFormProductCatalogCustomCharacteristicListName('');
            setDataCustomCharacteristicListItems([]);
        }
    };

    // customCharacteristics add
    const handleCustomCharacteristicsAdd = () =>
    {
        if(formProductCatalogCustomCharacteristicType !== '' && formProductCatalogCustomCharacteristicName !== '' && ((formProductCatalogCustomCharacteristicType !== 0 && formProductCatalogCustomCharacteristicType !== 1) || (formProductCatalogCustomCharacteristicType === 0 && formProductCatalogCustomCharacteristicExistingId > 0) || (formProductCatalogCustomCharacteristicType === 1 && dataCustomCharacteristicListItems != null && dataCustomCharacteristicListItems.length > 0)))
        {
            setDataCustomCharacteristics([...(dataCustomCharacteristics), ...[{'typeId': formProductCatalogCustomCharacteristicType, 'name': formProductCatalogCustomCharacteristicName, 'id': formProductCatalogCustomCharacteristicExistingId, 'list': dataCustomCharacteristicListItems}]]);

            setFormProductCatalogCustomCharacteristicName('');
            setFormProductCatalogCustomCharacteristicExistingId('');
            setFormProductCatalogCustomCharacteristicExistingName('');
            setFormProductCatalogCustomCharacteristicListName('');
            setDataCustomCharacteristicListItems([]);
        }
    };

    // customCharacteristics delete
    const handleCustomCharacteristicsDelete = (index) =>
    {
        let tmpData = dataCustomCharacteristics;
        tmpData.splice(index, 1);
        setDataCustomCharacteristics([...tmpData, ...[]]);
    };

    // customCharacteristic list add
    const handleCustomCharacteristicListAdd = () =>
    {
        if(formProductCatalogCustomCharacteristicListName !== '')
        {
            setDataCustomCharacteristicListItems([...(dataCustomCharacteristicListItems), ...[{'id': '', 'name': formProductCatalogCustomCharacteristicListName}]]);

            setFormProductCatalogCustomCharacteristicListName('');
        }
    };

    // customCharacteristic list delete
    const handleCustomCharacteristicListDelete = (index) =>
    {
        let tmpData = dataCustomCharacteristicListItems;
        tmpData.splice(index, 1);
        setDataCustomCharacteristicListItems([...tmpData, ...[]]);
    };

    // customCharacteristic Existing Change
    const handleCustomCharacteristicExistingChange = (e) =>
    {
        let id = e.target.value;
        let name = '';

        for(let i = 0; i < dataCustomInput.length; i++)
        {
            name = (dataCustomInput[i].id === id) ? dataCustomInput[i].name : name;
        }

        setFormProductCatalogCustomCharacteristicExistingId(id);
        setFormProductCatalogCustomCharacteristicExistingName(name);
        setFormProductCatalogCustomCharacteristicName(name);
    };

    // form category Change
    const handleFormCategoryChange = (e) =>
    {
        let id = e.target.value;
        let name = '';

        for(let i = 0; i < dataCategory.length; i++)
        {
            name = (dataCategory[i].categoryId === id) ? dataCategory[i].categoryName : name;
        }

        setFormProductCatalogCategoryId(id);
        setFormProductCatalogCategoryName(name);
    };

    // show form
    const handleCategoryFormDialog = (e) =>
    {
        setFormCategoryId('');
        setFormCategoryName('');

        setCategoryFormDialogOpen(!categoryFormDialogOpen);
    };

    // category edit
    const handleCategoryEdit = (index) =>
    {
        setFormCategoryId(data[index].categoryId);
        setFormCategoryName(data[index].categoryName);

        setCategoryFormDialogOpen(true);
    };

    // category save
    const saveCategory = () =>
    {
        if(formCategoryName !== "") // check data is correct
        {
            setFormCategoryLoading(true); // hide btn & show loading

            // send data
            const params = {'categoryId': formCategoryId, 'categoryName': formCategoryName};
            CategoryApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Category Saved!", 'success'); // show success

                        loadData();
                        loadDataCategory();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormCategoryLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormCategoryLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // delete category
    const handleDeleteCategory = () =>
    {
        setFormCategoryLoading(true); // hide btn & show loading

        // send data
        const params =
            {
                'categoryId': formCategoryId,
            };
        CategoryApi.delete(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Category Deleted!", 'success'); // show success

                    loadData();
                    loadDataCategory();
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormCategoryLoading(false); // show btn & hide loading
                setCategoryFormDialogOpen(false);
            }
        ).catch
        (
            function(error)
            {
                setFormCategoryLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-product-catalog">
            <div className="title-bar-breadcrumbs-btn">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/settings">Settings</Link>
                        <Typography className="current-page" key="3" color="text.primary">Product Catalog</Typography>,
                    </Breadcrumbs>
                </div>

                <Button onClick={handleProductCatalogFormDialog} className="btn-blue" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Product</Button>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                data.map
                                (
                                    (item, index) =>
                                    {
                                        return (
                                            <div className="category-box" key={'cb' + index}>
                                                <div className="category-title-bar">
                                                    <div>
                                                        <span className="title">{item.categoryName}</span>
                                                        <Button className="btn-edit" onClick={() => handleCategoryEdit(index)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                                                    </div>
                                                    <IconButton className="btn-close-open" onClick={() => handleProductsShowHide(index)}>{!item.secShow ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}</IconButton>
                                                </div>

                                                <div className="products-box">
                                                    {
                                                        !item.secShow && item.products !== null ?
                                                            item.products.map
                                                            (
                                                                (item2, index2) =>
                                                                {
                                                                    return (
                                                                        <div className="product-box" onClick={() => handleProductCatalogEdit(index, index2)} key={'cb' + index + '-pb' + index2}>
                                                                            <div className="title">{item2.productName}</div>
                                                                            <span className="initial">{item2.initials}</span>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                )
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* productCatalog form */}
            <Dialog open={productCatalogFormDialogOpen} onClose={handleProductCatalogFormDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formProductCatalogId === '' ? 'Add' : 'Edit'} Products</span>
                        <IconButton className="dialog-close" onClick={handleProductCatalogFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2 form-product-catalog-box">
                    {/* section Choose Product Category */}
                    <div className="section-box">
                        <div className={formProductCatalogSectionShowHide[1] === 2 ? "section-title-bar done" : "section-title-bar"}>
                            <div>
                                <span className="title">1. Choose Product Category</span>
                                <Button className="btn-edit" onClick={() => handleFormProductCatalogSectionShowHide(1, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                            </div>
                            <IconButton className="btn-close-open" onClick={() => handleFormProductCatalogSectionShowHide(1, 0)}>{formProductCatalogSectionShowHide[1] === 1 ? <ArrowDropUpOutlinedIcon /> : (formProductCatalogSectionShowHide[1] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                        </div>
                        {
                            formProductCatalogSectionShowHide[1] === 1 ?
                                <div className="section-content">
                                    <Container className="form-container">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-product-catalog-category">Product Category</InputLabel>
                                                <Select className="form-select" id="form-product-catalog-category" value={formProductCatalogCategoryId} onChange={handleFormCategoryChange}>
                                                    <MenuItem value="">&nbsp;</MenuItem>
                                                    {
                                                        dataCategory !== null ?
                                                            dataCategory.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <MenuItem key={'mi' + index} value={item.categoryId}>{item.categoryName}</MenuItem>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Select>
                                            </Grid>

                                            <div className='form-divider'></div>

                                            <Grid item xs={12} sm={6}>
                                                <InputLabel shrink className="form-label" htmlFor="form-product-catalog-model-name">Model Name</InputLabel>
                                                <TextField className="form-input" id="form-product-catalog-model-name" placeholder="" value={formProductCatalogModelName} onChange={(e) => setFormProductCatalogModelName(e.target.value)} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button className="btn-blue btn-blue-outline" onClick={() => handleFormProductCatalogSectionShowHide(2, 2)} fullWidth={false} variant="contained">Continue</Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                                :
                                null
                        }
                    </div>

                    {/* section Customize Product */}
                    <div className="section-box">
                        <div className={formProductCatalogSectionShowHide[2] === 2 ? "section-title-bar done" : "section-title-bar"}>
                            <div>
                                <span className="title">2. Customize Product</span>
                                <Button className="btn-edit" onClick={() => handleFormProductCatalogSectionShowHide(2, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                            </div>
                            <IconButton className="btn-close-open" onClick={() => handleFormProductCatalogSectionShowHide(2, 0)}>{formProductCatalogSectionShowHide[2] === 1 ? <ArrowDropUpOutlinedIcon /> : (formProductCatalogSectionShowHide[2] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                        </div>
                        {
                            formProductCatalogSectionShowHide[2] === 1 ?
                                <div className="section-content">
                                    <Container className="form-container">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Alert severity="error" className="alert-gray">All JOBS/products already have Stock Quantity / Notes / and Production Notes Fields. Add any Custom Characteristics here:</Alert>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <InputLabel shrink className="form-label form-label-t2" htmlFor="form-product-catalog-work-flow-selected">Custom Characterisitcs</InputLabel>
                                                <div className="custom-characteristic-form-box">
                                                    <div>
                                                        <InputLabel shrink className="form-label" htmlFor="form-product-catalog-custom-characteristic-type">Type</InputLabel>
                                                        <ToggleButtonGroup className="form-button-group" value={formProductCatalogCustomCharacteristicType} exclusive onChange={(e, v) => handleCustomCharacteristicTypeChange(e, v)}>
                                                            {
                                                                customCharacteristicTabs.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            <ToggleButton key={'tb' + index} value={index}>{item}</ToggleButton>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                        </ToggleButtonGroup>
                                                    </div>

                                                    <div>
                                                        {
                                                            formProductCatalogCustomCharacteristicType === 0 ?
                                                                <Select className="form-select" id="form-product-catalog-characteristic-existing" value={formProductCatalogCustomCharacteristicExistingId} onChange={handleCustomCharacteristicExistingChange}>
                                                                    <MenuItem value="">Select an exisiting characterisitic</MenuItem>
                                                                    {
                                                                        dataCustomInput !== null ?
                                                                            dataCustomInput.map
                                                                            (
                                                                                (item, index) =>
                                                                                {
                                                                                    return (
                                                                                        <MenuItem key={'mi2' + index} value={item.id}>{item.name}</MenuItem>
                                                                                    );
                                                                                }
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                </Select>
                                                                :
                                                                <TextField className="form-input" id="form-product-catalog-custom-characteristic-name" placeholder="Name" value={formProductCatalogCustomCharacteristicName} onChange={(e) => setFormProductCatalogCustomCharacteristicName(e.target.value)} />
                                                        }
                                                    </div>

                                                    <div className={formProductCatalogCustomCharacteristicType === 1 ? 'form-product-catalog-custom-characteristic-list-name-box' : 'display-none'}>
                                                        <InputLabel shrink className="form-label" htmlFor="form-product-catalog-custom-characteristic-list-name">Add to list</InputLabel>
                                                        <TextField className="form-input" id="form-product-catalog-custom-characteristic-list-name" value={formProductCatalogCustomCharacteristicListName} onChange={(e) => setFormProductCatalogCustomCharacteristicListName(e.target.value)} />

                                                        <IconButton className="form-product-catalog-custom-characteristic-btn-add-to-list" onClick={() => handleCustomCharacteristicListAdd()}><AddBoxOutlinedIcon /></IconButton>

                                                        {
                                                            dataCustomCharacteristicListItems != null && dataCustomCharacteristicListItems.length > 0 ?
                                                                <div className="characteristic-list-box">
                                                                    {
                                                                        dataCustomCharacteristicListItems.map
                                                                        (
                                                                            (item, index) =>
                                                                            {
                                                                                return (
                                                                                    <div className="characteristic-list-row" key={'cl' + index}>
                                                                                        <span className="name">{item.name}</span>
                                                                                        <IconButton className="icon" onClick={() => handleCustomCharacteristicListDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div>
                                                        <Button onClick={handleCustomCharacteristicsAdd} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add</Button>
                                                    </div>
                                                </div>
                                                {
                                                    dataCustomCharacteristics != null && dataCustomCharacteristics.length > 0 ?
                                                        <div className="characteristics-list-box">
                                                            {
                                                                dataCustomCharacteristics.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            <div className="characteristic-list-row" key={'cl2' + index}>
                                                                                <span className="name">{item.name}</span>
                                                                                <span className="type">{customCharacteristicTabs[item.typeId]}</span>
                                                                                <IconButton className="icon" onClick={() => handleCustomCharacteristicsDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button className="btn-blue btn-blue-outline" onClick={() => handleFormProductCatalogSectionShowHide(3, 2)} fullWidth={false} variant="contained">Continue</Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                                :
                                null
                        }
                    </div>

                    {/* section Define Workflow */}
                    <div className="section-box">
                        <div className={formProductCatalogSectionShowHide[3] === 2 ? "section-title-bar done" : "section-title-bar"}>
                            <div>
                                <span className="title">3. Define Workflow</span>
                                <Button className="btn-edit" onClick={() => handleFormProductCatalogSectionShowHide(3, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                            </div>
                            <IconButton className="btn-close-open" onClick={() => handleFormProductCatalogSectionShowHide(3, 0)}>{formProductCatalogSectionShowHide[3] === 1 ? <ArrowDropUpOutlinedIcon /> : (formProductCatalogSectionShowHide[3] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                        </div>
                        {
                            formProductCatalogSectionShowHide[3] === 1 ?
                                <div className="section-content">
                                    <Container className="form-container">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <InputLabel shrink className="form-label form-label-t2" htmlFor="form-product-catalog-work-flow-items">Add Workflow Items</InputLabel>
                                                <div className="stations-box">
                                                    {
                                                        dataStation !== null ?
                                                            dataStation.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <div key={'si' + index} className="station-item" onClick={() => handleAddTodStationSelected(index)}>{item.stationName}</div>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <InputLabel shrink className="form-label form-label-t2" htmlFor="form-product-catalog-work-flow-selected">Selected Workflow</InputLabel>
                                                <div className="stations-selected-box">
                                                    {
                                                        dataStationSelected !== null ?
                                                            dataStationSelected.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <div className="station-item" key={'si2' + index}>
                                                                            <div className="title">{item.stationName}</div>
                                                                            <span className="abbreviation">{item.abbreviation}</span>
                                                                            <IconButton className="btn-remove" onClick={() => handleRemovedStationSelected(index)}><CloseIcon /></IconButton>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button className="btn-blue btn-blue-outline" onClick={() => handleFormProductCatalogSectionShowHide(4, 2)} fullWidth={false} variant="contained">Continue</Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                                :
                                null
                        }
                    </div>

                    {/* section Review */}
                    <div className="section-box">
                        <div className={formProductCatalogSectionShowHide[4] === 2 ? "section-title-bar done" : "section-title-bar"}>
                            <div>
                                <span className="title">4. Review</span>
                                <Button className="btn-edit" onClick={() => handleFormProductCatalogSectionShowHide(4, 0)} fullWidth={false} variant="contained" startIcon={<BorderColorIcon />}>Edit</Button>
                            </div>
                            <IconButton className="btn-close-open" onClick={() => handleFormProductCatalogSectionShowHide(4, 0)}>{formProductCatalogSectionShowHide[4] === 1 ? <ArrowDropUpOutlinedIcon /> : (formProductCatalogSectionShowHide[4] === 0 ? <ArrowDropDownOutlinedIcon /> : <CheckIcon className="icon-check" />)}</IconButton>
                        </div>
                        {
                            formProductCatalogSectionShowHide[4] === 1 ?
                                <div className="section-content">
                                    <Container className="form-container">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <InputLabel shrink className="form-label form-label-t2" htmlFor="form-product-catalog-work-flow-items">Product TYpe</InputLabel>
                                                <div className="review-item">{formProductCatalogCategoryName}</div>
                                                <div className="review-item">{formProductCatalogModelName}</div>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <InputLabel shrink className="form-label form-label-t2" htmlFor="form-product-catalog-work-flow-items">Custmoization</InputLabel>
                                                {
                                                    dataCustomCharacteristics !== null ?
                                                        dataCustomCharacteristics.map
                                                        (
                                                            (item, index) =>
                                                            {
                                                                return (
                                                                    <div key={'ri' + index} className="review-item">{item.name}</div>
                                                                );
                                                            }
                                                        )
                                                        :
                                                        null
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <InputLabel shrink className="form-label form-label-t2" htmlFor="form-product-catalog-work-flow-items">Workflow</InputLabel>
                                                {
                                                    dataStationSelected !== null ?
                                                        dataStationSelected.map
                                                        (
                                                            (item, index) =>
                                                            {
                                                                return (
                                                                    <div key={'ri2' + index} className="review-item">{item.stationName}</div>
                                                                );
                                                            }
                                                        )
                                                        :
                                                        null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </div>
                                :
                                null
                        }
                    </div>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveProductCatalog} className="dialog-btn-save">Save Product</Button>
                                <Button onClick={handleProductCatalogFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>

            {/* category form */}
            <Dialog open={categoryFormDialogOpen} onClose={handleCategoryFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formCategoryId === '' ? 'Add' : 'Edit'} Product Category</span>
                        <IconButton className="dialog-close" onClick={handleCategoryFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-category-name">Product Category Name</InputLabel>
                        <TextField className="form-input" id="form-category-name" placeholder="Foam Mattress" value={formCategoryName} onChange={(e) => setFormCategoryName(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions dialog-actions-2">
                    {
                        !formCategoryLoading ?
                            <>
                                <div className="">
                                    <Button onClick={saveCategory} className="dialog-btn-save">Save Category</Button>
                                    <Button onClick={handleCategoryFormDialog} className="dialog-btn-cancel">Cancel</Button>
                                </div>
                                {
                                    formCategoryId !== '' ?
                                        <Button onClick={handleDeleteCategory} className="dialog-btn-delete">Delete</Button>
                                        :
                                        ''
                                }
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default ProductCatalog;
