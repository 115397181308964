import React, {useRef, useState} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import AlertAction from "../../components/AlertAction";
import UserApi from "../../services/UserApi";
import Loading from "../../components/Loading/Loading";
import { Grid, Container, TextField, Button, InputLabel } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link } from "react-router-dom";
import imageLogo from '../../images/logo.png';
import imageBG from '../../images/image01.png';
import "./Register.css";

//--------------------------------------------------

function Register()
{
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRep, setPasswordRep] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordRepType, setPasswordRepType] = useState("password");

    const [formLoading, setFormLoading] = useState(false);
    const alertAction = useRef(); // init alert

    // send register data to server
    const register = () =>
    {
        if(name !== "" && email !== "" && companyName !== "" && phoneNumber !== "" && password !== "" && passwordRep !== "" && password === passwordRep) // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'firstName': name, 'lastName': name, 'username': email, 'companyName': companyName, 'phone': phoneNumber, 'password': password};
            UserApi.register(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Resister successfully", "success"); // show success

                        setName("");
                        setEmail("");
                        setCompanyName("");
                        setPhoneNumber("");
                        setPassword("");
                        setPasswordRep("");
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // page content
    return (
        <div className="page-register">
            <Container className="form-box">
                <Grid container justifyContent="center">
                    <Grid item xs={12} className="logo-box"><img src={imageLogo} alt="logo" /></Grid>

                    <Grid item sm={10} xs={12} className="form-register">
                        <Grid container>
                            <Grid item sm={5} className="bg-box"><img src={imageBG} alt="" /></Grid>

                            <Grid item sm={7} xs={12} className="form-content">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <h2 className="form-title">Create account</h2>
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-register-name">Name</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-register-name" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-register-email">Email</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-register-email" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-register-company-name">Company Name</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-register-company-name" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-register-phone-number">Business Phone Number</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-register-phone-number" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-register-password">Password</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-register-password" placeholder="Password" type={passwordType} autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        {passwordType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('password')} />}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-register-password-rep">Confirm Password</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-register-password-rep" placeholder="Password" type={passwordRepType} autoComplete="current-password" value={passwordRep} onChange={(e) => setPasswordRep(e.target.value)} />
                                        {passwordRepType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordRepType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordRepType('password')} />}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {!formLoading ? <Button onClick={register} className="form-btn" fullWidth={true} variant="contained" endIcon={<ArrowForwardIcon />}>Register</Button> : <Loading />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="register-des">Already have account! <Link to="/login" className="link-to-login">Log In</Link></Grid>
                </Grid>
            </Container>

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Register;
