import {Scrollbars} from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Button, Grid, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Loading from "../../components/Loading/Loading";
import AlertAction from "../../components/AlertAction";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CustomCharacteristicApi from "../../services/CustomCharacteristicApi";
import Checkbox from '@mui/material/Checkbox';
import "./CustomCharacteristic.css";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

//--------------------------------------------------

function CustomCharacteristic(props)
{
    //
    const [formLoading, setFormLoading] = useState(false);

    const [dataCustomCharacteristic, setDataCustomCharacteristic] = useState([]);
    const [dataCustomCharacteristicLoading, setDataCustomCharacteristicLoading] = useState(true);
    const [customCharacteristicFormDialogOpen, setCustomCharacteristicFormDialogOpen] = useState(false);
    const [formCustomCharacteristicLoading, setFormCustomCharacteristicLoading] = useState(false);
    const [formCustomCharacteristicId, setFormCustomCharacteristicId] = useState('');
    const [formCustomCharacteristicName, setFormCustomCharacteristicName] = useState('');
    const [formCustomCharacteristicType, setFormCustomCharacteristicType] = useState('');
    const [formCustomCharacteristicListName, setFormCustomCharacteristicListName] = useState('');
    const [dataCustomCharacteristicListItems, setDataCustomCharacteristicListItems] = useState([]);

    const [dataJobOption, setDataJobOption] = useState([]);
    const [dataJobOptionLoading, setDataJobOptionLoading] = useState(true);
    const [formJobOptionLoading, setFormJobOptionLoading] = useState(false);

    const alertAction = useRef(); // init alert

    // load data CustomCharacteristic
    const loadDataCustomCharacteristic = () =>
    {
        setDataCustomCharacteristic(null); // set data to null
        setDataCustomCharacteristicLoading(true); // show loading

        // get data
        CustomCharacteristicApi.findCustomCharacteristic().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCustomCharacteristic(response.data.list);
                }

                setDataCustomCharacteristicLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataCustomCharacteristicLoading(false); // hide loading
            }
        );
    };

    // load data JobOption
    const loadDataJobOption = () =>
    {
        setDataJobOption(null); // set data to null
        setDataJobOptionLoading(true); // show loading

        // get data
        CustomCharacteristicApi.findJobOption().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataJobOption(response.data.list);
                }

                setDataJobOptionLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataJobOptionLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadDataCustomCharacteristic(); loadDataJobOption(); }, []);

    // show form CustomCharacteristic
    const handleCustomCharacteristicFormDialog = (e) =>
    {
        resetForm();

        setCustomCharacteristicFormDialogOpen(!customCharacteristicFormDialogOpen);
    };

    // customCharacteristic save
    const saveCustomCharacteristic = () =>
    {
        if(formCustomCharacteristicName !== "" && formCustomCharacteristicType !== "" && (formCustomCharacteristicType !== "1" || (formCustomCharacteristicType === "1" && dataCustomCharacteristicListItems != null))) // check data is correct
        {
            setFormCustomCharacteristicLoading(true); // hide btn & show loading

            // send data
            const params = {'id': formCustomCharacteristicId, 'characteristicName': formCustomCharacteristicName, 'customType': formCustomCharacteristicType, 'customsTemporary': dataCustomCharacteristicListItems};
            CustomCharacteristicApi.saveCustomCharacteristic(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Custom Characteristic Saved!", 'success'); // show success

                        if(formCustomCharacteristicId !== '') setCustomCharacteristicFormDialogOpen(false);
                        resetForm();

                        loadDataCustomCharacteristic();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormCustomCharacteristicLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormCustomCharacteristicLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormCustomCharacteristicId('');
        setFormCustomCharacteristicName('');
        setFormCustomCharacteristicType('');
        setFormCustomCharacteristicListName('');
        setDataCustomCharacteristicListItems([]);
    };

    // customCharacteristic edit
    const handleCustomCharacteristicEdit = (index) =>
    {
        setFormCustomCharacteristicId(dataCustomCharacteristic[index].id);
        setFormCustomCharacteristicName(dataCustomCharacteristic[index].name);
        setFormCustomCharacteristicType(dataCustomCharacteristic[index].typeId.toString());
        setFormCustomCharacteristicListName('');
        setDataCustomCharacteristicListItems(dataCustomCharacteristic[index].items);

        setCustomCharacteristicFormDialogOpen(true);
    };

    // customCharacteristic list add
    const handleCustomCharacteristicListAdd = () =>
    {
        if(formCustomCharacteristicListName !== '')
        {
            setDataCustomCharacteristicListItems([...(dataCustomCharacteristicListItems), ...[formCustomCharacteristicListName]]);

            setFormCustomCharacteristicListName('');
        }
    };

    // customCharacteristic list delete
    const handleCustomCharacteristicListDelete = (index) =>
    {
        let tmpData = dataCustomCharacteristicListItems;
        tmpData.splice(index, 1);
        setDataCustomCharacteristicListItems([...tmpData, ...[]]);
    };

    // customCharacteristic delete
    const handleCustomCharacteristicDelete = (index) =>
    {
        setDataCustomCharacteristicLoading(true); // show loading

        // send data
        const params = {'id': dataCustomCharacteristic[index].id};
        CustomCharacteristicApi.deleteCustomCharacteristic(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Custom Characteristic Deleted!", 'success'); // show success

                    loadDataCustomCharacteristic();
                }
                else
                {
                    setDataCustomCharacteristicLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataCustomCharacteristicLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // CustomCharacteristic required
    const handleCustomCharacteristicChange = (e, index) =>
    {
        let tmpData = dataCustomCharacteristic;
        tmpData[index].isRequired = e.target.checked;
        setDataCustomCharacteristic([...tmpData, ...[]]);
    };

    // CustomCharacteristic save
    const saveCustomCharacteristics = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'data': dataCustomCharacteristic};
        CustomCharacteristicApi.save(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Custom Characteristics Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // jobOption required
    const handleJobOptionChange = (e, index) =>
    {
        let tmpData = dataJobOption;
        tmpData[index].isRequired = e.target.checked;
        setDataJobOption([...tmpData, ...[]]);
    };

    // jobOption save
    const saveJobOption = () =>
    {
        setFormJobOptionLoading(true); // hide btn & show loading
        let dollarsOnJob = false;
        let salesPersonOnJob = false;

        //
        for(let i = 0; i < dataJobOption.length; i++)
        {
            if(dataJobOption[i]['key'] === 'dollarsOnJob') dollarsOnJob = dataJobOption[i]['isRequired'];
            else salesPersonOnJob = dataJobOption[i]['isRequired'];
        }

        // send data
        const params = {'dollarsOnJob': dollarsOnJob, 'salesPersonOnJob': salesPersonOnJob};
        CustomCharacteristicApi.saveJobOption(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Job Option Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormJobOptionLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormJobOptionLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <>
            {/* CustomCharacteristic list */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Manage Custom Characteristics</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content dialog-content-custom-characteristic">
                    <Button onClick={handleCustomCharacteristicFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Characterisitic</Button>
                    {
                        dataCustomCharacteristicLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                dataCustomCharacteristic !== null ?
                                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar scrollbar-custom-characteristic">
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th className="w-100">Required</th>
                                                <th>Characterisitc</th>
                                                <th>Type</th>
                                                <th className="w-80"></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataCustomCharacteristic.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td><Checkbox checked={item.isRequired} onChange={(e) => handleCustomCharacteristicChange(e, index)} /></td>
                                                                <td>{item.name}</td>
                                                                <td>{item.typeName}</td>
                                                                <td>
                                                                    {
                                                                        item.id !== '' ?
                                                                            <>
                                                                                <IconButton className="icon-edit" onClick={() => handleCustomCharacteristicEdit(index)}><BorderColorIcon /></IconButton>
                                                                                <IconButton className="icon-delete" onClick={() => handleCustomCharacteristicDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    :
                                    <AlertDataEmpty />
                            )
                    }

                    <div className="dialog-actions">
                        {
                            !formLoading ?
                                <>
                                    {dataCustomCharacteristic !== null ? <Button onClick={saveCustomCharacteristics} className="dialog-btn-save">Save Changes</Button> : null}
                                    <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                                </>
                                :
                                <Loading />
                        }
                    </div>

                    {/* *** */}

                    {
                        dataJobOptionLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                dataJobOption !== null ?
                                    <table className="table-data table-data-job-option">
                                        <thead className="sticky">
                                        <tr>
                                            <th className="w-100">Required</th>
                                            <th>Name</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            dataJobOption.map
                                            (
                                                (item, index) =>
                                                {
                                                    return (
                                                        <tr>
                                                            <td><Checkbox checked={item.isRequired} onChange={(e) => handleJobOptionChange(e, index)} /></td>
                                                            <td>{item.name}</td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    :
                                    <AlertDataEmpty />
                            )
                    }

                    <div className="dialog-actions">
                        {
                            !formJobOptionLoading ?
                                <>
                                    {dataJobOption !== null ? <Button onClick={saveJobOption} className="dialog-btn-save">Save Changes</Button> : null}
                                    <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                                </>
                                :
                                <Loading />
                        }
                    </div>
                </DialogContent>
            </Dialog>


            {/* CustomCharacteristic form */}
            <Dialog open={customCharacteristicFormDialogOpen} onClose={handleCustomCharacteristicFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formCustomCharacteristicId === '' ? 'Add' : 'Edit'} Custom Characteristic</span>
                        <IconButton className="dialog-close" onClick={handleCustomCharacteristicFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-custom-characteristic-name">Characteristic Name</InputLabel>
                        <TextField className="form-input" id="form-custom-characteristic-name" value={formCustomCharacteristicName} onChange={(e) => setFormCustomCharacteristicName(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-custom-characteristic-type">Type</InputLabel>
                        <ToggleButtonGroup className="form-button-group" value={formCustomCharacteristicType} exclusive onChange={(e, v) => { setFormCustomCharacteristicType(v); setDataCustomCharacteristicListItems([]); }}>
                            <ToggleButton value="1">List</ToggleButton>
                            <ToggleButton value="2">Number</ToggleButton>
                            <ToggleButton value="3">Text</ToggleButton>
                            <ToggleButton value="4">Yes/No</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} className={formCustomCharacteristicType === '1' ? 'form-custom-characteristic-list-name-box' : 'display-none'}>
                        <InputLabel shrink className="form-label" htmlFor="form-custom-characteristic-list-name">Add to list</InputLabel>
                        <TextField className="form-input" id="form-custom-characteristic-list-name" value={formCustomCharacteristicListName} onChange={(e) => setFormCustomCharacteristicListName(e.target.value)} />

                        <IconButton className="form-custom-characteristic-btn-add-to-list" onClick={() => handleCustomCharacteristicListAdd()}><AddBoxOutlinedIcon /></IconButton>

                        <div className="characteristic-list-box">
                            {
                                dataCustomCharacteristicListItems != null ?
                                    dataCustomCharacteristicListItems.map
                                    (
                                        (item, index) =>
                                        {
                                            return (
                                                <div className="characteristic-list-row">
                                                    <span className="name">{item}</span>
                                                    <IconButton className="icon" onClick={() => handleCustomCharacteristicListDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                </div>
                                            );
                                        }
                                    )
                                    :
                                    null
                            }
                        </div>
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formCustomCharacteristicLoading ?
                            <>
                                <Button onClick={saveCustomCharacteristic} className="dialog-btn-save">Save Changes</Button>
                                <Button onClick={handleCustomCharacteristicFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default CustomCharacteristic;
