import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination";
import {Button, Grid} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import OrdersApi from "../../services/OrderApi";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import General from "../../utils/General";
import "./Orders.css";
import Quote from "../../components/Quote/Quote";
import SortTable from "../../components/SortTable/SortTable";

//--------------------------------------------------

function Orders(props)
{
    // get new param
    let { statusId } = useParams();

    // set page title & page path
    const pageTitle = 'Orders';
    usePage().pageData({title: Menus.orders.title, activeMenu: 'orders'});

    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [filterStatus, setFilterStatus] = useState(statusId > 0 ? statusId.toString() : '');
    const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);

    const [sortData, setSortData] = useState({'dueDate': 'desc'});
    const [sortColName, setSortColName] = useState('dueDate');
    const [sortType, setSortType] = useState('desc');

    const navigate = useNavigate();

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        OrdersApi.find({ pageNumber: pageNumber, status: filterStatus, sortColName: sortColName, sortType: sortType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, [filterStatus, sortColName, sortType]);

    //
    const handleStatusChange = (e) =>
    {
        setFilterStatus(e.target.value);
    };

    //
    const goToOrder = (id) =>
    {
        navigate("/order/" + id, { replace: false });
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-orders">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>

                <Button onClick={() => setQuoteDialogOpen(true)} className="title-bar-btn" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Create new Quote</Button>

                <div className="label-box">
                    <span className="label-title"><label className="label-job complete"></label>Compeleted Jobs</span>
                    <span className="label-title"><label className="label-job active"></label>Active Jobs</span>
                    <span className="label-title"><label className="label-job overdue"></label>Overdue Jobs</span>
                    <span className="label-title"><label className="label-job paused"></label>Paused Jobs</span>
                    <span className="label-title"><label className="label-task open"></label>Open Tasks</span>
                    <span className="label-title"><label className="label-task mine"></label>My Tasks</span>
                </div>
            </div>

            <div className="filter-bar">
                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-status-label">Status</InputLabel>
                    <Select labelId="filter-status-label" value={filterStatus} label="Status" onChange={handleStatusChange}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value="3">Pending</MenuItem>
                        <MenuItem value="4">Paused</MenuItem>
                        <MenuItem value="8">Active</MenuItem>
                        <MenuItem value="5">Jobs Done</MenuItem>
                        <MenuItem value="9">Delivery / Pickup</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('order')} className="w-100 center"><span className="col-value"><SortTable sortData={sortData} colName="order" />NO.</span></th>
                                        <th onClick={() => changeSortTable('orderName')}><span className="col-value"><SortTable sortData={sortData} colName="orderName" />Quote name</span></th>
                                        <th onClick={() => changeSortTable('customerName')}><span className="col-value"><SortTable sortData={sortData} colName="customerName" />Client</span></th>
                                        <th onClick={() => changeSortTable('status')} className="w-160"><span className="col-value"><SortTable sortData={sortData} colName="status" />Status</span></th>
                                        <th><span className="col-value">JObs</span></th>
                                        <th className="w-80"><span className="col-value">Tasks</span></th>
                                        <th onClick={() => changeSortTable('dueDate')} className="w-80"><span className="col-value"><SortTable sortData={sortData} colName="dueDate" />Date</span></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item) =>
                                            {
                                                return (
                                                    <tr onClick={(e) => goToOrder(item.quoteId)}>
                                                        <td className="center">{item.order}</td>
                                                        <td>{item.orderName}</td>
                                                        <td>{item.customerName}</td>
                                                        <td><label className={'label-status ' + General.initStatusClass(item.status)}>{item.status}</label></td>
                                                        <td>
                                                            {item.jobs["complete"] > 0 ? <label className="label-job complete">{item.jobs["complete"]}</label> : null}
                                                            {item.jobs["active"] > 0 ? <label className="label-job active">{item.jobs["active"]}</label> : null}
                                                            {item.jobs["paused"] > 0 ? <label className="label-job paused">{item.jobs["paused"]}</label> : null}
                                                            {item.jobs["overdue"] > 0 ? <label className="label-job overdue">{item.jobs["overdue"]}</label> : null}
                                                        </td>
                                                        <td>
                                                            {item.tasks["mine"] > 0 ? <label className="label-task mine">{item.tasks["mine"]}</label> : null}
                                                            {item.tasks["open"] > 0 ? <label className="label-task open">{item.tasks["open"]}</label> : null}
                                                        </td>
                                                        <td className={item.isDueDateRequired ? 'td-highlight' : ''}>{item.dueDate}</td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="7"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>

            {/* quote */}
            {quoteDialogOpen ? <Quote handleDialog={() => setQuoteDialogOpen(false)} closeAfterSave={true} loadData={() => { loadData(); }} /> : null}
        </div>
    );
}

export default Orders;
