import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import DashboardApi from "../../services/DashboardApi";
import React, {useEffect, useState} from "react";
import Loading from "../../components/Loading/Loading";
import SyncAltIcon from "@mui/material/SvgIcon/SvgIcon";
import Pagination from "../../components/Pagination/Pagination";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {Button, Container, Grid} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import "./Dashboard.css";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import imageStationArrowIcon from '../../images/station-arrow-icon.png';
import General from "../../utils/General";

//--------------------------------------------------

function Dashboard(props)
{
    // set page title & page path
    const pageTitle = 'Dashboard';
    usePage().pageData({title: Menus.dashboard.title, activeMenu: 'dashboard'});

    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    const navigate = useNavigate();

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        DashboardApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(1); }, []);

    // go to new page
    const goToPage = (pageUrl) =>
    {
        navigate(pageUrl, { replace: false });
    };

    //
    return (
        <div className="page-content page-dashboard">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <Container className="dashboard-section-container">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <div className="dashboard-section-box">
                                                <div className="title">
                                                    Workflow
                                                    {/*<Button onClick={(e) => {}} className="btn-edit" fullWidth={false} variant="contained" endIcon={<BorderColorIcon />}>Edit</Button>*/}
                                                </div>

                                                <div className="content workflow-content">
                                                    {
                                                        data.stationJobs != null ?
                                                            // Object.entries(data.stationJobs).map
                                                            data.stationJobs.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <div className="workflow-station-box">
                                                                            <div className="station-title-number-box" onClick={() => goToPage('/jobs/station-' + item.id)}>
                                                                                <div className="title-station-number">
                                                                                    <div className="title">{item.name}</div>
                                                                                    <div className="station-number">S{index}</div>
                                                                                </div>
                                                                                <div className="job-number">{item.count} Jobs</div>
                                                                            </div>
                                                                            <img src={imageStationArrowIcon} className="icon" />
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                    {/*
                                                    <div className="workflow-station-box">
                                                        <div className="station-title-number-box">
                                                            <div className="title-station-number">
                                                                <div className="title">Station 1</div>
                                                                <div className="station-number">S1</div>
                                                            </div>
                                                            <div className="job-number">20 Jobs</div>
                                                        </div>
                                                        <img src={imageStationArrowIcon} className="icon" />
                                                    </div>
                                                    <div className="workflow-station-box">
                                                        <div className="station-title-number-box">
                                                            <div className="title-station-number">
                                                                <div className="title">Station 1</div>
                                                                <div className="station-number">S1</div>
                                                            </div>
                                                            <div className="job-number">20 Jobs</div>
                                                        </div>
                                                        <img src={imageStationArrowIcon} className="icon" />
                                                    </div>
                                                    <div className="workflow-station-box">
                                                        <div className="station-title-number-box">
                                                            <div className="title-station-number">
                                                                <div className="title">Station 1</div>
                                                                <div className="station-number">S1</div>
                                                            </div>
                                                            <div className="job-number">20 Jobs</div>
                                                        </div>
                                                        <img src={imageStationArrowIcon} className="icon" />
                                                    </div>
                                                    <div className="workflow-station-box">
                                                        <div className="station-title-number-box">
                                                            <div className="title-station-number">
                                                                <div className="title">Station 1</div>
                                                                <div className="station-number">S1</div>
                                                            </div>
                                                            <div className="job-number">20 Jobs</div>
                                                        </div>
                                                        <img src={imageStationArrowIcon} className="icon" />
                                                    </div>
                                                    <div className="workflow-station-box">
                                                        <div className="station-title-number-box">
                                                            <div className="title-station-number">
                                                                <div className="title">Station 1</div>
                                                                <div className="station-number">S1</div>
                                                            </div>
                                                            <div className="job-number">20 Jobs</div>
                                                        </div>
                                                        <img src={imageStationArrowIcon} className="icon" />
                                                    </div>
                                                    */}
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item lg={3} md={4} sm={12} xs={12}>
                                            <div className="dashboard-section-box">
                                                <div className="title">Quote Status</div>

                                                <div className="content">
                                                    {
                                                        data.quotesByStatus != null ?
                                                            data.quotesByStatus.map
                                                            (
                                                                (item) =>
                                                                {
                                                                    return (
                                                                        <span className="label-status-box" onClick={() => goToPage('/quotes/status-' + item.id)}><span className={"label-status " + General.initStatusClass(item.name)}>{item.count}</span>{item.name}</span>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                    {/*<span className="label-status-box"><span className="label-status active"></span>Active</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status pending">5</span>Pending</span>*/}
                                                </div>

                                                <div className="link-box">
                                                    <Link to="/quotes/new" className="btn-new"><AddBoxOutlinedIcon className="icon" />New Quote</Link>
                                                    {/*<Button onClick={(e) => {}} className="btn-new" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>New Quote</Button>*/}
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item lg={9} md={8} sm={12} xs={12}>
                                            <div className="dashboard-section-box">
                                                <div className="title">Order Status</div>

                                                <div className="content">
                                                    {
                                                        data.ordersByStatus != null ?
                                                            data.ordersByStatus.map
                                                            (
                                                                (item) =>
                                                                {
                                                                    return (
                                                                        <span className="label-status-box" onClick={() => goToPage('/orders/status-' + item.id)}><span className={"label-status " + General.initStatusClass(item.name)}>{item.count}</span>{item.name}</span>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                    {/*<span className="label-status-box"><span className="label-status overdue">5</span>Overdue</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status active">5</span>Active</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status pending">5</span>Pending</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status pickup-or-delivery">5</span>Pickup / Delivery</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status completed">5</span>Completed</span>*/}
                                                </div>

                                                <div className="link-box">
                                                    <Link to="/orders" className="link-to-view-all">View All</Link>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <div className="dashboard-section-box">
                                                <div className="title">Job Status</div>

                                                <div className="content">
                                                    {
                                                        data.jobsByStatus != null ?
                                                            data.jobsByStatus.map
                                                            (
                                                                (item) =>
                                                                {
                                                                    return (
                                                                        <span className="label-status-box" onClick={() => goToPage('/jobs/status-' + item.id)}><span className={"label-status " + General.initStatusClass(item.name)}>{item.count}</span>{item.name}</span>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                    {/*<span className="label-status-box"><span className="label-status overdue">5</span>Overdue</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status active">5</span>Active</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status paused">5</span>Paused</span>*/}
                                                    {/*<span className="label-status-box"><span className="label-status assgined">5</span>Assgined</span>*/}
                                                </div>

                                                <div className="link-box">
                                                    <Link to="/jobs" className="link-to-view-all">View All</Link>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>
        </div>
    );
}

export default Dashboard;
