import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading/Loading";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Pagination from "../../components/Pagination/Pagination";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {Button, Container, Grid, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import UserApi from "../../services/UserApi";
import InvoiceApi from "../../services/InvoiceApi";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import iconVisa from "../../images/icon-visa.png";

import "./Profile.css";
import SortTable from "../../components/SortTable/SortTable";

//--------------------------------------------------

function Profile(props)
{
    // set page title & page path
    const pageTitle = 'My Profile';
    usePage().pageData({title: Menus.dashboard.title, activeMenu: 'profile'});

    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    const [dataUser, setDataUser] = useState([]);
    const [dataUserLoading, setDataUserLoading] = useState(true);
    const [paginationDataUser, setPaginationDataUser] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [userFormDialogOpen, setUserFormDialogOpen] = useState(false);
    const [formUserLoading, setFormUserLoading] = useState(false);
    const [formUserId, setFormUserId] = useState('');
    const [formUserFirstName, setFormUserFirstName] = useState('');
    const [formUserLastName, setFormUserLastName] = useState('');
    const [formUserEmail, setFormUserEmail] = useState('');
    const [formUserUserType, setFormUserUserType] = useState('');
    const [formUserLanguage, setFormUserLanguage] = useState('');
    const [formUserPhone, setFormUserPhone] = useState('');
    const [formUserExt, setFormUserExt] = useState('');
    const [formUserUserName, setFormUserUserName] = useState('');
    const [formUserPassword, setFormUserPassword] = useState('');
    const [formUserPasswordRep, setFormUserPasswordRep] = useState('');

    const [dataInvoice, setDataInvoice] = useState([]);
    const [dataInvoiceLoading, setDataInvoiceLoading] = useState(true);
    const [paginationDataInvoice, setPaginationDataInvoice] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortDataInvoice, setSortDataInvoice] = useState({'': ''});
    const [sortColNameInvoice, setSortColNameInvoice] = useState('');
    const [sortTypeInvoice, setSortTypeInvoice] = useState('');

    const [sortDataUser, setSortDataUser] = useState({'': ''});
    const [sortColNameUser, setSortColNameUser] = useState('');
    const [sortTypeUser, setSortTypeUser] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        // setData(null); // set data to null
        // setDataLoading(true); // show loading
        //
        // // get data
        // UserApi.detail().then
        // (
        //     function(response)
        //     {
        //         if (response.status === 200)
        //         {
        //             setData(response.data.list);
        //         }
        //
        //         setDataLoading(false); // hide loading
        //     }
        // ).catch
        // (
        //     function(error)
        //     {
        //         setDataLoading(false); // hide loading
        //     }
        // );
    };

    // useEffect(() => { loadData(); }, []);
    useEffect(() => { loadDataInvoice(); }, [sortColNameInvoice, sortTypeInvoice]);
    useEffect(() => { loadDataUser(); }, [sortColNameUser, sortTypeUser]);

    // load data user
    const loadDataUser = (pageNumber = 1) =>
    {
        setDataUser(null); // set data to null
        setDataUserLoading(true); // show loading

        // get data
        UserApi.find({ pageNumber: pageNumber, sortColName: sortColNameUser, sortType: sortTypeUser }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationDataUser({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setDataUser(response.data.list);
                }

                setDataUserLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataUserLoading(false); // hide loading
            }
        );
    };

    // show form
    const handleUserFormDialog = (e) =>
    {
        resetFormUser();

        setUserFormDialogOpen(!userFormDialogOpen);
    };

    // user save
    const saveUser = () =>
    {
        if(formUserFirstName !== "" && formUserLastName !== "" && formUserUserType !== "" && formUserEmail !== "" && formUserLanguage !== "" && formUserPhone !== "" /*&& formUserExt !== ""*/ && formUserUserName !== "" && ((formUserPassword !== "" && formUserPasswordRep !== "" && formUserPassword === formUserPasswordRep) || (formUserId !== "" && formUserPassword === "" && formUserPasswordRep === ""))) // check data is correct
        {
            setFormUserLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'userId': formUserId,
                    'firstName': formUserFirstName,
                    'lastName': formUserLastName,
                    'email': formUserEmail,
                    'type': formUserUserType,
                    'language': formUserLanguage,
                    'phone': formUserPhone,
                    'extension': formUserExt,
                    'username': formUserUserName,
                    'password': formUserPassword,
                };
            UserApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("User Saved!", 'success'); // show success

                        if(formUserId !== '') setUserFormDialogOpen(false);
                        resetFormUser();

                        loadDataUser();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormUserLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormUserLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetFormUser = () =>
    {
        setFormUserId('');
        setFormUserFirstName('');
        setFormUserLastName('');
        setFormUserEmail('');
        setFormUserUserType('');
        setFormUserLanguage('');
        setFormUserPhone('');
        setFormUserExt('');
        setFormUserUserName('');
        setFormUserPassword('');
        setFormUserPasswordRep('');
    };

    // user edit
    const handleUserEdit = (index) =>
    {

        setFormUserId(dataUser[index].userId);
        setFormUserFirstName(dataUser[index].firstName);
        setFormUserLastName(dataUser[index].lastName);
        setFormUserEmail(dataUser[index].email);
        setFormUserUserType(dataUser[index].userTypeId);
        setFormUserLanguage(dataUser[index].language);
        setFormUserPhone(dataUser[index].phone);
        setFormUserExt(dataUser[index].extension);
        setFormUserUserName(dataUser[index].username);

        setUserFormDialogOpen(true);
    };

    // user delete
    const handleUserDelete = (index) =>
    {
        setDataUserLoading(true); // show loading

        // send data
        const params = {'userId': dataUser[index].userId};
        UserApi.delete(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("User Deleted!", 'success'); // show success

                    loadDataUser();
                }
                else
                {
                    setDataUserLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataUserLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // load data
    const loadDataInvoice = (pageNumber = 1) =>
    {
        setDataInvoice(null); // set data to null
        setDataInvoiceLoading(true); // show loading

        // get data
        InvoiceApi.findHistory({ pageNumber: pageNumber, sortColName: sortColNameInvoice, sortType: sortTypeInvoice }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationDataInvoice({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setDataInvoice(response.data.list);
                }

                setDataInvoiceLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataInvoiceLoading(false); // hide loading
            }
        );
    };

    //
    const changeSortTableInvoice = (colName) =>
    {
        let lastSortType = sortDataInvoice[colName];
        let sortType = (lastSortType === 'asc') ? 'desc' : 'asc';

        let sd =  {};
        sd['lastSortType'] = lastSortType;
        sd[colName] = lastSortType !== 'desc' ? sortType : '';

        setSortColNameInvoice(lastSortType !== 'desc' ? colName : '');
        setSortTypeInvoice(lastSortType !== 'desc' ? sortType : '');
        setSortDataInvoice(sd);
    };

    //
    const changeSortTableUser = (colName) =>
    {
        let lastSortType = sortDataUser[colName];
        let sortType = (lastSortType === 'asc') ? 'desc' : 'asc';

        let sd =  {};
        sd['lastSortType'] = lastSortType;
        sd[colName] = lastSortType !== 'desc' ? sortType : '';

        setSortColNameUser(lastSortType !== 'desc' ? colName : '');
        setSortTypeUser(lastSortType !== 'desc' ? sortType : '');
        setSortDataUser(sd);
    };

    //
    return (
        <div className="page-content page-profile">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {/*
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <Container className="profile-section-container profile-section-user-plan">
                                    <Grid container spacing={4}>
                                        <Grid item lg={3} md={4} sm={12} xs={12}>
                                            <div className="profile-section-box">
                                                <div className="title">Current Plan</div>

                                                <div className="user-plan-status"><span className="label-status active">Active</span></div>

                                                <div className="user-plan-level-type-link">
                                                    <div className="user-plan-level-type">
                                                        <div className="user-plan-level">Intro</div>
                                                        <div className="user-plan-type">Free</div>
                                                    </div>
                                                    <div className="user-plan-link">
                                                        <Link to="" className="link">Change Plan</Link>
                                                    </div>
                                                </div>

                                                <div className="user-plan-des">Your plan renews on March 15, 2022 using Visa ****0222</div>


                                            </div>
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <div className="profile-section-box">
                                                <div className="title">Payment Method</div>
                                                <div className="visa-card-data">
                                                    <div className="card-number"><img src={iconVisa} className="" />Visa **** 0222</div>
                                                    <div className="expire">Expires 03/2022</div>
                                                </div>
                                                <div className="btn-link-bar">
                                                    <Button disabled onClick={handleUserFormDialog} className="btn-blue" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add payment Method</Button>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item lg={5} md={4} sm={12} xs={12}>
                                            <div className="profile-section-box">
                                                <div className="title">Upgrade Account</div>
                                                <div className="upgrade-title">🚀 Upgrade to Pro</div>
                                                <div className="upgrade-des">Love using our product? Need more users?</div>
                                                <div className="btn-link-bar">
                                                    <Button disabled onClick={handleUserFormDialog} className="btn-blue" fullWidth={false} variant="contained">Upgrade Plan</Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <AlertDataEmpty />
                        )
                */}

                {/* invoice -------------------------------------------------- */}

                <div className="section-divider">
                    <span className="section-title">Invoice History</span>
                </div>

                <div className="scrollbar-box scrollbar-invoice-box" style={(dataInvoice !== null && (126 + (dataInvoice.length * 53) < 500)) ? {height: (126 + (dataInvoice.length * 53)) + 'px'} : {height: '100%'}}>
                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                        {
                            dataInvoiceLoading ?
                                (
                                    <Loading />
                                )
                                :
                                (
                                    dataInvoice !== null ?
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th onClick={() => changeSortTableInvoice('invoiceNumber')}><span className="col-value"><SortTable sortData={sortDataInvoice} colName="invoiceNumber" />Invoice Number</span></th>
                                                <th onClick={() => changeSortTableInvoice('date')}><span className="col-value"><SortTable sortData={sortDataInvoice} colName="date" />Date</span></th>
                                                <th onClick={() => changeSortTableInvoice('plan')}><span className="col-value"><SortTable sortData={sortDataInvoice} colName="plan" />Plan</span></th>
                                                <th onClick={() => changeSortTableInvoice('price')}><span className="col-value"><SortTable sortData={sortDataInvoice} colName="price" />Price</span></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataInvoice.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td>{item.invoiceNumber}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.plan}</td>
                                                                <td>{item.price}</td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>

                                            <tfoot className="sticky">
                                            <tr>
                                                <td colSpan="7"><Pagination data={paginationDataInvoice} loadData={loadDataInvoice} /></td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                        :
                                        <AlertDataEmpty />
                                )
                        }
                    </Scrollbars>
                </div>

                {/* user -------------------------------------------------- */}

                <div className="section-divider">
                    <span className="section-title">Users</span>
                    <Button onClick={handleUserFormDialog} className="btn-blue" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add User</Button>
                </div>

                <div className="scrollbar-box scrollbar-user-box" style={(dataUser !== null && (126 + (dataUser.length * 72) < 500)) ? {height: (126 + (dataUser.length * 72)) + 'px'} : {height: '100%'}}>
                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                        {
                            dataUserLoading ?
                                (
                                    <Loading />
                                )
                                :
                                (
                                    dataUser !== null ?
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th onClick={() => changeSortTableUser('fullName')}><span className="col-value"><SortTable sortData={sortDataUser} colName="fullName" />Full Name</span></th>
                                                <th onClick={() => changeSortTableUser('username')}><span className="col-value"><SortTable sortData={sortDataUser} colName="username" />User Name</span></th>
                                                <th onClick={() => changeSortTableUser('email')}><span className="col-value"><SortTable sortData={sortDataUser} colName="email" />Email</span></th>
                                                <th onClick={() => changeSortTableUser('userType')}><span className="col-value"><SortTable sortData={sortDataUser} colName="userType" />User Type</span></th>
                                                <th onClick={() => changeSortTableUser('lastLogin')}><span className="col-value"><SortTable sortData={sortDataUser} colName="lastLogin" />Last Login</span></th>
                                                <th className="w-80"></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataUser.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td>{item.fullName}</td>
                                                                <td>{item.username}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.userType}</td>
                                                                <td>{item.lastLogin}</td>
                                                                <td>
                                                                    <IconButton className="icon-edit" onClick={() => handleUserEdit(index)}><BorderColorIcon /></IconButton>
                                                                    <IconButton className="icon-delete" onClick={() => handleUserDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>

                                            <tfoot className="sticky">
                                            <tr>
                                                <td colSpan="7"><Pagination data={paginationDataUser} loadData={loadDataUser} /></td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                        :
                                        <AlertDataEmpty />
                                )
                        }
                    </Scrollbars>
                </div>

                {/* user form */}
                <Dialog open={userFormDialogOpen} onClose={handleUserFormDialog} fullWidth={true} maxWidth='sm'>
                    <DialogTitle>
                        <div className="dialog-title">
                            <span className="title">{formUserId === '' ? 'Add' : 'Edit'} User</span>
                            <IconButton className="dialog-close" onClick={handleUserFormDialog}><CloseIcon className="icon" /></IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent className="dialog-content form-box form-box-t2">
                        <Container className="form-container">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-first-name">First Name *</InputLabel>
                                    <TextField className="form-input" id="form-user-first-name" placeholder="" value={formUserFirstName} onChange={(e) => setFormUserFirstName(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-last-name">Last Name *</InputLabel>
                                    <TextField className="form-input" id="form-user-last-name" placeholder="" value={formUserLastName} onChange={(e) => setFormUserLastName(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-email">Email Address *</InputLabel>
                                    <TextField className="form-input" id="form-user-email" placeholder="" value={formUserEmail} onChange={(e) => setFormUserEmail(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-user-type">User Type *</InputLabel>
                                    <Select className="form-select" id="form-user-user-type" value={formUserUserType} onChange={(e) => setFormUserUserType(e.target.value)}>
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        <MenuItem value="1">Owner / Admin</MenuItem>
                                        <MenuItem value="2">Sales</MenuItem>
                                        <MenuItem value="3">Production</MenuItem>
                                        <MenuItem value="4">Production Manager</MenuItem>
                                    </Select>
                                </Grid>

                                <div className="form-divider"></div>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-language">Language *</InputLabel>
                                    <Select className="form-select" id="form-user-language" value={formUserLanguage} onChange={(e) => setFormUserLanguage(e.target.value)}>
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        <MenuItem value="0">English</MenuItem>
                                        <MenuItem value="1">French</MenuItem>
                                    </Select>
                                </Grid>

                                <div className="form-divider"></div>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-phone">Phone Number *</InputLabel>
                                    <TextField className="form-input" id="form-user-phone" placeholder="" value={formUserPhone} onChange={(e) => setFormUserPhone(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-ext">Ext.</InputLabel>
                                    <TextField className="form-input form-input-half" id="form-user-ext" placeholder="" value={formUserExt} onChange={(e) => setFormUserExt(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-username">Username *</InputLabel>
                                    <TextField className="form-input" id="form-user-username" placeholder="" value={formUserUserName} onChange={(e) => setFormUserUserName(e.target.value)} />
                                </Grid>

                                <div className="form-divider"></div>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-password">Password *</InputLabel>
                                    <TextField className="form-input" id="form-user-password" placeholder="Password" type="password" autoComplete="current-password" value={formUserPassword} onChange={(e) => setFormUserPassword(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink className="form-label" htmlFor="form-user-password-rep">Confirm Password *</InputLabel>
                                    <TextField className="form-input" id="form-user-password-rep" placeholder="Password" type="password" autoComplete="current-password" value={formUserPasswordRep} onChange={(e) => setFormUserPasswordRep(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContent>

                    <DialogActions className="dialog-actions">
                        {
                            !formUserLoading ?
                                <>
                                    <Button onClick={saveUser} className="dialog-btn-save">Save User</Button>
                                    <Button onClick={handleUserFormDialog} className="dialog-btn-cancel">Cancel</Button>
                                </>
                                :
                                <Loading />
                        }
                    </DialogActions>
                </Dialog>

                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default Profile;
