import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination";
import {Button, Grid} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import JobApi from "../../services/JobApi";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import General from "../../utils/General";
import Checkbox from '@mui/material/Checkbox';
import "./Jobs.css";
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import Search from '@mui/icons-material/Search';
import ProductApi from "../../services/ProductApi";
import StationApi from "../../services/StationApi";
import UserApi from "../../services/UserApi";
import SortTable from "../../components/SortTable/SortTable";

//--------------------------------------------------

function Jobs(props)
{
    // get param
    let { stationId, statusId } = useParams();

    // set page title & page path
    const pageTitle = 'Jobs';
    usePage().pageData({title: Menus.jobs.title, activeMenu: 'jobs'});

    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [searchWord, setSearchWord] = useState("");
    const [filterPeople, setFilterPeople] = useState('');
    const [filterStation, setFilterStation] = useState(stationId > 0 ? stationId.toString() : '');
    const [filterProduct, setFilterProduct] = useState('');
    const [filterModel, setFilterModel] = useState('');
    const [filterStatus, setFilterStatus] = useState(statusId > 0 ? statusId.toString() : '');

    const [dataProduct, setDataProduct] = useState([]);
    const [dataStation, setDataStation] = useState([]);
    const [dataUsers, setDataUsers] = useState([]);

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const navigate = useNavigate();

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        JobApi.find({ pageNumber: pageNumber, searchTerm: searchWord, statuses: filterStatus, station: filterStation, product: filterProduct, model: filterModel, people: filterPeople, sortColName: sortColName, sortType: sortType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataProduct = () =>
    {
        setDataProduct(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        ProductApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataProduct(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataStation = () =>
    {
        setDataStation(null); // set data to null

        // get data
        StationApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataStation(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataUser = () =>
    {
        setDataUsers(null); // set data to null

        // get data
        UserApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataUsers(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); }, [sortColName, sortType, filterStatus, filterPeople, filterStation, filterProduct, filterModel]);
    useEffect(() => { loadDataProduct(); loadDataStation(); loadDataUser(); }, []);

    //
    const handleJobChange = (e) =>
    {
    };

    const handleSearch = (e) =>
    {
        loadData(1);
    };

    //
    const goToJob = (id) =>
    {
        navigate("/job/" + id, { replace: false });
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-jobs">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>

                <Box className="search-box form-box">
                    <Search className="icon" onClick={handleSearch} />
                    <TextField placeholder="Search" className="form-input" onChange={(e) => setSearchWord(e.target.value)} />
                </Box>
            </div>

            <div className="filter-bar">
                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-status-label">People</InputLabel>
                    <Select labelId="filter-status-label" value={filterPeople} label="People" onChange={(e) => setFilterPeople(e.target.value)}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        {
                            dataUsers !== null ?
                                dataUsers.map
                                (
                                    (item) =>
                                    {
                                        return (
                                            <MenuItem value={item.userId}>{item.fullName}</MenuItem>
                                        );
                                    }
                                )
                                :
                                null
                        }
                    </Select>
                </FormControl>

                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-status-label">Station</InputLabel>
                    <Select labelId="filter-status-label" value={filterStation} label="Station" onChange={(e) => setFilterStation(e.target.value)}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        {
                            dataStation !== null ?
                                dataStation.map
                                (
                                    (item) =>
                                    {
                                        return (
                                            <MenuItem value={item.stationId}>{item.stationName}</MenuItem>
                                        );
                                    }
                                )
                                :
                                null
                        }
                    </Select>
                </FormControl>

                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-status-label">Product</InputLabel>
                    <Select labelId="filter-status-label" value={filterProduct} label="Product" onChange={(e) => setFilterProduct(e.target.value)}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        {
                            dataProduct !== null ?
                                dataProduct.map
                                (
                                    (item) =>
                                    {
                                        return (
                                            <MenuItem value={item.categoryId}>{item.categoryName}</MenuItem>
                                        );
                                    }
                                )
                                :
                                null
                        }
                    </Select>
                </FormControl>

                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-status-label">Model</InputLabel>
                    <Select labelId="filter-status-label" value={filterModel} label="Model" onChange={(e) => setFilterModel(e.target.value)}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        {
                            dataProduct !== null ?
                                dataProduct.map
                                (
                                    (item) =>
                                    {
                                        return (
                                            item.products !== null ?
                                                item.products.map
                                                (
                                                    (item2) =>
                                                    {
                                                        return (
                                                            <MenuItem value={item2.productId}>{item2.productName}</MenuItem>
                                                        );
                                                    }
                                                )
                                                :
                                                null
                                        );
                                    }
                                )
                                :
                                null
                        }
                    </Select>
                </FormControl>

                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-status-label">Status</InputLabel>
                    <Select labelId="filter-status-label" value={filterStatus} label="Status" onChange={(e) => setFilterStatus(e.target.value)}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value="3">Pending</MenuItem>
                        <MenuItem value="4">Paused</MenuItem>
                        <MenuItem value="8">Active</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('jobNumber')}><span className="col-value"><SortTable sortData={sortData} colName="jobNumber" />No.</span></th>
                                        <th onClick={() => changeSortTable('orderName')}><span className="col-value"><SortTable sortData={sortData} colName="orderName" />Order name</span></th>
                                        <th onClick={() => changeSortTable('productName')}><span className="col-value"><SortTable sortData={sortData} colName="productName" />Product</span></th>
                                        <th onClick={() => changeSortTable('modelName')}><span className="col-value"><SortTable sortData={sortData} colName="modelName" />Model</span></th>
                                        <th onClick={() => changeSortTable('statusName')}><span className="col-value"><SortTable sortData={sortData} colName="statusName" />Status</span></th>
                                        <th onClick={() => changeSortTable('stationName')}><span className="col-value"><SortTable sortData={sortData} colName="stationName" />Station</span></th>
                                        <th onClick={() => changeSortTable('dueDate')}><span className="col-value"><SortTable sortData={sortData} colName="dueDate" />Due Date</span></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item) =>
                                            {
                                                return (
                                                    <tr onClick={() => goToJob(item.jobId)}>
                                                        <td>{item.jobNumber}</td>
                                                        <td>{item.orderName}</td>
                                                        <td>{item.productName}</td>
                                                        <td>{item.modelName}</td>
                                                        <td><label className={'label-status ' + General.initStatusClass(item.statusName)}>{item.statusName}</label></td>
                                                        <td>{item.stationName}</td>
                                                        <td className={item.isDueDateRequired ? 'td-highlight' : ''}>{item.dueDate}</td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="7"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>
        </div>
    );
}

export default Jobs;
