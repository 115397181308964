import axios from "axios";
import qs from "qs";
import General from "../utils/General";

export default
{
    find: async function()
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/listProvince",
                null,
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },
}
