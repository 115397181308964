import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination.js";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertAction from "../../components/AlertAction";
import "./Couriers.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CourierApi from "../../services/CourierApi";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SortTable from "../../components/SortTable/SortTable";

//--------------------------------------------------

function Couriers(props)
{
    // set page title & page path
    const pageTitle = 'Couriers';
    usePage().pageData({title: pageTitle, activeMenu: 'settings'});

    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [courierFormDialogOpen, setCourierFormDialogOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [formCourierId, setFormCourierId] = useState('');
    const [formCourierName, setFormCourierName] = useState('');
    const [formCourierContactName, setFormCourierContactName] = useState('');
    const [formCourierContactPhone, setFormCourierContactPhone] = useState('');
    const [formCourierContactEmail, setFormCourierContactEmail] = useState('');
    const [formCourierContactExtension, setFormCourierContactExtension] = useState('');

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        CourierApi.find({ pageNumber: pageNumber, sortColName: sortColName, sortType: sortType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, [sortColName, sortType]);

    // show form
    const handleCourierFormDialog = (e) =>
    {
        resetForm();

        setCourierFormDialogOpen(!courierFormDialogOpen);
    };

    // courier save
    const saveCourier = () =>
    {
        if(formCourierName !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'courierId': formCourierId, 'name': formCourierName, 'contact': formCourierContactName, 'phone': formCourierContactPhone, 'email': formCourierContactEmail, 'extension': formCourierContactExtension};
            CourierApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Courier Saved!", 'success'); // show success

                        if(formCourierId !== '') setCourierFormDialogOpen(false);
                        resetForm();

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormCourierId('');
        setFormCourierName('');
        setFormCourierContactName('');
        setFormCourierContactPhone('');
        setFormCourierContactEmail('');
        setFormCourierContactExtension('');
    };

    // courier edit
    const handleCourierEdit = (index) =>
    {
        setFormCourierId(data[index].courierId);
        setFormCourierName(data[index].vendorName);
        setFormCourierContactName(data[index].contactName);
        setFormCourierContactPhone(data[index].phone);
        setFormCourierContactEmail(data[index].email);
        setFormCourierContactExtension(data[index].extension);

        setCourierFormDialogOpen(true);
    };

    // courier delete
    const handleCourierDelete = (index) =>
    {
        setDataLoading(true); // show loading

        // send data
        const params = {'courierId': data[index].courierId};
        CourierApi.delete(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Courier Deleted!", 'success'); // show success

                    loadData();
                }
                else
                {
                    setDataLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-couriers">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                <Link className="link" underline="hover" color="inherit" to="/settings">Settings</Link>
                <Typography className="current-page" key="3" color="text.primary">Manage Couriers</Typography>,
            </Breadcrumbs>

            <div className="filter-bar">
                <Button onClick={handleCourierFormDialog} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Courier</Button>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('vendorName')}><span className="col-value"><SortTable sortData={sortData} colName="vendorName" />Courier Name</span></th>
                                        <th onClick={() => changeSortTable('contactName')}><span className="col-value"><SortTable sortData={sortData} colName="contactName" />Contact Name</span></th>
                                        <th onClick={() => changeSortTable('email')}><span className="col-value"><SortTable sortData={sortData} colName="email" />Email</span></th>
                                        <th onClick={() => changeSortTable('phone')}><span className="col-value"><SortTable sortData={sortData} colName="phone" />Phone</span></th>
                                        <th onClick={() => changeSortTable('extension')}><span className="col-value"><SortTable sortData={sortData} colName="extension" />Extension</span></th>
                                        <th className="w-80"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr>
                                                        <td>{item.vendorName}</td>
                                                        <td>{item.contactName}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.extension}</td>
                                                        <td>
                                                            <IconButton className="icon-edit" onClick={() => handleCourierEdit(index)}><BorderColorIcon /></IconButton>
                                                            <IconButton className="icon-delete" onClick={() => handleCourierDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="7"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>

            {/* courier form */}
            <Dialog open={courierFormDialogOpen} onClose={handleCourierFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formCourierId === '' ? 'Add' : 'Edit'} Courier</span>
                        <IconButton className="dialog-close" onClick={handleCourierFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-courier-name">Courier Name</InputLabel>
                        <TextField className="form-input" id="form-courier-name" placeholder="" value={formCourierName} onChange={(e) => setFormCourierName(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-courier-contact-name">Contact Name</InputLabel>
                        <TextField className="form-input" id="form-courier-contact-name" placeholder="" value={formCourierContactName} onChange={(e) => setFormCourierContactName(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-courier-name">Contact Phone</InputLabel>
                        <TextField className="form-input" id="form-courier-name" placeholder="" value={formCourierContactPhone} onChange={(e) => setFormCourierContactPhone(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-courier-contact-email">Contact Email</InputLabel>
                        <TextField className="form-input" id="form-courier-contact-email" placeholder="" value={formCourierContactEmail} onChange={(e) => setFormCourierContactEmail(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-courier-contact-extention">Contact Extension</InputLabel>
                        <TextField className="form-input" id="form-courier-contact-extention" placeholder="" value={formCourierContactExtension} onChange={(e) => setFormCourierContactExtension(e.target.value)} />
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveCourier} className="dialog-btn-save">Save Courier</Button>
                                <Button onClick={handleCourierFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Couriers;
