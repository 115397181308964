import React, {useRef, useState} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import AlertAction from "../../components/AlertAction";
import UserApi from "../../services/UserApi";
import Loading from "../../components/Loading/Loading";
import { Grid, Container, TextField, Button, InputLabel } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link } from "react-router-dom";
import imageLogo from '../../images/logo.png';
import imageBG from '../../images/image01.png';
import "./Login.css";

//--------------------------------------------------

function Login()
{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const [isLogin, setIsLogin] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const auth = useAuth();
    const alertAction = useRef(); // init alert

    // send login data to server
    const login = () =>
    {
        // auth.authentication({/*nameFamily: response.data.nameFamily, */token: 'response.data.token'});
        // setIsLogin(true);

        if(email !== "" && password !== "") // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'username': email, 'password': password, 'remember': 0};
            UserApi.login(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        // console.log(response)
                        auth.authentication({nameFamily: response.data.nameFamily, token: response.data.token});
                        setIsLogin(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // redirect to panel if user loggedIn
    if(isLogin)
    {
        return <Navigate to="/dashboard" />;
    }

    // page content
    return (
        <div className="page-login">
            <Container className="form-box">
                <Grid container justifyContent="center">
                    <Grid item xs={12} className="logo-box"><img src={imageLogo} alt="logo" /></Grid>

                    <Grid item sm={10} xs={12} className="form-login">
                        <Grid container>
                            <Grid item sm={5} className="bg-box"><img src={imageBG} alt="" /></Grid>

                            <Grid item sm={7} xs={12} className="form-content">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <h2 className="form-title">Login to your account</h2>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-login-email">Email/Username</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-login-email" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-login-password">Password<Link to="/forgot-password" className="link-to-forgot-password">Forgot Password</Link></InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-login-password" placeholder="password" type={passwordType} autoComplete="current-password" onChange={(e) => setPassword(e.target.value)} />
                                        {passwordType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('password')} />}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {!formLoading ? <Button onClick={login} className="form-btn" fullWidth={true} variant="contained" endIcon={<ArrowForwardIcon />}>Login</Button> : <Loading />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="login-des">Don’t have account! <Link to="/register" className="link-to-register">Create Account</Link></Grid>
                </Grid>
            </Container>

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Login;
