import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Loading from "../../components/Loading/Loading";
import QuoteApi from "../../services/QuoteApi";
import AlertAction from "../../components/AlertAction";
import "react-datepicker/dist/react-datepicker.css";
import Quote from "../../components/Quote/Quote";
import SortTable from "../../components/SortTable/SortTable";
import './Quotes.css';

//--------------------------------------------------

function Quotes(props)
{
    // get new param
    let { openNew, statusId } = useParams();

    // set page title & page path
    const pageTitle = 'Quotes';
    usePage().pageData({title: Menus.quotes.title, activeMenu: 'quotes'});

    //
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [quoteDialogOpen, setQuoteDialogOpen] = useState(false);

    const [sortData, setSortData] = useState({'dueDate': 'desc'});
    const [sortColName, setSortColName] = useState('dueDate');
    const [sortType, setSortType] = useState('desc');

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        QuoteApi.find({ pageNumber: pageNumber, status: statusId, sortColName: sortColName, sortType: sortType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, [sortColName, sortType]);
    useEffect(() => { if(openNew === 'new') setQuoteDialogOpen(true); }, []);

    //
    const goToQuote = (id) =>
    {
        navigate("/quote/" + id, { replace: false });
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-quotes">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>

                <Button onClick={() => setQuoteDialogOpen(true)} className="title-bar-btn" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Create new Quote</Button>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        {/*<th onClick={() => changeSortTable('quoteNumber')} className="w-140 center"><span className="col-value">{sortData["quoteNumber"] !== '' ? (sortData["quoteNumber"] === 'asc' ? <ArrowRightAltIcon className="col-sort asc" /> : <ArrowRightAltIcon className="col-sort desc" />) : <SyncAltIcon className="col-sort" />}NO.</span></th>*/}
                                        <th onClick={() => changeSortTable('quoteNumber')} className="w-140 center"><span className="col-value"><SortTable sortData={sortData} colName="quoteNumber" />NO.</span></th>
                                        <th onClick={() => changeSortTable('quoteName')}><span className="col-value"><SortTable sortData={sortData} colName="quoteName" />Quote name</span></th>
                                        <th onClick={() => changeSortTable('customerName')} className="w-160"><span className="col-value"><SortTable sortData={sortData} colName="customerName" />Client</span></th>
                                        <th onClick={() => changeSortTable('status')} className="w-120 center"><span className="col-value"><SortTable sortData={sortData} colName="status" />Status</span></th>
                                        <th onClick={() => changeSortTable('dueDate')} className="w-120"><span className="col-value"><SortTable sortData={sortData} colName="dueDate" />Date</span></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item) =>
                                            {
                                                return (
                                                    <tr onClick={(e) => goToQuote(item.quoteId)}>
                                                        <td className="center">{item.quoteNumber}</td>
                                                        <td className="color-gray-900">{item.quoteName}</td>
                                                        <td>{item.customerName}</td>
                                                        <td><label className={item.status === 'Active' ? 'label-status active' : 'label-status pending'}>{item.status}</label></td>
                                                        <td className={item.isDueDateRequired ? 'td-highlight' : ''}>{item.dueDate}</td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="5"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* quote */}
            {quoteDialogOpen ? <Quote handleDialog={() => setQuoteDialogOpen(false)} closeAfterSave={true} loadData={() => { loadData(); }} /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Quotes;
