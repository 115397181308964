import React from "react";
import loadingImage from "../../images/loading.gif";
import "./Loading.css";

//
function Loading(prop)
{
    //
    return(
        <div className="loading"><img src={loadingImage} /></div>
    )
}
export default Loading;
