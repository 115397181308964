import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useParams, Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AlertDataEmpty from '../../components/AlertDataEmpty/AlertDataEmpty.js';
import './Search.css';
import SearchApi from "../../services/SearchApi";
import Loading from "../../components/Loading/Loading";

//--------------------------------------------------

function Search(props)
{
    // get search word
    let { sw } = useParams();

    // set page title & page path
    const pageTitle = 'Search Results';
    usePage().pageData({title: pageTitle, activeMenu: 'search'});

    //
    const [tabActive, setTabActive] = useState('all');
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    // tab click
    const handleTabChange = (event, newValue) =>
    {
        setTabActive(newValue);
    };

    // load data
    const loadData = () =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        SearchApi.find({ query: sw.replace(/-/g, ' ') }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    return (
        <div className="page-content page-search">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <Box className="tab-bar">
                <Tabs value={tabActive} onChange={handleTabChange}>
                    <Tab className="tab" label="All Results" value="all" />
                    <Tab className="tab" label="Orders" value="order" />
                    <Tab className="tab" label="Jobs" value="job" />
                    <Tab className="tab" label="Customers" value="customer" />
                    <Tab className="tab" label="Vendors" value="vendor" />
                </Tabs>
            </Box>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <>
                                    {
                                        (tabActive === 'all' || tabActive === 'order') && data.orders !== undefined && data.orders !== null && data.orders.length > 0 ?
                                            <div className="search-result">
                                                <div className="search-result-title"><span className="title">Orders</span></div>
                                                {
                                                    data.orders.map
                                                    (
                                                        (item) =>
                                                        {
                                                            return (
                                                                <div className="search-item">
                                                                    <div className="col">
                                                                        <div className="title">Order#</div>
                                                                        <div className="value"><Link to={"/order/" + item.orderId}>{item.orderNumber}</Link></div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Customer Name</div>
                                                                        <div className="value">{item.customerName}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Status</div>
                                                                        <div className="value">{item.status}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Due Date</div>
                                                                        <div className="value">{item.dueDate}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        (tabActive === 'all' || tabActive === 'job') && data.jobs !== undefined && data.jobs !== null && data.jobs.length > 0 ?
                                            <div className="search-result">
                                                <div className="search-result-title job"><span className="title">Jobs</span></div>
                                                {
                                                    data.jobs.map
                                                    (
                                                        (item) =>
                                                        {
                                                            return (
                                                                <div className="search-item">
                                                                    <div className="col">
                                                                        <div className="title">Job#</div>
                                                                        <div className="value"><Link to={"/job/" + item.jobId}>{item.jobNumber}</Link></div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Customer Name</div>
                                                                        <div className="value">{item.customerName}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Product</div>
                                                                        <div className="value">{item.product}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Status</div>
                                                                        <div className="value">{item.status}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Due Date</div>
                                                                        <div className="value">{item.dueDate}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        (tabActive === 'all' || tabActive === 'customer') && data.customers !== undefined && data.customers !== null && data.customers.length > 0 ?
                                            <div className="search-result">
                                                <div className="search-result-title customer"><span className="title">Customers</span></div>
                                                {
                                                    data.customers.map
                                                    (
                                                        (item) =>
                                                        {
                                                            return (
                                                                <div className="search-item">
                                                                    <div className="col">
                                                                        <div className="title">Name</div>
                                                                        <div className="value">{item.name}</div>
                                                                        {/*<div className="value"><Link to={"/customer/" + item.name}>{item.name}</Link></div>*/}
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Email</div>
                                                                        <div className="value">{item.email}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Number</div>
                                                                        <div className="value">{item.phone}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Address</div>
                                                                        <div className="value">{item.address}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        (tabActive === 'all' || tabActive === 'vendor') && data.vendors !== undefined && data.vendors !== null && data.vendors.length > 0 ?
                                            <div className="search-result">
                                                <div className="search-result-title vendor"><span className="title">Vendors</span></div>
                                                {
                                                    data.vendors.map
                                                    (
                                                        (item) =>
                                                        {
                                                            return (
                                                                <div className="search-item">
                                                                    <div className="col">
                                                                        <div className="title">Vendor Name</div>
                                                                        {/*<div className="value"><Link to={"/job/" + item.name}>{item.name}</Link></div>*/}
                                                                        <div className="value">{item.name}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Contact Name</div>
                                                                        <div className="value">{item.contactName}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">Email</div>
                                                                        <div className="value">{item.email}</div>
                                                                    </div>

                                                                    <div className="col">
                                                                        <div className="title">number</div>
                                                                        <div className="value">{item.phone}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>
        </div>
    );
}

export default Search;
