import axios from "axios";
import qs from "qs";
import General from "../utils/General";

export default
{
    find: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/listJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    findJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/getJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    pauseJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/pauseJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    resumeJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/resumeJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    deleteJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/deleteJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    assignJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/assignJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    returnJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/returnJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    completeJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/completeJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    nextStationJob: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/nextStationJob",
                qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },

    save: async function(params)
    {
        let res = '';

        try
        {
            const response = await axios.post
            (
                General.siteUrl + "/saveJob",
                params,//qs.stringify(params),
                {
                    headers: {...General.authorizationToken, "content-type": "multipart/form-data"},
                }
            );

            res = response.data;
        }
        catch(error)
        {
            res = error.response.data;
        }

        return res;
    },
}
