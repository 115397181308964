import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid} from "@mui/material";
import Loading from "../../components/Loading/Loading";
import TasksApi from "../../services/TaskApi";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Switch from "@mui/material/Switch/Switch";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import UserApi from "../../services/UserApi";
import General from "../../utils/General";
import QuoteApi from "../../services/QuoteApi";
import FormControl from '@mui/material/FormControl';
import "./Task.css";
import OrderApi from "../../services/OrderApi";

//--------------------------------------------------

function Task(props)
{
    //
    const [dataUsers, setDataUsers] = useState([]);
    const [dataOrders, setDataOrders] = useState([]);

    const [formLoading, setFormLoading] = useState(false);
    const [formTaskId, setFormTaskId] = useState('');
    const [formTaskAssigned, setFormTaskAssigned] = useState('');
    const [formTaskName, setFormTaskName] = useState('');
    const [formTaskOrderId, setFormTaskOrderId] = useState(props.orderId);
    const [formTaskOrderNumber, setFormTaskOrderNumber] = useState(props.orderNumber);
    const [formTaskType, setFormTaskType] = useState(1);
    const [formTaskDate, setFormTaskDate] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadDataUser = () =>
    {
        setDataUsers(null); // set data to null

        // get data
        UserApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataUsers(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    const loadDataOrder = () =>
    {
        setDataOrders(null); // set data to null

        // get data
        OrderApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataOrders(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadDataUser(); loadDataOrder(); if(props.taskData != null) handleTaskEdit(props.taskData); }, []);

    // Task save
    const saveTask = () =>
    {
        if(formTaskAssigned !== "" && formTaskName !== "" && formTaskOrderId !== "" && formTaskType !== "" && formTaskDate !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'id': formTaskId, 'assigned': formTaskAssigned, 'quoteId': formTaskOrderId, 'quoteNumber': formTaskOrderNumber, 'type': formTaskType, 'name': formTaskName, 'dueDate': General.parseDateSafe(formTaskDate)};
            TasksApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Task Saved!", 'success'); // show success

                        if(formTaskId === '') resetForm();
                        props.loadData();
                        if(props.closeAfterSave !== undefined && props.closeAfterSave) props.handleDialog();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormTaskId('');
        setFormTaskAssigned('');
        setFormTaskName('');
        if(props.orderId === undefined) setFormTaskOrderId('');
        if(props.orderNumber === undefined) setFormTaskOrderNumber('');
        setFormTaskType('');
        setFormTaskDate('');
    };

    // task edit
    const handleTaskEdit = (data) =>
    {
        setFormTaskId(data.taskId);
        setFormTaskAssigned(data.assignedToId);
        setFormTaskName(data.taskName);
        if(props.orderId === undefined) setFormTaskOrderId(data.quoteId);
        if(props.orderNumber === undefined) setFormTaskOrderNumber(data.quoteNumber);
        setFormTaskType(data.type);
        setFormTaskDate(data.dueDate);
    };

    //
    return (
        <>
            {/* task form */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formTaskId === '' ? 'Add New' : 'Edit'} Task</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-task-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-task-assigned">Assigned To</InputLabel>
                        <Select className="form-select" id="form-task-assigned" value={formTaskAssigned} onChange={(e) => setFormTaskAssigned(e.target.value)}>
                            <MenuItem value="">&nbsp;</MenuItem>
                            {
                                dataUsers !== null ?
                                    dataUsers.map
                                    (
                                        (item) =>
                                        {
                                            return (
                                                <MenuItem value={item.userId}>{item.fullName}</MenuItem>
                                            );
                                        }
                                    )
                                    :
                                    null
                            }
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-task-name">Task Name</InputLabel>
                        <TextField className="form-input" id="form-task-name" placeholder="Normal" value={formTaskName} onChange={(e) => setFormTaskName(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-task-order-number">Order number</InputLabel>
                        {
                            (props.orderNumber !== undefined && props.orderNumber !== '') ?
                                <TextField className="form-input" id="form-task-order-number" placeholder="Normal" value={formTaskOrderNumber} disabled="disabled" />
                                :
                                <FormControl fullWidth disabled={formTaskId !== ''}>
                                    <Select className="form-select" id="form-task-order-number" value={formTaskOrderId} onChange={(e) => setFormTaskOrderId(e.target.value)}>
                                        <MenuItem value="">&nbsp;</MenuItem>
                                        {
                                            dataOrders !== null ?
                                                dataOrders.map
                                                (
                                                    (item) =>
                                                    {
                                                        return (
                                                            <MenuItem value={item.quoteId}>{item.orderNumber}</MenuItem>
                                                        );
                                                    }
                                                )
                                                :
                                                null
                                        }
                                    </Select>
                                </FormControl>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Stack className="form-switch" direction="row" spacing={1} alignItems="center">
                            <span className="form-label form-label-m0">Pre Production</span>
                            <Switch defaultChecked checked={formTaskType === 1} onChange={(e) => setFormTaskType(e.target.checked ? 1 : 0)} />
                            <span className="form-label form-label-m0">Post-Production</span>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-task-date">Due Date</InputLabel>
                        <div className="form-input">
                            <DatePicker id="form-task-date" dateFormat="yyyy/MM/dd" selected={formTaskDate !== '' ? new Date(formTaskDate) : ''} value={formTaskDate !== '' ? new Date(formTaskDate) : ''} onChange={(e) => setFormTaskDate(e)} />
                        </div>
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                <Button onClick={saveTask} className="dialog-btn-save">Save Task</Button>
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default Task;
