import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination";
import {Button, Container, Grid, TextField} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import TasksApi from "../../services/TaskApi";
import UserApi from "../../services/UserApi";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import General from "../../utils/General";
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import "react-datepicker/dist/react-datepicker.css";
import AlertAction from "../../components/AlertAction";
import "./Tasks.css";
import QuoteApi from "../../services/QuoteApi";
import Task from "../../components/Task/Task";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SortTable from "../../components/SortTable/SortTable";

//--------------------------------------------------

function Tasks(props)
{
    // set page title & page path
    const pageTitle = 'Tasks';
    usePage().pageData({title: Menus.tasks.title, activeMenu: 'tasks'});

    //
    const [data, setData] = useState([]);
    const [dataUsers, setDataUsers] = useState([]);
    const [dataOrders, setDataOrders] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [filterViewAs, setFilterViewAs] = useState('');
    const [taskDialogOpen, setTaskDialogOpen] = useState(false);

    const [formLoading, setFormLoading] = useState(false);
    const [formTaskAssigned, setFormTaskAssigned] = useState('');
    const [formTaskName, setFormTaskName] = useState('');
    const [formTaskOrderNumber, setFormTaskOrderNumber] = useState('');
    const [formTaskType, setFormTaskType] = useState(1);
    const [formTaskDate, setFormTaskDate] = useState('');

    const [taskData, setTaskData] = useState([]);

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        TasksApi.find({ pageNumber: pageNumber, viewAs: filterViewAs, sortColName: sortColName, sortType: sortType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataUser = () =>
    {
        setDataUsers(null); // set data to null

        // get data
        UserApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataUsers(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); }, [filterViewAs, sortColName, sortType]);
    useEffect(() => { loadDataUser(); }, []);

    //
    const handleViewAsChange = (e) =>
    {
        setFilterViewAs(e.target.value);
    };

    const handleTaskDialog = (e) =>
    {
        setTaskDialogOpen(!taskDialogOpen);
    };

    const handleTaskChange = (e, taskId, quoteId) =>
    {
        setDataLoading(true); // show loading

            // send data
            const params = {'quoteId': quoteId, 'taskId': taskId, 'quoteTask': e.target.checked ? 'on' : 'off', 'value': ''};
            TasksApi.complete(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Task Completed!", 'success'); // show success

                        loadData();
                    }
                    else
                    {
                        setDataLoading(false); // hide loading
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }
                }
            ).catch
            (
                function(error)
                {
                    setDataLoading(false); // hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
    };

    // send new task data
    const saveTask = () =>
    {
        if(formTaskAssigned !== "" && formTaskName !== "" && formTaskOrderNumber !== "" && formTaskType !== "" && formTaskDate !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'id': '', 'assigned': formTaskAssigned, 'quoteId': formTaskOrderNumber, 'type': formTaskType, 'name': formTaskName, 'dueDate': General.parseDateSafe(formTaskDate)};
            TasksApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Task Saved!", 'success'); // show success

                        setFormTaskAssigned('');
                        setFormTaskName('');
                        setFormTaskOrderNumber('');
                        setFormTaskType(1);
                        setFormTaskDate('');

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // show form
    const handleTaskFormDialog = (data) =>
    {
        setTaskData(data);
        setTaskDialogOpen(true);
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-tasks">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>

                <Button onClick={() => handleTaskFormDialog(null)} className="title-bar-btn t2" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Task</Button>
            </div>

            <div className="filter-bar">
                <FormControl className="filter-select-box" size="small">
                    <InputLabel id="filter-view-as-label">View As</InputLabel>
                    <Select labelId="filter-view-as-label" value={filterViewAs} label="View As" onChange={handleViewAsChange}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        {
                            dataUsers !== null ?
                                dataUsers.map
                                (
                                    (item) =>
                                    {
                                        return (
                                            <MenuItem value={item.userId}>{item.fullName}</MenuItem>
                                        );
                                    }
                                )
                                :
                                null
                        }
                    </Select>
                </FormControl>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th className="w-30"></th>
                                        <th onClick={() => changeSortTable('taskName')}><span className="col-value"><SortTable sortData={sortData} colName="taskName" />Task</span></th>
                                        <th onClick={() => changeSortTable('quoteNumber')}><span className="col-value"><SortTable sortData={sortData} colName="quoteNumber" />Order</span></th>
                                        <th onClick={() => changeSortTable('dueDate')}><span className="col-value"><SortTable sortData={sortData} colName="dueDate" />Due Date</span></th>
                                        <th onClick={() => changeSortTable('assignedTo')}><span className="col-value"><SortTable sortData={sortData} colName="assignedTo" />Assigned to</span></th>
                                        <th className="w-40"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item) =>
                                            {
                                                return (
                                                    <tr>
                                                        <td><Checkbox onChange={(e) => handleTaskChange(e, item.taskId, item.quoteId)} /></td>
                                                        <td>{item.taskName}</td>
                                                        <td><Link to={'/order/' + item.quoteId}>{item.quoteNumber} - {item.quoteName}</Link></td>
                                                        <td>{item.dueDate}</td>
                                                        <td>{item.assignedTo}</td>
                                                        <td>
                                                            <IconButton className="icon-edit" onClick={() => handleTaskFormDialog(item)}><BorderColorIcon /></IconButton>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="7"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* form task */}
            {taskDialogOpen ? <Task handleDialog={() => setTaskDialogOpen(false)} taskData={taskData} loadData={() => {loadData();}} /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Tasks;
