import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import {Button, Container, Grid, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Loading from "../../components/Loading/Loading";
import AlertAction from "../../components/AlertAction";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PresetTaskApi from "../../services/PresetTaskApi";
import Checkbox from '@mui/material/Checkbox';
import "./PresetTask.css";
import UserApi from "../../services/UserApi";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';

//--------------------------------------------------

function PresetTask(props)
{
    //
    const [formLoading, setFormLoading] = useState(false);

    const [dataPresetTaskPre, setDataPresetTaskPre] = useState([]);
    const [dataPresetTaskPost, setDataPresetTaskPost] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataUsers, setDataUsers] = useState([]);

    const [presetTaskFormDialogOpen, setPresetTaskFormDialogOpen] = useState(false);
    const [formPresetTaskLoading, setFormPresetTaskLoading] = useState(false);
    const [formPresetTaskId, setFormPresetTaskId] = useState('');
    const [formPresetTaskName, setFormPresetTaskName] = useState('');
    const [formPresetTaskAssignedUser, setFormPresetTaskAssignedUser] = useState('');
    const [formPresetTaskDueDays, setFormPresetTaskDueDays] = useState('');
    const [formPresetTaskType, setFormPresetTaskType] = useState('');

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        setDataPresetTaskPre(null); // set data to null
        setDataPresetTaskPost(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        PresetTaskApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataPresetTaskPre(response.data.list.pre);
                    setDataPresetTaskPost(response.data.list.post);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };

    const loadDataUser = () =>
    {
        setDataUsers(null); // set data to null

        // get data
        UserApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataUsers(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };

    useEffect(() => { loadData(); loadDataUser(); }, []);

    // show form PresetTask
    const handlePresetTaskFormDialog = (type) =>
    {
        resetForm();
        setFormPresetTaskType(type);

        setPresetTaskFormDialogOpen(!presetTaskFormDialogOpen);
    };

    // presetTask save
    const save = () =>
    {
        if(formPresetTaskName !== "" && formPresetTaskAssignedUser !== "" && formPresetTaskDueDays !== "") // check data is correct
        {
            setFormPresetTaskLoading(true); // hide btn & show loading

            // send data
            const params = {'id': formPresetTaskId, 'name': formPresetTaskName, 'assigned': formPresetTaskAssignedUser, 'type': formPresetTaskType, 'dueDays': formPresetTaskDueDays};
            PresetTaskApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("PresetTask Saved!", 'success'); // show success

                        if(formPresetTaskId !== '') setPresetTaskFormDialogOpen(false);
                        resetForm();
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormPresetTaskLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormPresetTaskLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormPresetTaskId('');
        setFormPresetTaskName('');
        setFormPresetTaskAssignedUser('');
        setFormPresetTaskDueDays('');
    };

    // presetTask edit
    const handleEdit = (item) =>
    {
        setFormPresetTaskId(item.taskId);
        setFormPresetTaskName(item.taskName);
        setFormPresetTaskAssignedUser(item.assignedTo);
        setFormPresetTaskDueDays(item.dueDays);
        setFormPresetTaskType(item.type);

        setPresetTaskFormDialogOpen(true);
    };

    // delete
    const handleDelete = (item) =>
    {
        setDataLoading(true); // show loading

        // send data
        const params = {'id': item.taskId};
        PresetTaskApi.delete(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Task Deleted!", 'success'); // show success

                    loadData();
                }
                else
                {
                    setDataLoading(false); // hide loading
                    alertAction.current.showAlert("Incorrect information"); // show error
                }
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // PresetTask save
    const savePresetTask = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        PresetTaskApi.saveTasks().then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Tasks Saved!", 'success'); // show success
                }
                else
                {
                    alertAction.current.showAlert("Incorrect information"); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <>
            {/* PresetTask list */}
            <Dialog open={true} onClose={props.handleDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Manage Preset Tasks</span>
                        <IconButton className="dialog-close" onClick={props.handleDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content dialog-content-preset-task">
                    <div className="section-title">Pre-production task</div>
                    <div className="section-des">Pre-production tasks need to be complete before a job becomes active</div>
                    <Button onClick={() => handlePresetTaskFormDialog(0)} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Task</Button>
                    {
                        dataLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                dataPresetTaskPre !== null ?
                                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar scrollbar-preset-task-pre">
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th>Task name</th>
                                                <th>Assgined User</th>
                                                <th>Due Days</th>
                                                <th className="w-80"></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataPresetTaskPre.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td>{item.taskName}</td>
                                                                <td>{item.assignedTo}</td>
                                                                <td>{item.dueDays}</td>
                                                                <td>
                                                                    <IconButton className="icon-edit" onClick={() => handleEdit(item)}><BorderColorIcon /></IconButton>
                                                                    <IconButton className="icon-delete" onClick={() => handleDelete(item)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    :
                                    <AlertDataEmpty />
                            )
                    }

                    {/* *** */}

                    <div className="section-title">Post-production task</div>
                    <div className="section-des">Post-production tasks need to be complete before an order can be completed</div>
                    <Button onClick={() => handlePresetTaskFormDialog(1)} className="btn-blue btn-blue-outline" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Task</Button>
                    {
                        dataLoading ?
                            (
                                <Loading />
                            )
                            :
                            (
                                dataPresetTaskPost !== null ?
                                    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar scrollbar-preset-task-post">
                                        <table className="table-data">
                                            <thead className="sticky">
                                            <tr>
                                                <th>Task name</th>
                                                <th>Assgined User</th>
                                                <th>Due Days</th>
                                                <th className="w-80"></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                dataPresetTaskPost.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr>
                                                                <td>{item.taskName}</td>
                                                                <td>{item.assignedTo}</td>
                                                                <td>{item.dueDays}</td>
                                                                <td>
                                                                    <IconButton className="icon-edit" onClick={() => handleEdit(item)}><BorderColorIcon /></IconButton>
                                                                    <IconButton className="icon-delete" onClick={() => handleDelete(item)}><DeleteOutlineOutlinedIcon /></IconButton>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    :
                                    <AlertDataEmpty />
                            )
                    }
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formLoading ?
                            <>
                                {dataPresetTaskPre !== null || dataPresetTaskPost !== null ? <Button onClick={savePresetTask} className="dialog-btn-save">Save Changes</Button> : null}
                                <Button onClick={props.handleDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* PresetTask form */}
            <Dialog open={presetTaskFormDialogOpen} onClose={handlePresetTaskFormDialog} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">{formPresetTaskId === '' ? 'Add' : 'Edit'} Preset Tasks</span>
                        <IconButton className="dialog-close" onClick={handlePresetTaskFormDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content form-box form-box-t2">
                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-preset-task-name">Task Name</InputLabel>
                        <TextField className="form-input" id="form-preset-task-name" value={formPresetTaskName} onChange={(e) => setFormPresetTaskName(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-preset-task-assigned-user">Assigned User</InputLabel>
                        <Select className="form-select" id="form-preset-task-assigned-user" value={formPresetTaskAssignedUser} onChange={(e) => setFormPresetTaskAssignedUser(e.target.value)}>
                            <MenuItem value="">&nbsp;</MenuItem>
                            {
                                dataUsers !== null ?
                                    dataUsers.map
                                    (
                                        (item) =>
                                        {
                                            return (
                                                <MenuItem value={item.userId}>{item.fullName}</MenuItem>
                                            );
                                        }
                                    )
                                    :
                                    null
                            }
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink className="form-label" htmlFor="form-preset-task-due-days">Due Days</InputLabel>
                        <Select className="form-select" id="form-preset-task-due-days" value={formPresetTaskDueDays} onChange={(e) => setFormPresetTaskDueDays(e.target.value)}>
                            <MenuItem value="">&nbsp;</MenuItem>
                            <MenuItem value="0">Immediate</MenuItem>
                            <MenuItem value="1">1 Day</MenuItem>
                            <MenuItem value="2">2 Days</MenuItem>
                            <MenuItem value="3">3 Days</MenuItem>
                        </Select>
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    {
                        !formPresetTaskLoading ?
                            <>
                                <Button onClick={save} className="dialog-btn-save">Save Task</Button>
                                <Button onClick={handlePresetTaskFormDialog} className="dialog-btn-cancel">Cancel</Button>
                            </>
                            :
                            <Loading />
                    }
                </DialogActions>
            </Dialog>


            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    );
}

export default PresetTask;
