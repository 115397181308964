import Alert from '@mui/material/Alert';

function AlertDataEmpty(props)
{
    //
    return (
            <Alert severity="info">nothing here</Alert>
    );
}

export default AlertDataEmpty;
