import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Pagination from "../../components/Pagination/Pagination.js";
import {Button, Container, Grid} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Loading from "../../components/Loading/Loading";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import Search from '@mui/icons-material/Search';
import CustomerApi from "../../services/CustomerApi";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import AlertAction from "../../components/AlertAction";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Switch from "@mui/material/Switch/Switch";
import Stack from "@mui/material/Stack";
import FormControlLabel from '@mui/material/FormControlLabel';
import ProvinceApi from "../../services/ProvinceApi";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import "./Customers.css";
import Customer from "../../components/Customer/Customer";
import SortTable from "../../components/SortTable/SortTable";

//--------------------------------------------------

function Customers(props)
{
    // set page title & page path
    const pageTitle = 'Customers';
    usePage().pageData({title: Menus.customers.title, activeMenu: 'customers'});

    //
    const [data, setData] = useState([]);
    //const [dataProvince, setDataProvince] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});
    const [searchWord, setSearchWord] = useState("");
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    /*
        const [customerFormDialogOpen, setCustomerFormDialogOpen] = useState(false);
        const [formLoading, setFormLoading] = useState(false);
        const [formCustomerId, setFormCustomerId] = useState('');
        const [formCustomerType, setFormCustomerType] = useState(1);
        const [formCustomerCompanyName, setFormCustomerCompanyName] = useState('');
        const [formCustomerCustomerPhoneNumber, setFormCustomerCustomerPhoneNumber] = useState('');
        const [formCustomerCustomerPhoneExt, setFormCustomerCustomerPhoneExt] = useState('');
        const [formCustomerCustomerFax, setFormCustomerCustomerFax] = useState('');
        const [formCustomerFirstName, setFormCustomerFirstName] = useState('');
        const [formCustomerLastName, setFormCustomerLastName] = useState('');
        const [formCustomerEmail, setFormCustomerEmail] = useState('');
        const [formCustomerPhoneNumber, setFormCustomerPhoneNumber] = useState('');
        const [formCustomerPhoneExt, setFormCustomerPhoneExt] = useState('');
        const [formCustomerBillingAddress, setFormCustomerBillingAddress] = useState('');
        const [formCustomerCity, setFormCustomerCity] = useState('');
        const [formCustomerProvince, setFormCustomerProvince] = useState('');
        const [formCustomerPostalCode, setFormCustomerPostalCode] = useState('');
        const [formCustomerShippingAddress, setFormCustomerShippingAddress] = useState('');
        const [formCustomerShippingCity, setFormCustomerShippingCity] = useState('');
        const [formCustomerShippingProvince, setFormCustomerShippingProvince] = useState('');
        const [formCustomerShippingPostalCode, setFormCustomerShippingPostalCode] = useState('');
        const [formCustomerNote, setFormCustomerNote] = useState('');
        const [formCustomerBillingSameShipping, setFormCustomerBillingSameShipping] = useState(false);

        const alertAction = useRef(); // init alert
    */
    // load data
    const loadData = (pageNumber = 1) =>
    {
        setData(null); // set data to null
        setDataLoading(true); // show loading

        // get data
        CustomerApi.find({ pageNumber: pageNumber, searchTerm: searchWord, sortColName: sortColName, sortType: sortType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": pageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setData(response.data.list);
                }

                setDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setDataLoading(false); // hide loading
            }
        );
    };
/*
    const loadDataProvince = () =>
    {
        setDataProvince(null); // set data to null

        // get data
        ProvinceApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataProvince(response.data.list);
                }
            }
        ).catch
        (
            function(error)
            {
            }
        );
    };
*/
    useEffect(() => { loadData(); /*loadDataProvince();*/ }, [sortColName, sortType]);

    // search
    const handleSearch = (e) =>
    {
        loadData(1);
    };
/*
    // show form
    const handleCustomerFormDialog = (e) =>
    {
        resetForm();

        setCustomerFormDialogOpen(!customerFormDialogOpen);
    };

    // customer save
    const saveCustomer = () =>
    {
        if(((formCustomerType === 0 && formCustomerCompanyName !== "") || (formCustomerType === 1)) && formCustomerFirstName !== "" && formCustomerLastName !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading
            const preField = formCustomerType === 1 ? 'retail' : 'corporate';

            // send data
            const params = {};
            params['customerId'] = formCustomerId;
            params['customerType'] = formCustomerType;
            params[preField + 'CompanyName'] = formCustomerCompanyName;
            params[preField + 'FirstName'] = formCustomerFirstName;
            params[preField + 'LastName'] = formCustomerLastName;
            params[preField + 'CompanyPhone'] = formCustomerCustomerPhoneNumber;
            params[preField + 'CompanyPhoneExtension'] = formCustomerCustomerPhoneExt;
            params[preField + 'Phone'] = formCustomerPhoneNumber;
            params[preField + 'PhoneExtension'] = formCustomerPhoneExt;
            params[preField + 'Mobile'] = '';
            params[preField + 'Fax'] = formCustomerCustomerFax;
            params[preField + 'Email'] = formCustomerEmail;
            params[preField + 'CustomerSource'] = '';
            params[preField + 'BillingApartment'] = '';
            params[preField + 'BillingAddress'] = formCustomerBillingAddress;
            params[preField + 'BillingCity'] = formCustomerCity;
            params[preField + 'BillingProvince'] = formCustomerProvince;
            params[preField + 'BillingPostalCode'] = formCustomerPostalCode;
            params[preField + 'BillingSameAsShipping'] = formCustomerBillingSameShipping ? 'on' : '';
            params[preField + 'ShippingApartment'] = '';
            params[preField + 'ShippingAddress'] = formCustomerShippingAddress;
            params[preField + 'ShippingCity'] = formCustomerShippingCity;
            params[preField + 'ShippingProvince'] = formCustomerShippingProvince;
            params[preField + 'ShippingPostalCode'] = formCustomerShippingPostalCode;
            params[preField + 'Notes'] = formCustomerNote;
            CustomerApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Customer Saved!", 'success'); // show success

                        if(formCustomerId !== '') setCustomerFormDialogOpen(false);
                        resetForm();

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert("Incorrect information"); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetForm = () =>
    {
        setFormCustomerId('');
        setFormCustomerType(1);
        setFormCustomerCompanyName('');
        setFormCustomerCustomerPhoneNumber('');
        setFormCustomerCustomerPhoneExt('');
        setFormCustomerCustomerFax('');
        setFormCustomerFirstName('');
        setFormCustomerLastName('');
        setFormCustomerEmail('');
        setFormCustomerPhoneNumber('');
        setFormCustomerPhoneExt('');
        setFormCustomerBillingAddress('');
        setFormCustomerCity('');
        setFormCustomerProvince('');
        setFormCustomerPostalCode('');
        setFormCustomerShippingAddress('');
        setFormCustomerShippingCity('');
        setFormCustomerShippingProvince('');
        setFormCustomerShippingPostalCode('');
        setFormCustomerNote('');
        setFormCustomerBillingSameShipping('');
    };

    // customer edit
    const handleCustomerEdit = (index) =>
    {
        setFormCustomerId(data[index].customerId);
        setFormCustomerType(data[index].customerType);
        setFormCustomerCompanyName(data[index].companyName);
        setFormCustomerCustomerPhoneNumber(data[index].companyPhone);
        setFormCustomerCustomerPhoneExt(data[index].companyPhoneExtension);
        setFormCustomerCustomerFax(data[index].fax);
        setFormCustomerFirstName(data[index].firstName);
        setFormCustomerLastName(data[index].lastName);
        setFormCustomerEmail(data[index].email);
        setFormCustomerPhoneNumber(data[index].phone);
        setFormCustomerPhoneExt(data[index].phoneExtension);
        setFormCustomerBillingAddress(data[index].billingAddress);
        setFormCustomerCity(data[index].billingCity);
        setFormCustomerProvince(data[index].billingProvince);
        setFormCustomerPostalCode(data[index].billingPostalCode);
        setFormCustomerShippingAddress(data[index].shippingAddress);
        setFormCustomerShippingCity(data[index].shippingCity);
        setFormCustomerShippingProvince(data[index].shippingProvince);
        setFormCustomerShippingPostalCode(data[index].shippingPostalCode);
        setFormCustomerNote(data[index].notes);
        setFormCustomerBillingSameShipping(data[index].billingSameAsShipping === 1);

        setCustomerFormDialogOpen(true);
    };
*/
    // customer delete
    // const handleCustomerDelete = (index) =>
    // {
    //     setDataLoading(true); // show loading
    //
    //     // send data
    //     const params = {'customerId': data[index].customerId};
    //     CustomerApi.delete(params).then
    //     (
    //         function(response)
    //         {
    //             if(response.status === 200)
    //             {
    //                 alertAction.current.showAlert("Customer Deleted!", 'success'); // show success
    //
    //                 loadData();
    //             }
    //             else
    //             {
    //                 setDataLoading(false); // hide loading
    //                 alertAction.current.showAlert("Incorrect information"); // show error
    //             }
    //         }
    //     ).catch
    //     (
    //         function(error)
    //         {
    //             setDataLoading(false); // hide loading
    //             alertAction.current.showAlert("There is a Problem"); // show error
    //         }
    //     );
    // };

    // show form
    const handleCustomerFormDialog = (data) =>
    {
        setCustomerData(data);
        setCustomerDialogOpen(true);
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd['lastSortType'] = currentSortType;
        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-customers">
            <div className="title-bar">
                <span className="title">{pageTitle}</span>
            </div>

            <div className="title-bar filter-bar">
                <Box className="search-box form-box">
                    <Search className="icon" onClick={handleSearch} />
                    <TextField placeholder="Search" className="form-input" onChange={(e) => setSearchWord(e.target.value)} />
                </Box>

                {/*<Button onClick={handleCustomerFormDialog} className="title-bar-btn t2" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Customer</Button>*/}
                <Button onClick={() => handleCustomerFormDialog(null)} className="title-bar-btn t2" fullWidth={false} variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add New Customer</Button>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    dataLoading ?
                        (
                            <Loading />
                        )
                        :
                        (
                            data !== null ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('name')}><span className="col-value"><SortTable sortData={sortData} colName="name" />Customer Name</span></th>
                                        <th onClick={() => changeSortTable('address')}><span className="col-value"><SortTable sortData={sortData} colName="address" />Address</span></th>
                                        <th onClick={() => changeSortTable('email')}><span className="col-value"><SortTable sortData={sortData} colName="email" />Email</span></th>
                                        <th onClick={() => changeSortTable('phoneByFormat')}><span className="col-value"><SortTable sortData={sortData} colName="phoneByFormat" />Phone</span></th>
                                        <th className="w-40"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phoneByFormat}</td>
                                                        <td>
                                                            <IconButton className="icon-edit" onClick={() => handleCustomerFormDialog(item)}><BorderColorIcon /></IconButton>
                                                            {/*<IconButton className="icon-delete" onClick={() => handleCourierDelete(index)}><DeleteOutlineOutlinedIcon /></IconButton>*/}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="7"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {customerDialogOpen ? <Customer handleDialog={() => setCustomerDialogOpen(false)} customerData={customerData} loadData={() => {loadData();}} /> : null}
        </div>
    );
}

export default Customers;
